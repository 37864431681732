import S3 from "aws-s3";
const seUploadFun = function (file, newFileName) {
  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET,
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_S3A,
    secretAccessKey: process.env.REACT_APP_S3K,
  };
  const S3Client = new S3(config);
  return S3Client.uploadFile(file, newFileName);
};
export default seUploadFun;
