import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "scoring",
  initialState: {
    scoring: [],
    update: {},
    add:{},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    scoringRequested: (scoring, action) => {
      scoring.loading = true;
    },

    scoringReceived: (scoring, action) => {
      scoring.scoring = action.payload.data;
      scoring.loading = false;
      scoring.lastFetch = Date.now();
    },

    scoringRequestFailed: (scoring, action) => {
      scoring.loading = false;
    },
    updatedScoring: (scoring, action) => {
      scoring.update = action.payload;
      scoring.loading = false;
      scoring.lastFetch = Date.now();
    },

    updatedScoringRequestFailed: (scoring, action) => {
      scoring.loading = false;
    },
     scoringAdded: (scoring, action) => {
      scoring.add = action.payload;
      scoring.loading = false;
      scoring.lastFetch = Date.now();
    },

    scoringAddRequestFailed: (scoring, action) => {
      scoring.loading = false;
    },
  },
});

export const {
  scoringRequested,
  scoringReceived,
  scoringRequestFailed,
  updatedScoring,
  updatedScoringRequestFailed,
  scoringAdded,
  scoringAddRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "assessmentScoringReference/";


export const loadAllScoring = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: scoringRequested.type,
      onSuccess: scoringReceived.type,
      onError: scoringRequestFailed.type,
    })
  );
};
export const updateScoring = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url  + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedScoring.type,
      onError: updatedScoringRequestFailed.type,
    })
  );
};
export const addScoring = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: scoringAdded.type,
      onError: scoringAddRequestFailed.type,
    })
  );
};

export const deletedScoring = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url:url + id,
      method: "delete",
      callback,
    })
  );
};

export const getScoring = createSelector(
  (state) => state.entities.scoring,
  (scoring) => scoring
);
