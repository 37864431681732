import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'
//import moment from "moment";

const slice = createSlice({
  name: 'appointment',
  initialState: {
    appointment: [],
    pagination: {},
    refund: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    appointmentRequested: (appointment, action) => {
      appointment.loading = true
    },

    appointmentReceived: (appointment, action) => {
      appointment.appointment = action.payload.data
      appointment.pagination = action.payload.pagination
      appointment.loading = false
      appointment.lastFetch = Date.now()
    },

    appointmentRequestFailed: (appointment, action) => {
      appointment.loading = false
    },
    appointmentRefund: (appointment, action) => {
      appointment.refund = action.payload.data
      appointment.loading = false
      appointment.lastFetch = Date.now()
    },

    appointmentRefundRequestFailed: (appointment, action) => {
      appointment.loading = false
    },
  },
})

export const {
  appointmentRequested,
  appointmentReceived,
  appointmentRequestFailed,
  appointmentRefund,
  appointmentRefundRequestFailed,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'appointment/list'
const url1 = 'appointment/refund/'
const url2 = 'appointment/createComment'

export const loadAllAppointment = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: appointmentRequested.type,
      onSuccess: appointmentReceived.type,
      onError: appointmentRequestFailed.type,
    })
  )
}
export const addComment = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url2,
      method: 'POST',
      data,
      callback,
      onSuccess: appointmentRequestFailed.type,
      onError: appointmentRequestFailed.type,
    })
  )
}
export const appointmentAllRefund = (id, data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url1 + id,
      method: 'POST',
      data,
      callback,
      onSuccess: appointmentRefund.type,
      onError: appointmentRefundRequestFailed.type,
    })
  )
}

export const getAppointment = createSelector(
  state => state.entities.appointment,
  appointment => appointment
)
