import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { informationSaved, failed } from "../common/misc";
import { loadAllSite, updateSite, getSite } from "../../store/siteConstant";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

const initialState = {
  data: {
    convenience_charges: 0,
    tax: 0,
    spare_time_between_slots: "",
    refresh_token_expiry: "",
    JWT_token_expiry: "",
    admin_JWT_token_expiry: "",
    appointment_booking_allowed_before_hr: "",
    facebook: "",
    footer_email_address: "",
    header_email_address: "",
    helpline_no: "",
    instagram: "",
    twitter: "",
    chat_box_activate_before_hr: "",
    appointment_cancellation_allowed_before: "",
  },
  sms: false,
  errors: {
    spare_time_between_slots: "",
    refresh_token_expiry: "",
    JWT_token_expiry: "",
    admin_JWT_token_expiry: "",
    appointment_booking_allowed_before_hr: "",
    facebook: "",
    footer_email_address: "",
    header_email_address: "",
    helpline_no: "",
    instagram: "",
    twitter: "",
    chat_box_activate_before_hr: "",
    appointment_cancellation_allowed_before: "",
  },
};

class Basic extends Form {
  state = initialState;
  schema = {
    spare_time_between_slots: Joi.number().label("Spare Time Between Slots"),
    convenience_charges: Joi.number().required(),
    tax: Joi.number().required(),
    refresh_token_expiry: Joi.number().label("Refresh Token Expiry"),

    JWT_token_expiry: Joi.number().label("JWT Token Expiry"),
    chat_box_activate_before_hr: Joi.number().label(
      "Chat Box Activate Before Hour"
    ),
    admin_JWT_token_expiry: Joi.number()
      .min(30)
      .label("Admin JWT Token Expiry"),
    appointment_booking_allowed_before_hr: Joi.number().label(
      "Appointment Booking Allowed Before Hour"
    ),
    facebook: Joi.string().label("Facebook"),
    footer_email_address: Joi.string().label("Footer Email Address"),
    header_email_address: Joi.string().label("Header Email Address"),
    helpline_no: Joi.number().label("Helpline Number"),
    instagram: Joi.string().label("Instagram"),
    twitter: Joi.string().label("Twitter"),
    appointment_cancellation_allowed_before: Joi.number()
      .min(1)
      .label("Appointment Cancellation Allowed Before (Minutes)"),
  };
  doSubmit = () => {
    const {
      facebook,
      instagram,
      appointment_cancellation_allowed_before,
      twitter,
      chat_box_activate_before_hr,
      footer_email_address,
      header_email_address,
      helpline_no,
      spare_time_between_slots,
      refresh_token_expiry,
      appointment_booking_allowed_before_hr,
      JWT_token_expiry,
      admin_JWT_token_expiry,
      convenience_charges,
      tax,
    } = this.state.data;
    const id = this.props.id;
    const { sms } = this.state;
    var formData = {
      convenience_charges,
      tax,
      facebook: facebook,
      instagram: instagram,
      twitter: twitter,
      footer_email_address: footer_email_address,
      header_email_address: header_email_address,
      helpline_no: helpline_no,
      spare_time_between_slots: spare_time_between_slots,
      refresh_token_expiry: refresh_token_expiry,
      JWT_token_expiry: JWT_token_expiry,
      admin_JWT_token_expiry: admin_JWT_token_expiry,
      sms: sms,
      chat_box_activate_before_hr: chat_box_activate_before_hr,
      appointment_booking_allowed_before_hr:
        appointment_booking_allowed_before_hr,
      appointment_cancellation_allowed_before:
        appointment_cancellation_allowed_before,
    };
    this.props.updateSite(formData, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllSite();
      this.props.toggleAddUser();
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  componentDidMount = () => {
    this.setState({
      data: {
        convenience_charges:
          this.props.site.convenience_charges &&
          this.props.site.convenience_charges["$numberDecimal"],
        tax: this.props.site.tax && this.props.site.tax["$numberDecimal"],
        helpline_no: this.props.site.helpline_no,
        facebook: this.props.site.facebook,
        footer_email_address: this.props.site.footer_email_address,
        header_email_address: this.props.site.header_email_address,
        instagram: this.props.site.instagram,
        twitter: this.props.site.twitter,
        spare_time_between_slots: this.props.site.spare_time_between_slots,
        refresh_token_expiry: this.props.site.refresh_token_expiry,
        JWT_token_expiry: this.props.site.JWT_token_expiry,
        admin_JWT_token_expiry: this.props.site.admin_JWT_token_expiry,
        appointment_booking_allowed_before_hr:
          this.props.site.appointment_booking_allowed_before_hr,
        chat_box_activate_before_hr:
          this.props.site.chat_box_activate_before_hr,
        appointment_cancellation_allowed_before:
          this.props.site.appointment_cancellation_allowed_before,
      },
      sms: this.props.site.sms,
    });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.site) {
      this.setState({
        data: {
          convenience_charges:
            this.props.site.convenience_charges &&
            this.props.site.convenience_charges["$numberDecimal"],
          tax: this.props.site.tax && this.props.site.tax["$numberDecimal"],
          helpline_no: nextProps.site.helpline_no,
          chat_box_activate_before_hr:
            nextProps.site.chat_box_activate_before_hr,
          facebook: nextProps.site.facebook,
          footer_email_address: nextProps.site.footer_email_address,
          header_email_address: nextProps.site.header_email_address,
          instagram: nextProps.site.instagram,
          twitter: nextProps.site.twitter,
          chat_box_activate_before_hr:
            nextProps.site.chat_box_activate_before_hr,
          spare_time_between_slots: nextProps.site.spare_time_between_slots,
          refresh_token_expiry: nextProps.site.refresh_token_expiry,
          JWT_token_expiry: nextProps.site.JWT_token_expiry,
          admin_JWT_token_expiry: nextProps.site.admin_JWT_token_expiry,
          appointment_booking_allowed_before_hr:
            nextProps.site.appointment_booking_allowed_before_hr,
          appointment_cancellation_allowed_before:
            nextProps.site.appointment_cancellation_allowed_before,
        },
        sms: nextProps.site.sms,
      });
    }
  }

  toggleMaster = () => {
    this.setState({
      sms: !this.state.sms,
    });
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Basic</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleAddUser}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("tax", "Tax %", "number")}
            {this.renderInput(
              "convenience_charges",
              "Convenience Charges %",
              "number"
            )}
            {this.renderInput(
              "spare_time_between_slots",
              "Spare Time Between Slots"
            )}

            {this.renderInput("refresh_token_expiry", "Refresh Token Expiry")}
            {this.renderInput("JWT_token_expiry", "JWT Token Expiry")}
            {this.renderInput(
              "chat_box_activate_before_hr",
              "Chat Box Activate Before Hour"
            )}
            {this.renderInput(
              "admin_JWT_token_expiry",
              "Admin JWT Token Expiry"
            )}
            {this.renderInput(
              "appointment_booking_allowed_before_hr",
              "Appointment Booking Allowed Before Hour"
            )}
            {this.renderInput("facebook", "Facebook")}
            {this.renderInput("twitter", "Twitter")}
            {this.renderInput("instagram", "Instagram")}
            {this.renderInput("helpline_no", "Helpline Number")}
            {this.renderInput("header_email_address", "Header Email Address")}
            {this.renderInput("footer_email_address", "Footer Email Address")}
            {this.renderInput(
              "appointment_cancellation_allowed_before",
              "Appointment Cancellation Allowed Before (Minutes)"
            )}

            <div className="form-group">
              <label>SMS</label>
              <div className="checkbox-list">
                <label className="checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agree"
                    value=""
                    checked={this.state.sms === true ? true : false}
                    onClick={this.toggleMaster}
                  />
                  <span></span>
                </label>
              </div>
            </div>
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  site: getSite(state).site,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllSite: () => dispatch(loadAllSite()),
  updateSite: (data, callback) => dispatch(updateSite(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Basic);
