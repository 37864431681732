import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import AddSeo from "./addSeo";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import SeoGrid from "./seoGrid";
import HelmetComponent from "../common/helmetComponent";
import { getSeo,loadAllSeo,deletedSeo,updateSeo } from "../../store/seo";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
  deleted,
  activated,
  deactivated,
 failed,
} from "../common/misc";
import SeoSearch from "./seoSearch";

class SeoTable extends Component {
	state = {
	 	userShow: false,
	    isShow: false,
	    modal: false,
	    id:"",
	    data:"",
	    totalRecords: "",
	    totalPages: "",
	    pageLimit: "",
	    currentPage: "",
	    editShow:false,
	    delete_id:"",
		asside:false,
		asside2:false,
		asside3:false,
  	};

  	toggleAddUser = () => {
	    this.setState({
	      isShow: !this.state.isShow,
	    });
  	};
  	toggleAsside = () => {
	    this.setState({
		  asside2:false,
		  asside3:false,
	      asside: !this.state.asside,
	    });
  	};
	toggleAsside2 = () => {
	    this.setState({
		  asside: false,
		  asside3:false,
	      asside2: !this.state.asside2,
		  
	    });
  	};
	toggleAsside3 = () => {
	    this.setState({
		  asside2:false,
		  asside3:!this.state.asside3,
	      asside: false,
		});
	};
	toggleShowUser = () => {
	    this.setState({
	      userShow: !this.state.userShow,
	    });
  	};
  	
    toggleEditUser = (data,id) => {
      this.setState({
        editShow: !this.state.editShow,
        data:data,
        id:id
      });
  	}	
	
	toggle = (id) => {
      this.setState({
        modal: !this.state.modal,
        delete_id:id,
      });
    };

    deletedSeo=(id) => {
      this.props.deletedSeo(id,this.callback);
    }
    callback = (res) => {
      if (res && res.status === 200) {
        this.props.loadAllSeo({"page":this.state.currentPage})
        this.setState({
          modal: !this.state.modal,
        });
		toast(<AlertSuccess message={deleted}/>)
      } else {
	        toast(<AlertError message={failed}/>)
		}
    };
 	componentDidMount = () => {
      if(this.state.currentPage){
        this.props.loadAllSeo({"page":this.state.currentPage})
      }
    }
    onChangePage = data => {
      this.setState({
        pageLimit: data.pageLimit,
        totalPages: data.totalPages,
        currentPage: data.page,
      });
      if(data.page){
        this.props.loadAllSeo({"page":data.page})
      }
    };
	render() {
		const {seo} = this.props;
		const {record_per_page,total_record,total_page,current_page,filtered_record} = this.props.pagination;
	    return (
			<div className={this.state.asside===true || this.state.asside2===true || this.state.asside3=== true?"header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled":"header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"}>
			 <div  className="d-flex flex-column flex-root">
				<HelmetComponent title= "Administrators Management"/>
				<div className="d-flex flex-row flex-column-fluid page">
				<SideBar toggleAsside={this.toggleAsside} toggleAsside3={this.toggleAsside3} userShow={this.state.userShow} toggleShowUser={this.toggleShowUser} asside={this.state.asside} asside3={this.state.asside3} asside2={this.state.asside2} toggleAsside2={this.toggleAsside2}/>
					<div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
						<div className="content d-flex flex-column flex-column-fluid">
							<div className="subheader py-2 py-lg-4 subheader-transparent">
								<div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
									<div className="d-flex align-items-center flex-wrap mr-2">
										<h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">SEO Settings</h5>
										<div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
										<div className="d-flex align-items-center">
											<span className="text-dark-50 font-weight-bold">{filtered_record > 0 ?"Total :" + " " +filtered_record + " " + "records" :"Total : 0 record"}</span>
											<SeoSearch currentPage={current_page}/>
										</div>
									</div>
									<div className="d-flex align-items-center">
										<a href="javascript:void (0)" className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2" onClick={(e) => {e.preventDefault();this.toggleAddUser()}} data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window">Add SEO Tags</a>
									</div>
									
								</div>
							</div>
							<div className="d-flex flex-column-fluid">
								<div className="container-fluid">
									<div className="card card-custom">
										<div className="card-header flex-wrap border-0 pt-6 pb-0">
											<div className="card-title">
												<h3 className="card-label">SEO Settings Management
												<span className="d-block text-muted pt-2 font-size-sm">You can view/add/edit/delete/activate/deactivate seo settings.</span></h3>
											</div>
											
										</div>
										<div className="card-body">
											<div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
												<table className="datatable-table" style={{"display": "block"}}>
													<thead className="datatable-head">
														<tr className="datatable-row" style={{"left": "0px"}}>
															<th className="datatable-cell datatable-cell-sort">
																<span style={{"width": "145px"}}>Page Key</span>
															</th>
															<th  className="datatable-cell datatable-cell-sort">
																<span style={{"width": "145px"}}>Page Title</span></th>
															<th className="datatable-cell datatable-cell-sort">
																<span style={{"width": "250px"}}>Meta Description</span></th>
															
															<th className="datatable-cell datatable-cell-sort">
																<span style={{"width": "145px"}}>Created On</span>
															</th>
															<th className="datatable-cell datatable-cell-sort">
																<span style={{"width": "145px"}}>Action</span>
															</th>
														</tr>
													</thead>                  
													<SeoGrid   delete_id={this.state.delete_id} initialPage={this.state.current_page} editShow={this.state.editShow} toggleEditUser={this.toggleEditUser} data={this.state.data}  modalActive={this.state.active} toggleAcitve={this.toggleAcitve} seo={seo} modal={this.state.modal} toggle={this.toggle} id={this.state.id} deleted={this.deletedSeo}/>
												</table>
												<Pagination totalRecords={total_record} total_page={total_page}
                             				 	pageLimit={record_per_page} initialPage={current_page}
                            					pagesToShow={5} onChangePage={this.onChangePage}/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<UserProfile userShow={this.state.userShow} toggleShowUser={this.toggleShowUser}/>
				<AddSeo initialPage={this.state.current_page} isShow={this.state.isShow} toggleAddUser={this.toggleAddUser}/>
				<ScrollTop/>
			</div>
			</div>
		)
	}
}
const mapStateToProps = (state) => ({
    seo: getSeo(state).seo,
    pagination:getSeo(state).pagination,
  });
  const mapDispatchToProps = (dispatch) => ({
  loadAllSeo: (params) => dispatch(loadAllSeo(params)),
  deletedSeo: (id,callback) => dispatch(deletedSeo(id,callback)),
  updateSeo: (id,data,callbackActive) => dispatch(updateSeo(id,data,callbackActive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeoTable);
		