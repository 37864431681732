import React, { Component } from "react";
import Moment from 'moment';

class Association extends Component {
 
	render() {
	const {associations} = this.props.data;
		return (
			<>
				<div className={this.props.association===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Association</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleAssociation}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
						
					</div>
					<hr/>
					{associations && associations .map((e,i)=>(
					<>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Association Type: </a>
						<span className="text-muted font-weight-bold education-margin">{e.associationstype}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Association: </a>
						<span className="text-muted font-weight-bold education-margin">{e.associationsvalue}</span>
					</div>
					<hr/>
					</>))}
				</div>
				{this.props.association=== true?<div className="offcanvas-overlay" onClick={this.props.toggleAssociation}></div>:""}
			</>
		)
	}
}

export default Association;