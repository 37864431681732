import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "blogcategory",
  initialState: {
    blogcategory: [],
    pagination: {},
    update: {},
    add: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    blogcategoryRequested: (blogcategory, action) => {
      blogcategory.loading = true;
    },

    blogcategoryReceived: (blogcategory, action) => {
      blogcategory.blogcategory = action.payload.data;
      blogcategory.pagination = action.payload.pagination;
      blogcategory.loading = false;
      blogcategory.lastFetch = Date.now();
    },

    blogcategoryRequestFailed: (blogcategory, action) => {
      blogcategory.loading = false;
    },

    updatedBlogcategory: (blogcategory, action) => {
      blogcategory.update = action.payload;
      blogcategory.loading = false;
      blogcategory.lastFetch = Date.now();
    },

    updatedBlogcategoryRequestFailed: (blogcategory, action) => {
      blogcategory.loading = false;
    },
    blogcategoryAdded: (blogcategory, action) => {
      blogcategory.add = action.payload;
      blogcategory.loading = false;
      blogcategory.lastFetch = Date.now();
    },

    blogcategoryAddRequestFailed: (blogcategory, action) => {
      blogcategory.loading = false;
    },
  },
});

export const {
  blogcategoryRequested,
  blogcategoryReceived,
  blogcategoryRequestFailed,
  updatedBlogcategory,
  updatedBlogcategoryRequestFailed,
  blogcategoryAdded,
  blogcategoryAddRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "blog-category/";


export const loadAllBlogcategory = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: blogcategoryRequested.type,
      onSuccess: blogcategoryReceived.type,
      onError: blogcategoryRequestFailed.type,
    })
  );
};
export const updateBlogcategory = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedBlogcategory.type,
      onError: updatedBlogcategoryRequestFailed.type,
    })
  );
};
export const addBlogcategory = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: blogcategoryAdded.type,
      onError: blogcategoryAddRequestFailed.type,
    })
  );
};

export const deletedBlogcategory = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "delete",
      callback,
    })
  );
};

export const getBlogCategory = createSelector(
  (state) => state.entities.blogcategory,
  (blogcategory) => blogcategory
);
