import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "assessment",
  initialState: {
    assessment: [],
    pagination: {},
    update: {},
    question: {},
    add: {},
    addqestion: {},
    detail: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    assessmentRequested: (assessment, action) => {
      assessment.loading = true;
    },

    assessmentReceived: (assessment, action) => {
      assessment.assessment = action.payload.data;
      assessment.pagination = action.payload.pagination;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    assessmentRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
    assessmentDetailRequested: (assessment, action) => {
      assessment.loading = true;
    },

    assessmentDetailReceived: (assessment, action) => {
      assessment.detail = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    assessmentDetailRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
    updatedAssessment: (assessment, action) => {
      assessment.update = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    updatedAssessmentRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
    updatedQuestionsAssessment: (assessment, action) => {
      assessment.question = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    updatedQuestionAssessmentRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
    assessmentAdded: (assessment, action) => {
      assessment.add = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    assessmentAddRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },

    assessmentQuestionAdded: (assessment, action) => {
      assessment.addqestion = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    assessmentAddQuestionRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
  },
});

export const {
  assessmentQuestionAdded,
  assessmentAddQuestionRequestFailed,
  assessmentRequested,
  assessmentReceived,
  assessmentRequestFailed,
  assessmentDetailRequested,
  assessmentDetailReceived,
  assessmentDetailRequestFailed,
  updatedAssessment,
  updatedAssessmentRequestFailed,
  updatedQuestionsAssessment,
  updatedQuestionAssessmentRequestFailed,
  assessmentAdded,
  assessmentAddRequestFailed,

} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "assesment/";
const url1 = "assesment/question/";

export const loadAllAssessment = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: assessmentRequested.type,
      onSuccess: assessmentReceived.type,
      onError: assessmentRequestFailed.type,
    })
  );
};
export const loadDetailAssessment = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      onStart: assessmentDetailRequested.type,
      onSuccess: assessmentDetailReceived.type,
      onError: assessmentDetailRequestFailed.type,
    })
  );
};
export const updateAssessment = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedAssessment.type,
      onError: updatedAssessmentRequestFailed.type,
    })
  );
};
export const addAssessment = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: assessmentAdded.type,
      onError: assessmentAddRequestFailed.type,
    })
  );
};

export const addQuestionAssessment = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url1 + id,
      method: "post",
      data,
      callback,
      onSuccess: assessmentQuestionAdded.type,
      onError: assessmentAddQuestionRequestFailed.type,
    })
  );
};

export const deletedAssessment = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "delete",
      callback,
    })
  );
};
export const updatedQuestionAssessment = (id, id2, data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url1 + id + "/" + id2,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedQuestionsAssessment.type,
      onError: updatedQuestionAssessmentRequestFailed.type,
    })
  );
};
export const deletedQuestionAssessment = (id, id2, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url1 + id + "/" + id2,
      method: "delete",
      callback,
    })
  );
};
export const getAssessment = createSelector(
  (state) => state.entities.assessment,
  (assessment) => assessment
);
