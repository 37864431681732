import React, { Component } from "react";
import Moment from 'moment';
import { connect } from "react-redux";
import ModalPopup from "../common/modal";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Education from "./education";
import Experience from "./experience";
import Certification from "./certification";
import ClinicDetails from "./clinicDetails";
import Award from "./awards";
import Publication from "./publication";
import Documentation from "./documentation";
import Practice from "./pratice";
import LinkSetup from "./link";
import BankDetail from "./bank";
import Schedule from "./schedule";
import Association from "./association";
import StatusModal from "../common/statusModal";

class DoctorGrid extends Component {

    render() {
        return (
            <tbody className="datatable-body">
                {this.props.doctor.map((e, i) => (
                    <tr
                        data-row="10"
                        className="datatable-row"
                        key={i}
                    >
                        <td className="datatable-cell">
                            <span style={{ width: "200px" }}>
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-40 symbol-sm flex-shrink-0">
                                        <img className="" src={process.env.REACT_APP_S3URL + e.image_url} alt="photo" />
                                    </div>
                                    <div className="ml-4">
                                        <div className="text-dark-75 font-weight-bolder font-size-lg mb-0">{e.name}</div>
                                        {e.appointment > 0 ?
                                            <Link to={{ pathname: "/appointments", state: { doctor_id: e._id } }} className="font-weight-bold text-muted">
                                                {e.appointment} Appointments
                                            </Link> : ""
                                        }
                                        <div className="font-weight-bold text-muted">{e.sex === "M" ? "Male" : "Female"} </div>
                                        <div className="font-weight-bold text-muted">
                                            <a href={process.env.REACT_APP_S3URL + e.signarture_url} target="_blank" className="text-muted font-weight-bold text-hover-primary">View Signature</a>
                                        </div>
                                        <div className="font-weight-bold text-muted">
                                            {e.contract_accepted === true ? "Contract Accepted"
                                                : "Contract Pending"}
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </td>
                        <td className="datatable-cell">
                            <span style={{ width: "200px" }}>
                                <div className="font-weight-bolder font-size-lg mb-0">
                                    {e.email}
                                </div>
                                <div className="font-weight-bold text-muted">
                                    {e.mobile}
                                </div>
                            </span>
                        </td>
                        <td className="datatable-cell">
                            <span style={{ width: "90px" }}>
                                <div className="font-weight-bolder font-size-lg mb-0">
                                    {e.date_of_birth ? Moment(e.date_of_birth).format('DD-MM-YYYY') : ""}
                                </div>
                            </span>
                        </td>
                        <td className="datatable-cell">
                            <span style={{ width: "150px" }}>
                                <div className="font-weight-bolder font-size-lg mb-0">{e.field_type.name}
                                </div>
                                <div className="font-weight-bold text-muted mb-2">
                                    Featured on home: {e.featured_on_home === true ?
                                        <a onClick={() => { this.props.toggleChange(e._id, e.featured_on_home) }} style={{ "cursor": "pointer" }}>YES</a>
                                        : <a onClick={() => { this.props.toggleChange(e._id, e.featured_on_home) }} style={{ "cursor": "pointer" }}>NO</a>
                                    }

                                </div>
                                <div className="font-weight-bold text-muted">
                                    Featured on expert: {e.featured_on_expert === true ?
                                        <a onClick={() => { this.props.toggleChange1(e._id, e.featured_on_expert) }} style={{ "cursor": "pointer" }}>YES</a>
                                        : <a onClick={() => { this.props.toggleChange1(e._id, e.featured_on_expert) }} style={{ "cursor": "pointer" }}>NO</a>
                                    }
                                </div>
                            </span>
                        </td>
                        <td className="datatable-cell">
                            <span style={{ width: "75px" }}>
                                {e.is_approved === true ?
                                    <Link to={"#"} onClick={() => { this.props.toggleAcitve(e._id, false, "Are you sure you want to reject this record?") }} style={{ "cursor": "pointer" }}><span className="label label-lg font-weight-bold  label-light-success label-inline">YES</span></Link> :
                                    <Link to={"#"} onClick={() => { this.props.toggleAcitve(e._id, true, "Are you sure you want to approve this record?") }} style={{ "cursor": "pointer" }}><span className="label label-lg font-weight-bold  label-light-danger label-inline">NO</span></Link>
                                }
                            </span>
                        </td>
                        <td className="datatable-cell">
                            <span style={{ width: "115px" }}>
                                <div className="font-weight-bolder font-size-lg mb-0">{Moment(e.created_on).format('DD-MM-YYYY')}
                                </div>
                                <div className="font-weight-bold text-muted">
                                </div>
                            </span>
                        </td>
                        <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                            <span style={{ "overflow": "visible", "position": "relative", "width": "205px" }}>
                                <a href={process.env.REACT_APP_BASEURL + "dr/" + e.user_name} target="_blank" download="" className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2" title="Profile">
                                    <span className="symbol symbol-30 symbol-lg-40">
                                        <span className="svg-icon svg-icon-xl">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </a>
                                <Dropdown>
                                    <Dropdown.Toggle style={{ all: "unset" }}>
                                        <a href="javascript:void (0)" className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2" title="Action">
                                            <span className="svg-icon svg-icon-md">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                        <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000"></path>
                                                        <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </a>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul className="navi flex-column navi-hover py-2">
                                            <li className="navi-header font-weight-bolder   text-uppercase font-size-xs text-primary pb-2">
                                                Choose an action:
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleEducation(e._id)} className="navi-link">
                                                    <span className="navi-text">Educations</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleExperience(e._id)} className="navi-link">
                                                    <span className="navi-text">Experience</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleCertification(e._id)} className="navi-link" >
                                                    <span className="navi-text">Certifications/Fellowships</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleAward(e._id)} className="navi-link">
                                                    <span className="navi-text">Awards/Affiliations</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.togglePublication(e._id)} className="navi-link" >
                                                    <span className="navi-text">Publications</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleDocumentation(e._id)} className="navi-link" >
                                                    <span className="navi-text">Documentations</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleClinic(e._id)} className="navi-link">
                                                    <span className="navi-text">Clinic Details</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleAssociation(e._id)} className="navi-link">
                                                    <span className="navi-text">Associations</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.togglePractice(e._id)} className="navi-link" >
                                                    <span className="navi-text">Practice Preferences</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleSchedule(e._id)} className="navi-link">
                                                    <span className="navi-text">Schedule</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleBank(e._id)} className="navi-link">
                                                    <span className="navi-text">Bank Details</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="javascript:void (0)" onClick={() => this.props.toggleLink(e._id)} className="navi-link" >
                                                    <span className="navi-text">Link Setup</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {e.appointment === 0 ? "" :
                                    <Link to={{ pathname: "/appointments", state: { doctor_id: e._id } }} className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2" title="Appointments">
                                        <span className="svg-icon svg-icon-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z" fill="#000000" opacity="0.3" />
                                                    <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000" />
                                                </g>
                                            </svg>
                                        </span>
                                    </Link>
                                }
                                <a href="javascript:void (0)" onClick={() => this.props.toggle(e._id)} className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon" title="Delete">
                                    <span className="svg-icon svg-icon-md">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero"></path>
                                                <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                                            </g>
                                        </svg>
                                    </span>
                                </a>


                            </span>

                        </td>

                    </tr>

                ))}
                <ModalPopup show={this.props.modal} onHide={this.props.toggle} delete_id={this.props.delete_id} deleted={this.props.deleted} />
                <Education id={this.props.id} education={this.props.education} toggleEducation={this.props.toggleEducation} data={this.props.detail} />
                <Experience id={this.props.id} experience={this.props.experience} toggleExperience={this.props.toggleExperience} data={this.props.detail} />
                <Association id={this.props.id} association={this.props.association} toggleAssociation={this.props.toggleAssociation} data={this.props.detail} />
                <Certification id={this.props.id} certification={this.props.certification} toggleCertification={this.props.toggleCertification} data={this.props.detail} />
                <ClinicDetails id={this.props.id} clinic={this.props.clinic} toggleClinic={this.props.toggleClinic} data={this.props.detail} />
                <Award id={this.props.id} award={this.props.award} toggleAward={this.props.toggleAward} data={this.props.detail} />
                <Publication id={this.props.id} publication={this.props.publication} togglePublication={this.props.togglePublication} data={this.props.detail} />
                <Documentation id={this.props.id} documentation={this.props.documentation} toggleDocumentation={this.props.toggleDocumentation} data={this.props.detail} />
                <Practice id={this.props.id} practice={this.props.practice} togglePractice={this.props.togglePractice} data={this.props.detail} />
                <LinkSetup id={this.props.id} link={this.props.link} toggleLink={this.props.toggleLink} data={this.props.detail} />
                <BankDetail id={this.props.id} bank={this.props.bank} toggleBank={this.props.toggleBank} data={this.props.detail} />
                <Schedule id={this.props.id} schedule={this.props.schedule} toggleSchedule={this.props.toggleSchedule} data={this.props.detail} />
                <StatusModal show={this.props.modalActive} msg={this.props.msg} onHide={this.props.toggleAcitve} status_id={this.props.status_id} Update={this.props.Update} />

            </tbody>
        )
    }
}
export default DoctorGrid;
