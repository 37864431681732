import React, { useState, useEffect } from 'react'
import { getDoctor, loadAllDoctorData } from '../../store/doctor'
import { connect } from 'react-redux'
import Select from 'react-select'
import DateRangePicker from 'rsuite/DateRangePicker'
import moment from 'moment'

import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'

const initialState = {
  doctor_id: '',
  appointment_type: '',
  status: '',
  filterByDate: { label: 'Appointment Date', value: 'appointment' },
  filterDateRange: [],
}

const customStyles = {
  container: provided => ({
    ...provided,
    width: 180,
  }),
  option: (provided, state) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'rgb(95, 50, 187, 10%)',
      color: '#621ac0',
    },

    'color': state.isSelected ? '#621ac0' : 'black',
    'backgroundColor': state.isSelected
      ? 'rgb(95, 50, 187, 10%)'
      : provided.backgroundColor,
    //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
  }),
  control: (base, state) => ({
    ...base,
    'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
    'boxShadow': '0 !important',
    '&:hover': {
      borderColor: '#6119c0',
    },
    '&:focus': {
      borderColor: '#6119c0',
    },
  }),
}

const statusOptions = [
  { label: 'Pending', value: 0 },
  { label: 'Paid', value: 1 },
  { label: 'Completed', value: 2 },
  { label: 'Cancelled', value: 3 },
]

const typeOptions = [
  { label: 'Online', value: 'online' },
  { label: 'Clinic', value: 'clinic' },
]

const dateFilterOptions = [
  { label: 'Appointment Date', value: 'appointment' },
  { label: 'Created Date', value: 'booking' },
]

const Search = props => {
  const [searchData, setSearchData] = useState({ ...initialState })

  const onSearch = e => {}

  const reset = () => {
    setSearchData({ ...initialState })
  }

  useEffect(() => {
    console.log('searchData useEffect', searchData)
    let queryData = {}
    Object.keys(searchData).map(ele => {
      if (
        searchData[ele] &&
        ele !== 'filterByDate' &&
        ele !== 'filterDateRange'
      ) {
        queryData[ele] = searchData[ele].value
      }
    })
    if (searchData.filterByDate && searchData.filterDateRange.length > 0) {
      queryData[searchData.filterByDate.value + '_start_date'] = moment(
        searchData.filterDateRange[0]
      ).format('YYYY-MM-DD')
      queryData[searchData.filterByDate.value + '_end_date'] = moment(
        searchData.filterDateRange[1]
      ).format('YYYY-MM-DD')
    }
    props.handleSearch(queryData)
  }, [searchData])

  useEffect(() => {
    props.loadAllDoctor()
  }, [])

  const doctorOptions =
    props.doctor &&
    props.doctor.map(ele => ({ label: ele.name, value: ele._id }))

  return (
    <>
      <form className='ml-5 d-flex' onSubmit={onSearch}>
        <div className='input-group input-group-sm input-group-solid'>
          <Select
            onChange={newVal => {
              setSearchData({ ...searchData, doctor_id: newVal })
            }}
            menuPlacement='auto'
            menuPosition='fixed'
            placeholder='Select Doctor'
            style={{ width: '300px' }}
            styles={customStyles}
            className='form-groupxx'
            closeMenuOnSelect={true}
            options={doctorOptions}
            value={searchData.doctor_id}
          />
        </div>
        <div className='input-group input-group-sm input-group-solid ml-3 '>
          <Select
            onChange={newVal => {
              setSearchData({ ...searchData, status: newVal })
            }}
            menuPlacement='auto'
            menuPosition='fixed'
            placeholder='Appointment Status'
            style={{ width: '300px' }}
            styles={customStyles}
            className='form-groupxx'
            closeMenuOnSelect={true}
            options={statusOptions}
            value={searchData.status}
          />
        </div>
        <div className='input-group input-group-sm input-group-solid ml-3'>
          <Select
            onChange={newVal => {
              setSearchData({ ...searchData, appointment_type: newVal })
            }}
            menuPlacement='auto'
            menuPosition='fixed'
            placeholder='Appointment Type'
            style={{ width: '300px' }}
            styles={customStyles}
            className='form-groupxx'
            closeMenuOnSelect={true}
            options={typeOptions}
            value={searchData.appointment_type}
          />
        </div>
        <div className='ml-3'>
          <DateRangePicker
            cleanable={false}
            value={searchData.filterDateRange}
            placement='auto'
            onChange={value => {
              setSearchData({ ...searchData, filterDateRange: value })
            }}
            ranges={[
              {
                label: 'today',
                value: [startOfDay(new Date()), endOfDay(new Date())],
              },
              {
                label: 'yesterday',
                value: [
                  startOfDay(addDays(new Date(), -1)),
                  endOfDay(addDays(new Date(), -1)),
                ],
              },
              {
                label: 'last7Days',
                value: [
                  startOfDay(subDays(new Date(), 6)),
                  endOfDay(new Date()),
                ],
              },
              {
                label: 'Last 30 Days',
                value: [
                  startOfDay(subDays(new Date(), 30)),
                  endOfDay(new Date()),
                ],
              },
              {
                label: 'Last One Year',
                value: [
                  startOfDay(subDays(new Date(), 365)),
                  endOfDay(new Date()),
                ],
              },
            ]}
          />
        </div>
        <div className='input-group input-group-sm input-group-solid ml-3'>
          <Select
            onChange={newVal => {
              setSearchData({ ...searchData, filterByDate: newVal })
            }}
            menuPlacement='auto'
            menuPosition='fixed'
            placeholder='Date filter Type'
            style={{ width: '300px' }}
            styles={customStyles}
            className='form-groupxx'
            closeMenuOnSelect={true}
            options={dateFilterOptions}
            value={searchData.filterByDate}
          />
        </div>
      </form>
      <form className='ml-5' onSubmit={reset}>
        <div
          className='input-group input-group-sm input-group-solid'
          style={{ maxWidth: '195px' }}
          onClick={reset}>
          <a href='javascript:void (0)' type='submit'>
            <span
              className='text-dark-50 font-weight-bold'
              style={{ cursor: 'pointer' }}>
              Reset
            </span>
          </a>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = state => ({
  doctor: getDoctor(state).allDoctors,
})
const mapDispatchToProps = dispatch => ({
  loadAllDoctor: params => dispatch(loadAllDoctorData(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
