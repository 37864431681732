import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const state = useSelector((state) => state.entities.admin)
    const isLoggedIn = state.isLoggedIn
    return (
        <Route
        {...rest}
        render={(props) =>
            !isLoggedIn ? (
            <Redirect to="/" />
            ) : (
            <Component {...props} />
            )
        }
        />
    );
};

export default PrivateRoute;
