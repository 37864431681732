import React from 'react';
import { Modal } from 'react-bootstrap'
import Joi from "joi-browser";
import closeBtn from "../../include/media/close.svg";
import { getAppointment, appointmentAllRefund, loadAllAppointment } from "../../store/appointment";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
    informationSaved,
    failed
} from "../common/misc";
import Form from "../common/form/form";
import { connect } from "react-redux";

const initialState = {
    data: {
        amount: "",
        notes: "",

    },
    errors: {
        amount: "",
        notes: "",

    },
}
class Refund extends Form {
    state = initialState;
    schema = {
        amount: Joi.number()
            .required()
            .label("Amount")
            .error(() => {
                return { message: "Amount field is required." };
            }),
        notes: Joi.string()
            .required()
            .label("Notes")
            .error(() => {
                return { message: "Notes field is required." };
            }),
    }
    doSubmit = () => {
        const { amount, notes } = this.state.data;
        var formdata = {
            amount: amount,
            notes: notes,
        }

        const id = this.props.refund_id
        this.props.appointmentAllRefund(id, formdata, this.callback);

    };

    callback = (res) => {
        console.log(res.data.error)
        if (res && res.status === 200) {
            this.props.loadAllAppointment({ page: this.props.initialPage });
            toast(<AlertSuccess message={informationSaved} />)
            this.setState(initialState)
        }
        else {
            this.setState(initialState)
            this.props.loadAllAppointment({ page: this.props.initialPage });
            toast(<AlertError message={res && res.data && res.data.error && res.data.error.error && res.data.error.error.description || res.data.error} />)
        }

        this.props.onHide();

    };
    render() {
        return (
            <div className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <Modal
                            {...this.props}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <div className="modal-header"><h3></h3>
                                <button type="button" className="close mt-2" data-dismiss="modal" onClick={this.props.onHide}>
                                    <img src={closeBtn} alt="close popup" />
                                </button>
                            </div>
                            <form action="" onSubmit={this.handleSubmit}>
                                <div className="modal-body">
                                    <h3>Refund</h3>
                                    <div className="modal-alert-box">
                                        {this.renderInput("amount", "Amount")}
                                        {this.renderInput("notes", "Notes")}
                                    </div>
                                    <div className="modal-action-btn">
                                        <button type="submit" className="btn btn-delete">Yes</button>
                                        <a href="javascript:void (0)" onClick={this.props.onHide} className="btn btn-cancel" data-dismiss="modal" aria-label="Close">No</a>
                                    </div>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state) => ({
    appointment: getAppointment(state),
    pagination: getAppointment(state).pagination,
});
const mapDispatchToProps = (dispatch) => ({
    loadAllAppointment: (params) => dispatch(loadAllAppointment(params)),
    appointmentAllRefund: (id, data, callback) => dispatch(appointmentAllRefund(id, data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Refund);
