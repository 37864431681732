import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import Moment from 'moment';
import { getAssessment, loadAllAssessment, addAssessment } from "../../store/assessment";
import { getAssessmentCategory, loadAllAssessmentcategory } from "../../store/assessmentCategories";
import { getScoring, loadAllScoring } from "../../store/scoringReference";
import {

    title,
    informationSaved,
    failed,
    category
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const initialState = {
    data: {
        title: "",
        category: {},
        scoring: {},
        header_text: "",
        footer_text: "",
    },
    errors: {},
}
class AddAssessment extends Form {
    state = initialState;
    schema = {
        title: Joi.string()
            .required()
            .label("Form Title")
            .error(() => {
                return { message: title };
            }),
        header_text: Joi.string().allow("")
            .label("Header Text"),

        footer_text: Joi.string().allow("")
            .label("Footer Text"),

        category:
            Joi.object()
                .label("Category"),

        scoring: Joi.object()
            .label("Scoring").allow("")

    };

    doSubmit = () => {
        const { title, category, scoring, header_text, footer_text } = this.state.data;
        var formdata;
        if (scoring) {
            if (Object.keys(category).length === 0) {
                this.setState({ errors: { category: "Category field is required" } })
            } else {
                formdata = {
                    title: title,
                    footer_text: footer_text,
                    header_text: header_text,
                    category: category.value,
                    assessment_scoring_reference: scoring.value
                }
                this.props.addAssessment(formdata, this.callback);

            }

        }
        else {
            if (Object.keys(category).length === 0) {
                this.setState({ errors: { category: "Category field is required" } })
            }
            else {
                formdata = {
                    title: title,
                    footer_text: footer_text,
                    header_text: header_text,
                    category: category.value,
                }
                this.props.addAssessment(formdata, this.callback);

            }
        }
    };

    callback = (res) => {
        if (res && res.status === 200) {
            this.props.loadAllAssessment();
            this.props.toggleAddUser();
            toast(<AlertSuccess message={informationSaved} />)
            this.setState(initialState)
        }
        else if (res && res.status === 409) {
            this.props.toggleAddUser();
            toast(<AlertError message={"Title must be unique"} />)
            this.setState(initialState)
        }
        else {
            toast(<AlertError message={failed} />)
        }
    };
    animatedComponents = makeAnimated();
    componentDidMount = () => {
        this.props.loadAllAssessmentcategory();
        this.props.loadAllAssessment();
        this.props.loadAllScoring();

    }
    customStyles = {
        option: (provided, state) => ({
            ...provided,
            "&:hover": {
                backgroundColor: "rgb(95, 50, 187, 10%)",
                color: "#621ac0",
            },

            color: state.isSelected ? "#621ac0" : "black",
            backgroundColor: state.isSelected
                ? "rgb(95, 50, 187, 10%)"
                : provided.backgroundColor,
            //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
        }),
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? "#6119c0" : "#bdbdbd",
            boxShadow: "0 !important",
            "&:hover": {
                borderColor: "#6119c0",
            },
            "&:focus": {
                borderColor: "#6119c0",
            },
        }),
    };

    toggleClose = () => {
        this.setState(initialState);
        this.props.toggleAddUser();
    };
    render() {
        const { assessmentcategory, assessment, scoring } = this.props;
        return (
            <>
                <div className={this.props.isShow === true ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on " : "offcanvas offcanvas3 offcanvas-left p-10"} >
                    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                        <h3 className="font-weight-bold m-0">Add Assessment</h3>
                        <a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.toggleClose}>
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                    <hr />
                    <form action="" onSubmit={this.handleSubmit}>
                        {this.renderInput("title", "Form Title")}
                        {this.renderInput("header_text", "Header Text")}
                        {this.renderInput("footer_text", "Footer Text")}

                        <div className="form-group row">
                            <div className="col-lg-12">
                                <label>Category</label>
                                <Select
                                    key={this.state.data.category.length}
                                    onChange={(e) => {
                                        const data = { ...this.state.data };
                                        if (e) {
                                            data.category = e
                                            this.setState({ data })
                                        }
                                    }}
                                    placeholder=""
                                    styles={this.customStyles}
                                    className="form-groupxx"
                                    closeMenuOnSelect={true}
                                    components={this.animatedComponents}
                                    options={assessmentcategory.map((e) => ({
                                        label: e.name,
                                        value: e._id,
                                    }))}
                                    value={this.state.data.category}
                                />
                                {this.state.errors.category && <div className="fv-plugins-message-container">
                                    <div data-validator="notEmpty" className="fv-help-block">{this.state.errors.category}</div>
                                </div>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <label>Scoring Reference</label>
                                <Select
                                    key={this.state.data.scoring.length}
                                    onChange={(e) => {
                                        const data = { ...this.state.data };
                                        if (e) {
                                            data.scoring = e
                                            this.setState({ data })
                                        }
                                    }}
                                    placeholder=""
                                    styles={this.customStyles}
                                    className="form-groupxx"
                                    closeMenuOnSelect={true}
                                    components={this.animatedComponents}
                                    options={scoring.map((e) => ({
                                        label: e.code,
                                        value: e._id,
                                    }))}
                                    value={this.state.data.scoring}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                            <div className="my-3 mr-2">
                                <span className="text-muted mr-2"></span>
                                <a href="#" className="font-weight-bold"></a>
                            </div>
                            <button type="submit" className="btn btn-primary font-weight-bolder" >
                                Save & Add Question
                            </button>
                        </div>
                    </form>
                </div>
                {this.props.isShow === true ? <div className="offcanvas-overlay"></div> : ""}
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    assessment: getAssessment(state).assessment,
    assessmentcategory: getAssessmentCategory(state).assessmentcategory,
    scoring: getScoring(state).scoring
});
const mapDispatchToProps = (dispatch) => ({
    loadAllAssessment: () => dispatch(loadAllAssessment()),
    addAssessment: (param, callback) => dispatch(addAssessment(param, callback)),
    loadAllAssessmentcategory: () => dispatch(loadAllAssessmentcategory()),
    loadAllScoring: () => dispatch(loadAllScoring()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddAssessment);