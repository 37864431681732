import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "faq",
  initialState: {
    faq: [],
    update: {},
    add: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    faqRequested: (message, action) => {
      message.loading = true;
    },

    faqReceived: (faq, action) => {
      faq.faq = action.payload.data;
      faq.loading = false;
      faq.lastFetch = Date.now();
    },

    faqRequestFailed: (faq, action) => {
      faq.loading = false;
    },
    updatedFaq: (faq, action) => {
      faq.update = action.payload;
      faq.loading = false;
      faq.lastFetch = Date.now();
    },

    updatedFaqRequestFailed: (faq, action) => {
      faq.loading = false;
    },
    faqAdded: (faq, action) => {
      faq.add = action.payload;
      faq.loading = false;
      faq.lastFetch = Date.now();
    },

    faqAddRequestFailed: (faq, action) => {
      faq.loading = false;
    },
  },
});

export const {
  faqRequested,
  faqReceived,
  faqRequestFailed,
  updatedFaq,
  updatedFaqRequestFailed,
  faqAdded,
  faqAddRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "faq/";

export const loadAllFaq = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: faqRequested.type,
      onSuccess: faqReceived.type,
      onError: faqRequestFailed.type,
    })
  );
};
export const updateFaq = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedFaq.type,
      onError: updatedFaqRequestFailed.type,
    })
  );
};
export const deletedFaq = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "delete",
      callback,
    })
  );
};

export const addFaq = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: faqAdded.type,
      onError: faqAddRequestFailed.type,
    })
  );
};

export const getFaq = createSelector(
  (state) => state.entities.faq,
  (faq) => faq
);
