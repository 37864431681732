import React, { Component } from 'react'
import moment from 'moment'
import Payment from './payment'
import Refund from './refund'
import { Link } from 'react-router-dom'
import AddComment from './AddComment'
import Comments from './Comments'

class AppointmentGrid extends Component {
  state = {
    showAddComment: false,
    showComments: false,
    currentData: null,
  }

  toggleAddComment = () => {
    this.setState({ showAddComment: !this.state.showAddComment })
  }

  toggleComments = () => {
    this.setState({ showComments: !this.state.showComments })
  }

  capitalize = s => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  render() {
    return (
      <tbody className='datatable-body'>
        {this.props.appointment.map((e, i) => (
          <>
            <tr data-row='10' className='datatable-row' key={i}>
              <td className='datatable-cell'>
                <span style={{ width: '125px' }}>
                  <div className='font-weight-bolder font-size-lg mb-0'>
                    {e.doctor_id && e.doctor_id.name}
                  </div>
                  <div className='font-weight-bold text-muted'></div>
                </span>
              </td>
              <td className='datatable-cell'>
                <span style={{ width: '150px' }}>
                  <div className='font-weight-bolder font-size-lg mb-0'>
                    {e.patient_id &&
                      e.patient_id.first_name &&
                      e.patient_id.first_name + ' ' + e.patient_id.last_name}
                  </div>
                  <div className='font-weight-bold text-muted'></div>
                </span>
              </td>
              <td className='datatable-cell'>
                <span style={{ width: '100px' }}>
                  {e.status === 1 ? (
                    <div
                      className='font-weight-bolder font-size-lg mb-0'
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.props.togglePayment(e.payment_items, e)
                      }>
                      {e.amount} INR
                    </div>
                  ) : (
                    <div
                      className='font-weight-bolder font-size-lg mb-0'
                      style={{ cursor: 'not-allowed' }}>
                      {e.amount} INR
                    </div>
                  )}
                  <div className='font-weight-bold text-muted'></div>
                </span>
              </td>
              <td className='datatable-cell'>
                <span style={{ width: '100px' }}>
                  {e.refund_info ? e.refund_info.amount / 100 : 0}
                </span>
              </td>
              <td className='datatable-cell'>
                <span style={{ width: '130px' }}>
                  <div className='font-weight-bolder font-size-lg mb-0'>
                    {moment(e.appointment_time).utc().format('DD-MM-YYYY')}
                  </div>
                  <div className='font-weight-bold text-muted'>
                    {this.formatAMPM(new Date(e.appointment_time))}
                  </div>
                </span>
              </td>
              <td className='datatable-cell'>
                <span style={{ width: '90px' }}>
                  <div className='font-weight-bolder font-size-lg mb-0'>
                    {this.capitalize(e.appointment_type)}
                  </div>
                  <div className='font-weight-bold text-muted'></div>
                </span>
              </td>
              <td className='datatable-cell'>
                <span style={{ width: '90px' }}>
                  {e.status === 0 && (
                    <div className='font-weight-bolder font-size-lg mb-0'>
                      Pending
                    </div>
                  )}

                  <div className='font-weight-bolder font-size-lg mb-0'>
                    {e.status === 1
                      ? 'Paid'
                      : e.status === 2
                      ? 'Completed'
                      : 'Cancelled'}
                  </div>

                  <div className='font-weight-bold text-muted'></div>
                </span>
              </td>
              <td className='datatable-cell'>
                <span style={{ width: '125px' }}>
                  <div className='font-weight-bolder font-size-lg mb-0'>
                    {moment(e.created_at).format('DD-MM-YYYY')}
                  </div>
                  <div className='font-weight-bold text-muted'></div>
                </span>
              </td>
              <td
                data-field='Actions'
                data-autohide-disabled='false'
                aria-label='null'
                className='datatable-cell'>
                <span
                  style={{
                    overflow: 'visible',
                    position: 'relative',
                    width: '155px',
                  }}>
                  {e.booked && (e.status === 1 || e.status === 2) && (
                    <Link
                      to={'#'}
                      onClick={() => this.props.toggle(e._id)}
                      className='btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2'
                      title='Refund'>
                      <span className='svg-icon svg-icon-md'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                          version='1.1'>
                          <g
                            stroke='none'
                            strokeWidth='1'
                            fill='none'
                            fillRule='evenodd'>
                            <rect x='0' y='0' width='24' height='24' />
                            <path
                              d='M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z'
                              fill='#000000'
                              opacity='0.3'
                              transform='translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) '
                            />
                            <path
                              d='M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z'
                              fill='#000000'
                            />
                          </g>
                        </svg>
                      </span>
                    </Link>
                  )}
                  <span
                    className='btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2'
                    onClick={() => {
                      this.setState({ currentData: e })
                      this.toggleComments()
                    }}
                    style={{
                      backgroundColor:
                        e.comments && e.comments.length > 0 ? '#621ac0' : '',
                    }}>
                    <i
                      className='flaticon-comment'
                      style={{
                        color:
                          e.comments && e.comments.length > 0 ? '#fff' : '',
                      }}></i>
                  </span>
                  <span
                    className='btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2'
                    onClick={() => {
                      this.setState({ currentData: e })
                      this.toggleAddComment()
                    }}>
                    <i className='flaticon2-plus-1'></i>
                  </span>
                </span>
              </td>
              <Payment
                payment={this.props.payment}
                togglePayment={this.props.togglePayment}
                payData={this.props.payData}
                data={this.props.data}
              />
            </tr>

            <AddComment
              handleLoad={this.props.handleLoad}
              show={this.state.showAddComment}
              onHide={() => {
                this.setState({ currentData: null }, () =>
                  this.toggleAddComment()
                )
              }}
              currentData={this.state.currentData}
            />

            <Comments
              show={this.state.showComments}
              onHide={() => {
                this.setState({ currentData: null }, () =>
                  this.toggleComments()
                )
              }}
              currentData={this.state.currentData}
            />

            <Refund
              show={this.props.modal}
              onHide={this.props.toggle}
              refund_id={this.props.refund_id}
              amount={e.amount}
              initialPage={this.props.initialPage}
            />
          </>
        ))}
      </tbody>
    )
  }
}
export default AppointmentGrid
