import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import Moment from 'moment';
import { loadAllPatient, updatePatient, getProduct } from "../../store/patient";
import {
  mobileInvalid,
  mobileRequired,
  accountNotExist,
  dobRequired,
  dobInvalid,
  informationSaved,
  failed
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

const initialState = {
  data: {
    first_name: "",
    last_name: "",
    mobile: "",
    date_of_birth: "",
  },
  errors: {
    first_name: "",
    last_name: "",
    mobile: "",
    date_of_birth: "",
  },
};

class EditPatient extends Form {
  state = initialState;
  schema = {
    first_name: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: "" };
      }),
    last_name: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: "" };
      }),
    mobile: Joi.number()
      .label("Mobile number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        console.log(errors);
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
			err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;
          }
        });
        return errors;
      }),
    date_of_birth: Joi.string()
      .max(new Date().setFullYear(new Date().getFullYear() - 18))
      .label("Date of birth")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "date.base":
              err.message = dobRequired;
              break;

            case "date.max":
              err.message = dobInvalid;
              break;
          }
        });
        return errors;
      }),
  };
  doSubmit = () => {
    const { data } = this.state;
    const id = this.props.id;
    this.props.updatePatient(id, data, this.callback);
  };

	callback = (res) => {
      if (res && res.status === 200) {
        this.props.loadAllPatient({"page":this.props.initialPage});
        toast(<AlertSuccess message={informationSaved}/>)
      } 
      else{
        toast(<AlertError message={failed}/>)
      }
    };
  
    componentDidMount = () => {
      this.setState({
        data: {
          first_name: this.props.data.first_name,
          last_name: this.props.data.last_name,
          mobile: this.props.data.mobile,
          date_of_birth: this.props.data.dob,
        },
      });
	};
	
  componentWillReceiveProps(nextProps){
	 if(nextProps.id){
	  this.setState({
		data: {
            first_name: nextProps.data.first_name,
		  last_name: nextProps.data.last_name,
		  mobile: nextProps.data.mobile,
		  date_of_birth:nextProps.data.mobile,
		},
	  });  
	}
  }
	

  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Edit Patient Detail</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleEditUser}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr/>
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("first_name", "First Name")}
            {this.renderInput("last_name", "Last Name")}
            {this.renderInput("mobile", "Mobile Number")}
            {this.renderDateInput("date_of_birth", "DOB")}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button type="submit" className="btn btn-primary font-weight-bolder" >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  patient: getProduct(state).patient,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllPatient: (params) => dispatch(loadAllPatient(params)),
  updatePatient: (id, data, callback) =>
    dispatch(updatePatient(id, data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditPatient);
