import React, { Component } from "react";
import { getDoctor, loadAllDoctor } from "../../store/doctor";
import { connect } from "react-redux";
import SearchIcon from "../common/searchIcon";
import { getMisc, loadFieldType } from "../../store/misc";

class Search extends Component {
    componentDidMount = () => {
        this.props.loadFieldType();
    };

    render() {
        const { fieldTypes } = this.props.getMisc;
        return (
            <>
                <form onSubmit={this.props.onSearch}>
                    <div className="input-group input-group-sm input-group-solid" onClick={this.props.onSearch} style={{ "maxWidth": "175px" }}>
                        <input
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="Search By Name"
                            autoComplete="off"
                            dir="ltr"
                            value={this.props.name}
                            onChange={(e) => { this.props.changeName(e.target.value) }}

                        />
                        <SearchIcon />
                    </div>
                </form>
                <form onSubmit={this.props.onSearch}>
                    <div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "175px" }} onClick={this.props.onSearch}>
                        <input
                            name="mobile"
                            id="mobile"
                            className="form-control"
                            placeholder="Search By Mobile"
                            autoComplete="off"
                            dir="ltr"
                            value={this.props.mobile}
                            onChange={(e) => { this.props.changeMobile(e.target.value) }}

                        />
                        <SearchIcon />
                    </div>
                </form>
                <form onSubmit={this.props.onSearch}>
                    <div className="input-group input-group-sm input-group-solid" onClick={this.props.onSearch}>
                        <input
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Search By Email"
                            autoComplete="off"
                            value={this.props.email}
                            onChange={(e) => { this.props.changeEmail(e.target.value) }}

                        />
                        <SearchIcon />
                    </div>
                </form>
                <form>
                    <div className="input-group input-group-sm input-group-solid">
                        <div class="row">
                            <div class="col-12">
                                <select id="field" class="form-control" onChange={(e) => { this.props.handleChange(e.target.value) }}
                                >
                                    <option value="0" disabled selected>Field Types</option>
                                    {fieldTypes.map((e, i) => (<option key={e.id} value={e.id}>{e.name}</option>))}
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
                <form className="mr-5">
                    <div className="input-group input-group-sm input-group-solid">
                        <div class="row">
                            <div class="col-12">
                                <select id="featured" class="form-control" onChange={(e) => { this.props.handleFeatured(e.target.value) }}>
                                    <option value="0" disabled selected>Featured </option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>

                <form onSubmit={this.props.reset}>
                    <div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "75px" }} onClick={this.props.reset}>
                        <a href="javascript:void (0)" type="submit">
                            <span className="text-dark-50 font-weight-bold" style={{ "cursor": "pointer" }}>
                                Reset
                            </span>
                        </a>
                    </div>
                </form>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    doctor: getDoctor(state).doctor,
    getMisc: getMisc(state),
});
const mapDispatchToProps = (dispatch) => ({
    loadAllDoctor: (params) => dispatch(loadAllDoctor(params)),
    loadFieldType: () => dispatch(loadFieldType()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
