import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import Moment from 'moment';
import { getBlogCategory,loadAllBlogcategory,updateBlogcategory } from "../../store/blogCategory";
import {
  informationSaved,
  failed,
  categoryRequired,
  photo,
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import seUploadFun from "../services/s3Services";

const initialState = {
    photoStatus: "Add file",
	displayImage: "",
  data: {
    name: "",
    image:""
   
  },
  errors: {
    name: "",
    image:""
    
  },
};

class EditBlogCategory extends Form {
  state = initialState;
  
  schema = {
    name: Joi.string()
      .required()
      .label("Category Name")
      .error(() => {
        return { message: categoryRequired };
      }),
      image: Joi.string()
      .required()
      .label("Category Image")
      .error(() => {
        return { message: photo};
	}), 
  };
  doSubmit = () => {
    const { data } = this.state;
    const id = this.props.id;
    this.props.updateBlogcategory(id, data, this.callback);
  };

	callback = (res) => {
      if (res && res.status === 200) {
        this.props.loadAllBlogcategory();
        this.props.toggleEditUser();
        toast(<AlertSuccess message={informationSaved}/>)
      } 
      else{
        toast(<AlertError message={failed}/>)
      }
    };
    fileUpload = (e) => {
		this.setState({ photoStatus: "Loading" });
		const fileName = "blogcategory/photo/" + Date.now();
		const res = seUploadFun(e.target.files[0], fileName);
		  res.then((result) => {
		  const { data, errors } = this.state;
		  data.image = result.key;
		  errors.image = false;
		  this.setState({
			photoStatus: "Uploaded successfully. Click here to change",
			data,
			displayImage: process.env.REACT_APP_S3URL + result.key,
		  });
        });
	  };
    componentDidMount = () => {
      this.setState({
        data: {
          name: this.props.data.name,
          image:this.props.data.image,
        },
        
      });
	};
    componentWillReceiveProps(nextProps){
	 if(nextProps.id){
	  this.setState({
		data: {
          name: nextProps.data.name,
          image:nextProps.data.image,
		},
	  });  
	}
  }

  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Edit Blog Category</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleEditUser}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr/>
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("name", "Category Name")}
            <div className="form-group row">
            <div className="col-lg-12">
                <label className={
                  this.state.errors.image
                    ? "errorColor"
                    : ""
                    }
                htmlFor="photograph2">Upload Image</label>
                  <input
                    accept="image/*"
                    type="file"
                    id="photograph2"
                    className="form-control "
                    onChange={this.fileUpload}
                    disabled={this.state.photoStatus === "Loading"}
                  />
                  {this.state.data.image && (
                    <a
                      className="add-more-field"
                      href={
                        process.env.REACT_APP_S3URL + this.state.data.image
                      }
                      style={{ fontSize: "11px" }}
                      target="_blank"
                      download
                    >
                    </a>
                  )}
                  {this.state.errors.image && (
                    <div className="fv-plugins-message-container">
                      <div data-validator="notEmpty" className="fv-help-block">{this.state.errors.image}
                      </div>
                  </div>
                  )}
                </div>
            </div>
            <div className="form-group row">
                <div className="col-lg-12">
                    <img
                      className="img-fluid"
                      src={process.env.REACT_APP_S3URL + this.state.data.image}
                      alt=""
                    />
                </div>
            </div>
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button type="submit" className="btn btn-primary font-weight-bolder" >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    blogcategory: getBlogCategory(state).blogcategory,
});
  const mapDispatchToProps = (dispatch) => ({
  loadAllBlogcategory: () => dispatch(loadAllBlogcategory()),
  updateBlogcategory: (id,data,callback) => dispatch(updateBlogcategory(id,data,callback)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(EditBlogCategory);

