import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import Moment from 'moment';
import {
  informationSaved,
  failed,
  title,
  description,
  pageKey
} from "../common/misc";
import { loadAllSeo, updateSeo, getSeo} from "../../store/seo";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

const initialState = {
  data: {
    title:"",
    description: "",
    page_key:"",
  },
  errors: {
    title: "",
    description: "",
    page_key:"",
  },
};

class EditSeo extends Form {
  state = initialState;
  schema = {
    title: Joi.string()
      .required()
      .label("Title")
      .error(() => {
        return { message: title };
      }),
    description: Joi.string()
      .required()
      .label("Description")
      .error(() => {
        return { message: description };
      }),
	page_key: Joi.string()
      .required()
      .label("Page Key")
      .error(() => {
        return { message: pageKey };
      }),
  
  };
  doSubmit = () => {
    const { title,description,page_key } = this.state.data;
    const id = this.props.id;
      var formData={
        title:title,
        description:description,
        page_key:page_key,
        }
        this.props.updateSeo(id, formData, this.callback);
      };

	callback = (res) => {
      if (res && res.status === 200) {
        this.props.loadAllSeo({page:this.props.initialPage});
        this.props.toggleEditUser();
        toast(<AlertSuccess message={informationSaved}/>)
      } 
      else{
        toast(<AlertError message={failed}/>)
      }
    };
  
  componentDidMount = () => {
      this.setState({
        data: {
          title: this.props.data.title,
          description: this.props.data.description,
          page_key:this.props.data.page_key,

        },
      });
	};
	  
  componentWillReceiveProps(nextProps){
	 if(nextProps.id){
	  this.setState({
  		data: {
  		  title: nextProps.data.title,
  		  description: nextProps.data.description,
          page_key:nextProps.data.page_key,

        },
      });
    }
  } 
	

  render() {
    return (
      <>
        <div
          className={
            this.props.editShow === true
              ? "offcanvas offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Edit SEO Tags</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleEditUser}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr/>
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("page_key", "Page Key")}
            {this.renderInput("title", "Title")}
            {this.renderTextarea("description", "Description")}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button type="submit" className="btn btn-primary font-weight-bolder" >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.editShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  seo: getSeo(state).seo,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllSeo: (params) => dispatch(loadAllSeo(params)),
  updateSeo: (id,data,callback) => dispatch(updateSeo(id,data,callback)),
 });

export default connect(mapStateToProps, mapDispatchToProps)(EditSeo);
