import React, { Component } from "react";
import Moment from 'moment';

class Experience extends Component {
 
	render() {
	const {experiences,total_experience} = this.props.data;
		return (
			<>
				<div className={this.props.experience===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Experience</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleExperience}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
						
					</div>
					<hr/>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Experience (in total years): </a>
						<span className="text-muted font-weight-bold education-margin">{total_experience}</span>
					</div>
					<hr/>
					{experiences && experiences .map((e,i)=>(

					<>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Post: </a>
						<span className="text-muted font-weight-bold education-margin">{e.post}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Place: </a>
						<span className="text-muted font-weight-bold education-margin">{e.work_place}</span>
						</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Time Period From: </a>
						<span className="text-muted font-weight-bold education-margin">{Moment(e.from).format('DD-MM-YYYY')}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Time Period To: </a>
						{e.to?
							<span className="text-muted font-weight-bold education-margin">{Moment(e.to).format('DD-MM-YYYY')}</span>:
							<span className="text-muted font-weight-bold education-margin">{Moment().format('DD-MM-YYYY')}</span>
						}
					</div>
					<hr/>
					</>))}
				</div>
				{this.props.experience=== true?<div className="offcanvas-overlay" onClick={this.props.toggleExperience}></div>:""}
			</>
		)
	}
}

export default Experience;