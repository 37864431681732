import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "blog",
  initialState: {
    blog: [],
    pagination: {},
    update: {},
    add: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    blogRequested: (blog, action) => {
      blog.loading = true;
    },

    blogReceived: (blog, action) => {
      blog.blog = action.payload.data;
      blog.pagination = action.payload.pagination;
      blog.loading = false;
      blog.lastFetch = Date.now();
    },

    blogRequestFailed: (blog, action) => {
      blog.loading = false;
    },

    updatedBlog: (blog, action) => {
      blog.update = action.payload;
      blog.loading = false;
      blog.lastFetch = Date.now();
    },

    updatedBlogRequestFailed: (blog, action) => {
      blog.loading = false;
    },
    blogAdded: (blog, action) => {
      blog.add = action.payload;
      blog.loading = false;
      blog.lastFetch = Date.now();
    },

    blogAddRequestFailed: (blog, action) => {
      blog.loading = false;
    },
  },
});

export const {
  blogRequested,
  blogReceived,
  blogRequestFailed,
  updatedBlog,
  updatedBlogRequestFailed,
  blogAdded,
  blogAddRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "blog/";

export const loadAllBlog = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: blogRequested.type,
      onSuccess: blogReceived.type,
      onError: blogRequestFailed.type,
    })
  );
};
export const updateBlog = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedBlog.type,
      onError: updatedBlogRequestFailed.type,
    })
  );
};
export const addBlog = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "post",
      data: param,
      callback,
      onSuccess: blogAdded.type,
      onError: blogAddRequestFailed.type,
    })
  );
};

export const deletedBlog = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "delete",
      callback,
    })
  );
};

export const getBlog = createSelector(
  (state) => state.entities.blog,
  (blog) => blog
);
