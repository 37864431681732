import React from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { loadAllMessage, addMessage, getMessage } from "../../../store/message";

import {
	KeyRequired,
	informationSaved,
	failed,
	messageRequired
} from "../../common/misc";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";
import { Link } from "react-router-dom";

const initialState={
	data: {
		key: "",
		message: "",
		notes: "",
  	},
  	errors: {
		key: "",
		message: "",
		notes: "",
  	},
}
class AddMessage extends Form {
  state = initialState;
  schema = {
    key: Joi.string()
      .required()
      .label("Key")
      .error(() => {
        return { message: KeyRequired };
      }),
    message: Joi.string()
      .required()
      .label("Message")
      .error(() => {
        return { message: messageRequired};
      }),

    notes: Joi.string()
      .label("Notes")
	};
 	
  	doSubmit = () => {
		const { key,message,notes } = this.state.data;
			var formdata={
				key:key,
				message:message,
				notes:notes,
				
			}
		this.props.addMessage(formdata, this.callback);
	};

	callback = (res) => {
      if (res && res.status === 200) {
        this.props.loadAllMessage({page:this.props.initialPage});
        this.props.toggleAddUser();
		toast(<AlertSuccess message={informationSaved}/>)
		this.setState(initialState)
      }
	  else{
		toast(<AlertError message={failed}/>)
	   }
    };
  
	render() {
		return (
			<>
				<div className={this.props.isShow===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Add Message</h3>
						<a href="javascript:void(0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleAddUser}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
					</div>
					<hr/>
					<form action="" onSubmit={this.handleSubmit}>
						{this.renderInput("key", "Key")}
           				{this.renderInput("message", "Message")}
            			{this.renderInput("notes", "Notes")}
                  		<hr />
            			<div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              				<div className="my-3 mr-2">
                				<span className="text-muted mr-2"></span>
                				<Link to={"#"} className="font-weight-bold"></Link>
             				</div>
							<button type="submit" className="btn btn-primary font-weight-bolder" >
								Save
							</button>
            			</div>
					</form>
				</div>
				{this.props.isShow=== true?<div className="offcanvas-overlay"></div>:""}
			</>
		)
	}
}
const mapStateToProps = (state) => ({
  message: getMessage(state).message,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllMessage: (params) => dispatch(loadAllMessage(params)),
  addMessage: (param,callback) => dispatch(addMessage(param,callback)),
 });
export default connect(mapStateToProps, mapDispatchToProps)(AddMessage);