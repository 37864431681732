import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "section",
  initialState: {
    section: [],
    pagination:{},
    update: {},
    add:{},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    sectionRequested: (section, action) => {
      section.loading = true;
    },

    sectionReceived: (section, action) => {
      section.section = action.payload.data;
      section.pagination=action.payload.pagination;
      section.loading = false;
      section.lastFetch = Date.now();
    },

    sectionRequestFailed: (section, action) => {
      section.loading = false;
    },
    
    updatedSection: (section, action) => {
      section.update = action.payload;
      section.loading = false;
      section.lastFetch = Date.now();
    },

    updatedSectionRequestFailed: (section, action) => {
      section.loading = false;
    },
    sectionAdded: (section, action) => {
      section.add = action.payload;
      section.loading = false;
      section.lastFetch = Date.now();
    },

    sectionAddRequestFailed: (section, action) => {
      section.loading = false;
    },
  },
});

export const {
  sectionRequested,
  sectionReceived,
  sectionRequestFailed,
  updatedSection,
  updatedSectionRequestFailed,
  sectionAdded,
  sectionAddRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "section/";


export const loadAllSection = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: sectionRequested.type,
      onSuccess: sectionReceived.type,
      onError: sectionRequestFailed.type,
    })
  );
};
export const updateSection = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url  + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedSection.type,
      onError: updatedSectionRequestFailed.type,
    })
  );
};
export const addSection = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: sectionAdded.type,
      onError: sectionAddRequestFailed.type,
    })
  );
};

export const deletedSection = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url:url + id,
      method: "delete",
      callback,
    })
  );
};

export const getSection = createSelector(
  (state) => state.entities.section,
  (section) => section
);
