import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'
//import moment from "moment";

const slice = createSlice({
  name: 'doctor',
  initialState: {
    doctor: [],
    allDoctors: [],
    pagination: {},
    update: {},
    loading: false,
    lastFetch: null,
    doctorDetail: {},
    doctorDetailLoading: false,
  },
  reducers: {
    doctorRequested: (doctor, action) => {
      doctor.loading = true
    },

    doctorReceived: (doctor, action) => {
      doctor.doctor = action.payload.data
      doctor.pagination = action.payload.pagination
      doctor.loading = false
      doctor.lastFetch = Date.now()
    },

    doctorAllDataReceived: (doctor, action) => {
      doctor.allDoctors = action.payload.data
      doctor.loading = false
      doctor.lastFetch = Date.now()
    },

    doctorRequestFailed: (doctor, action) => {
      doctor.loading = false
    },
    doctorDetailRequested: (doctor, action) => {
      doctor.doctorDetailLoading = true
    },
    updatedApproved: (doctor, action) => {
      doctor.update = action.payload
      doctor.loading = false
      doctor.lastFetch = Date.now()
    },
    updatedDoctor: (doctor, action) => {
      doctor.update = action.payload
      doctor.loading = false
      doctor.lastFetch = Date.now()
    },

    updatedDoctorRequestFailed: (doctor, action) => {
      doctor.loading = false
    },
    updatedApprovedRequestFailed: (doctor, action) => {
      doctor.loading = false
    },
    doctorDetailReceived: (doctor, action) => {
      doctor.doctorDetail = action.payload.data
      doctor.doctorDetailLoading = false
    },
    doctorDetailRequestFailed: (doctor, action) => {
      doctor.doctorDetailLoading = false
    },
  },
})

export const {
  updatedDoctor,
  updatedDoctorRequestFailed,
  doctorRequested,
  doctorReceived,
  doctorRequestFailed,
  doctorDetailRequested,
  doctorDetailReceived,
  doctorDetailRequestFailed,
  updatedApprovedRequestFailed,
  updatedApproved,
  doctorAllDataReceived,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'doctor/'

export const loadAllDoctorData = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'list/alldoctors',
      method: 'post',
      params,
      onStart: doctorRequested.type,
      onSuccess: doctorAllDataReceived.type,
      onError: doctorRequestFailed.type,
    })
  )
}

export const loadAllDoctor = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: doctorRequested.type,
      onSuccess: doctorReceived.type,
      onError: doctorRequestFailed.type,
    })
  )
}
export const updateDoctor = (id, data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: 'PUT',
      data,
      callback,
      onSuccess: updatedDoctor.type,
      onError: updatedDoctorRequestFailed.type,
    })
  )
}
export const loadDoctorDetail = id => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      onStart: doctorDetailRequested.type,
      onSuccess: doctorDetailReceived.type,
      onError: doctorDetailRequestFailed.type,
    })
  )
}
export const updateApproved = (id, data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: 'PUT',
      data,
      callback,
      onSuccess: updatedApproved.type,
      onError: updatedApprovedRequestFailed.type,
    })
  )
}
export const deletedDoctor = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: 'delete',
      callback,
    })
  )
}

export const getDoctor = createSelector(
  state => state.entities.doctor,
  doctor => doctor
)
