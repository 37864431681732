import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "question",
  initialState: {
    question: [],
    pagination:{},
    update: {},
    add:{},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    questionRequested: (question, action) => {
      question.loading = true;
    },

    questionReceived: (question, action) => {
      question.question = action.payload.data;
      question.pagination=action.payload.pagination;
      question.loading = false;
      question.lastFetch = Date.now();
    },

    questionRequestFailed: (question, action) => {
      question.loading = false;
    },
    
    updatedQuestion: (question, action) => {
      question.update = action.payload;
      question.loading = false;
      question.lastFetch = Date.now();
    },

    updatedQuestionRequestFailed: (question, action) => {
      question.loading = false;
    },
    questionAdded: (question, action) => {
      question.add = action.payload;
      question.loading = false;
      question.lastFetch = Date.now();
    },

    questionAddRequestFailed: (question, action) => {
      question.loading = false;
    },
  },
});

export const {
  questionRequested,
  questionReceived,
  questionRequestFailed,
  updatedQuestion,
  updatedQuestionRequestFailed,
  questionAdded,
  questionAddRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "questionGroup/";


export const loadAllQuestion = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: questionRequested.type,
      onSuccess: questionReceived.type,
      onError: questionRequestFailed.type,
    })
  );
};
export const updateQuestion = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url  + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedQuestion.type,
      onError: updatedQuestionRequestFailed.type,
    })
  );
};
export const addQuestion = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: questionAdded.type,
      onError: questionAddRequestFailed.type,
    })
  );
};

export const deletedQuestion = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url:url + id,
      method: "delete",
      callback,
    })
  );
};

export const getQuestion = createSelector(
  (state) => state.entities.question,
  (question) => question
);
