import React from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { loadAllFaq, updateFaq, getFaq } from "../../../store/faq";
import {
    question,
    answer,
    category,
    informationSaved,
    failed,
    messageRequired
} from "../../common/misc";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";

const initialState = {
    data: {
        question: "",
        answer: "",
        category: "",
    },
    Categories: [
        {
            id: "Psychiatrist",
            name: "Psychiatrist",
        },
        {
            id: "Psychologist",
            name: "Psychologist",
        },

        {
            id: "Psychosocial",
            name: "Psychosocial",
        },
        {
            id: "Counsellor",
            name: "Counsellor",
        },
        {
            id: "Misc",
            name: "Misc",
        },


    ],
    errors: {}
}
class EditFaq extends Form {
    state = initialState;
    schema = {
        question: Joi.string()
            .required()
            .label("Question")
            .error(() => {
                return { message: question };
            }),
        answer: Joi.string()
            .required()
            .label("Answer")
            .error(() => {
                return { message: answer };
            }),

        category: Joi.string()
            .required()
            .label("Category")
            .error(() => {
                return { message: category };
            }),
    };
    doSubmit = () => {
        const { question, answer, category } = this.state.data;
        const id = this.props.id;
        var formData = {
            question: question,
            answer: answer,
            category: category,
        }
        this.props.updateFaq(id, formData, this.callback);
    };

    callback = (res) => {
        if (res && res.status === 200) {
            this.props.loadAllFaq();
            this.props.toggleEditUser();
            toast(<AlertSuccess message={informationSaved} />)
        }
        else {
            toast(<AlertError message={failed} />)
        }
    };

    componentDidMount = () => {
        this.setState({
            data: {
                question: this.props.data.question,
                answer: this.props.data.answer,
                category: this.props.data.category,

            },
        });
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.id !== prevProps.id
        ) {
            this.setState({
                data: {
                    question: this.props.data && this.props.data.question,
                    answer: this.props.data && this.props.data.answer,
                    category: this.props.data && this.props.data.category,
                },
            });
        }
    }
    render() {
        return (
            <>
                <div
                    className={
                        this.props.editShow === true
                            ? "offcanvas offcanvas-left offcanvas3 p-10 offcanvas-on"
                            : "offcanvas offcanvas3 offcanvas-left p-10"
                    }
                >
                    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                        <h3 className="font-weight-bold m-0">Edit FAQ</h3>
                        <a
                            href="javascript:void(0)"
                            className="btn btn-xs btn-icon btn-light btn-hover-primary"
                            onClick={this.props.toggleEditUser}
                        >
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                    <hr />
                    <form action="" onSubmit={this.handleSubmit}>
                        {this.renderInput("question", "Question")}
                        {this.renderTextarea("answer", "Answer")}
                        {this.renderSelect(
                            "category",
                            "Category",
                            this.state.Categories
                        )}
                        <hr />
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                            <div className="my-3 mr-2">
                                <span className="text-muted mr-2"></span>
                                <a href="#/" className="font-weight-bold"></a>
                            </div>
                            <button type="submit" className="btn btn-primary font-weight-bolder" >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
                {this.props.editShow === true ? (
                    <div className="offcanvas-overlay"></div>
                ) : (
                    ""
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    faq: getFaq(state).faq,
});
const mapDispatchToProps = (dispatch) => ({
    loadAllFaq: (params) => dispatch(loadAllFaq(params)),
    updateFaq: (id, data, callback) => dispatch(updateFaq(id, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFaq);
