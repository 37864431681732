import React, { Component } from "react";
import { getBlog, loadAllBlog } from "../../store/blog";
import { connect } from "react-redux";
import SearchIcon from "../common/searchIcon";


class Search extends Component {


    render() {
        return (
            <>
                <form className="ml-5" onSubmit={this.props.onSearch}>
                    <div className="input-group input-group-sm input-group-solid" onClick={this.props.onSearch} style={{ "maxWidth": "195px" }}>
                        <input
                            name="title"
                            id="title"
                            className="form-control"
                            placeholder="Search By Title"
                            autoComplete="off"
                            dir="ltr"
                            value={this.props.title}
                            onChange={(e) => { this.props.changeTitle(e.target.value) }}

                        />
                        <SearchIcon />
                    </div>
                </form>
                <form className="ml-5" onSubmit={this.props.onSearch}>
                    <div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.props.onSearch}>
                        <input
                            name="author"
                            id="author"
                            className="form-control"
                            placeholder="Search By Author"
                            autoComplete="off"
                            dir="ltr"
                            value={this.props.author}
                            onChange={(e) => { this.props.changeAuthor(e.target.value) }}

                        />
                        <SearchIcon />
                    </div>
                </form>
                <form className="ml-5" onSubmit={this.props.onSearch}>
                    <div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.props.onSearch}>
                        <input
                            name="category"
                            id="category"
                            className="form-control"
                            placeholder="Search By Category"
                            autoComplete="off"
                            value={this.props.category}
                            onChange={(e) => { this.props.changeCategory(e.target.value) }}

                        />
                        <SearchIcon />
                    </div>
                </form>
                <form className="ml-5" onSubmit={this.props.reset}>
                    <div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.props.reset}>
                        <a href="javascript:void (0)" type="submit">
                            <span className="text-dark-50 font-weight-bold" style={{ "cursor": "pointer" }}>
                                Reset
                            </span>
                        </a>
                    </div>
                </form>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    blog: getBlog(state).blog,
});
const mapDispatchToProps = (dispatch) => ({
    loadAllBlog: (params) => dispatch(loadAllBlog(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
