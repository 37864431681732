
import React from "react";
import { Helmet } from "react-helmet";

class HelmetComponent extends React.Component {

  render() {
    var defaultTitle = "yesmindy-admin.com";
   
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{this.props.title ? this.props.title: defaultTitle}</title>
       </Helmet>

    );
  }
};

export default HelmetComponent;

