import React, { Component } from "react";
import Moment from 'moment';
import { connect } from "react-redux";
import ModalPopup from "../common/modal";
import StatusModal from "../common/statusModal";
import { Link } from "react-router-dom";
import EditPatient from "./editPatient";

  class PatientEmpty extends Component {
  	
    render(){
      return(
        <tbody className="datatable-body">
        
            <tr
              data-row="10"
              className="datatable-row"
              style={{ left: "0px" }}
            >
              <td className="datatable-cell">
                <span style={{ width: "150px" }}>
                 
                </span>
              </td>
              <td className="datatable-cell">
                <span style={{ width: "250px"}}>
                  <div className="font-weight-bolder font-size-lg mb-0">
                  </div>
                  <div className="font-weight-bold text-muted">
                  </div>
                </span>
              </td>
              <td className="datatable-cell">
                <span style={{ width: "100px" }}>
                  <div className="font-weight-bolder font-size-lg mb-0">
                  </div>
                </span>
              </td>
              <td className="datatable-cell">
                <span style={{ width: "125px" }}>
                 
                </span>
              </td>
              <td className="datatable-cell">
                <span style={{ width: "80px" }}>
                
                </span>
              </td>
              <td className="datatable-cell">
                <span style={{ width: "140px" }}>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    
                   </div>
                </span>
              </td>
                
                    
                   
            </tr>        
        </tbody>
      )
    }
  }
 export default PatientEmpty;
