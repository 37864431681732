import React, { Component } from "react";
import DatePicker from "react-datepicker";


class DateInput extends Component {

    initDatepicker() { }
    render() {
        const { name, label, error, ...rest } = this.props;
        return (
            <div className="form-group row fv-plugins-icon-container has-danger">
                <div className="col-lg-12">
                    <label htmlFor={label}>{label}</label>
                    <DatePicker
                        maxDate={new Date()}
                        name={name}
                        id={label}
                        className={
                            error
                                ? "form-control error"
                                : "form-control "
                        }
                        style={{ "display": "block" }}
                        dateFormat="dd/MM/yyyy"
                        {...rest}
                    />
                    {error && <div className="fv-plugins-message-container">
                        <div data-validator="notEmpty" className="fv-help-block">{error}</div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default DateInput;
