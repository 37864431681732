import axios from "axios";
import * as actions from "../api";
const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  async (response) => {
    if (
      response.config.url !== "admin/signIn" &&
      response.config.url !== "admin/checkLogin"
    ) {
      const headers = {
        "auth-token": process.env.REACT_APP_TOKEN,
      };
      headers["x-auth-token"] = localStorage.getItem("x-auth-token");
      try {
        const response = await axios.request({
          baseURL: process.env.REACT_APP_APIBASE,
          headers,
          url: "admin/checkLogin",
          method: "GET",
        });
      } catch (error) {
        console.log(error);
        localStorage.clear();
        window.location.href = "/";
      }
    }
    return response;
  },
  async (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

const api = ({ dispatch }) => (next) => async (action) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);

  const {
    url,
    method,
    data,
    params,
    onStart,
    onSuccess,
    onError,
    callback = false,
  } = action.payload;
  if (onStart) dispatch({ type: onStart });

  next(action);

  const headers = {
    "auth-token": process.env.REACT_APP_TOKEN,
  };

  if (localStorage.getItem("x-auth-token"))
    headers["x-auth-token"] = localStorage.getItem("x-auth-token");

  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_APIBASE,
      headers,
      url,
      method,
      params,
      data,
    });
    // General
    dispatch(actions.apiCallSuccess(response.data));
    // Specific
    if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
    if (callback) callback(response);
  } catch (error) {
    // General
    dispatch(actions.apiCallFailed(error.message));
    // Specific
    if (onError) dispatch({ type: onError, payload: error.message });
    if (callback) callback(error.response);
  }
};

export default api;
