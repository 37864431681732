import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import HelmetComponent from "../common/helmetComponent";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Joi from "joi-browser";
import Form from "../common/form/form";
import { WithContext as ReactTags } from "react-tag-input";
import CreatableSelect from "react-select/creatable";

import ModalPopup from "../common/modal";

import {
  informationSaved,
  failed,
  title,
  category,
  deleted,
} from "../common/misc";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import Select from "react-select";
import {
  getAssessmentCategory,
  loadAllAssessmentcategory,
  addAssessmentcategory,
} from "../../store/assessmentCategories";
import AddQuestion from "./addQuestion";
import {
  getAssessment,
  loadDetailAssessment,
  deletedQuestionAssessment,
  updateAssessment,
} from "../../store/assessment";
import { getScoring, loadAllScoring } from "../../store/scoringReference";
import _ from "lodash";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const initialState = {
  userShow: false,
  asside: false,
  radio: "",
  data: {
    title: "",
    category: {},
    header_text: "",
    footer_text: "",
    scoring: {},
  },
  problems: [],
  delete_id: "",
  asside3: false,
  modal: false,
  multiSelect: false,
  publish: false,
  asside2: false,
  isShow: false,
  photoStatus: "Add file",
  displayImage: "",
  editShow: false,
  id: "",
  detail: "",
  errors: {},
};
class AssessmentForm extends Form {
  state = initialState;
  schema = {
    title: Joi.string()
      .required()
      .label("Form Title")
      .error(() => {
        return { message: title };
      }),
    header_text: Joi.string().label("Header Text").allow(""),
    footer_text: Joi.string().label("Footer Text").allow(""),
    scoring: Joi.object().label("Scoring").allow(""),
    category: Joi.object()
      .required()
      .error(() => {
        return { message: category };
      }),
  };
  componentDidMount = () => {
    const data = { ...this.state.data };

    this.props.loadAllAssessmentcategory();
    this.props.loadAllScoring();

    this.props.loadDetailAssessment(
      this.props.location.state && this.props.location.state.data._id
    );
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.detail !== prevProps.detail) {
      const data = { ...this.state.data };
      data.title = this.props.detail.data.title;
      data.footer_text = this.props.detail.data.footer_text;
      data.header_text = this.props.detail.data.header_text;
      data.scoring.label =
        this.props.detail.data.assessment_scoring_reference &&
        this.props.detail.data.assessment_scoring_reference.code;
      data.scoring.value =
        this.props.detail.data.assessment_scoring_reference &&
        this.props.detail.data.assessment_scoring_reference._id;
      data.category.label = this.props.detail.data.category.name;
      data.category.value = this.props.detail.data.category._id;
      this.setState({ data });
    }
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };
  toggleMulti = () => {
    this.setState({
      multiSelect: !this.state.multiSelect,
    });
  };

  toggleRadio = (e) => {
    this.setState({ radio: e.target.name });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };
  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  toggleEditUser = (id, data) => {
    this.setState({
      editShow: !this.state.editShow,
      id: id,
      detail: data,
    });
  };
  handleDeleteproblems = (i) => {
    const { problems } = this.state;
    this.setState({
      problems: problems.filter((tag, index) => index !== i),
    });
  };

  handleAdditionproblems = (tag) => {
    this.setState((state) => ({ problems: [...state.problems, tag] }));
  };
  deletedQuestion = (id2) => {
    const id1 = this.props.location.state && this.props.location.state.data._id;
    this.props.deletedQuestionAssessment(id1, id2, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(
        this.props.location.state && this.props.location.state.data._id
      );
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  doSubmit = () => {
    const { title, category, header_text, footer_text, scoring } =
      this.state.data;
    const id = this.props.location.state && this.props.location.state.data._id;
    var formdata;
    if (scoring) {
      formdata = {
        title: title,
        footer_text: footer_text,
        header_text: header_text,
        category: category.value,
        assessment_scoring_reference: scoring.value,
      };
    } else {
      formdata = {
        title: title,
        footer_text: footer_text,
        header_text: header_text,
        category: category.value,
      };
    }

    this.props.updateAssessment(id, formdata, this.editCallback);
  };

  editCallback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(
        this.props.location.state && this.props.location.state.data._id
      );
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#6119c0" : "#bdbdbd",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#6119c0",
      },
      "&:focus": {
        borderColor: "#6119c0",
      },
    }),
  };
  render() {
    const { assessmentcategory, detail, scoring } = this.props;
    const ques =
      detail.questions &&
      detail.questions.map((e) =>
        _.chain(e.questions)
          .groupBy("section.name")
          .map((value, key) => ({
            section: key,
            question_group: _.chain(value)
              .groupBy("question_group.name")
              .map((value, key) => ({ question_group: key, question: value }))
              .value(),
          }))
          .value()
      );
    const components = {
      DropdownIndicator: null,
    };
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader enabled aside-enabled aside-fixed aside-secondary-enabled aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Assessments" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div
                  className="subheader py-3 py-lg-8 subheader-transparent"
                  id="kt_subheader"
                >
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center mr-1">
                      <div className="d-flex align-items-baseline flex-wrap mr-5">
                        <h2 className="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">
                          Assessment
                        </h2>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void (0)"
                        className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleAddUser();
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add Question
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div
                      className="card card-custom card-sticky"
                      id="kt_page_sticky_card"
                    >
                      <div
                        className="card-header"
                        style={{
                          "z-index": "90",
                          top: "-1px",
                          left: "125px",
                          right: "25px",
                        }}
                      >
                        <div className="card-title">
                          <h3 className="card-label">
                            Assessment Form
                            <i className="mr-2"></i>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <form action="" onSubmit={this.handleSubmit}>
                          <div
                            className="form-group row"
                            style={{ marginBottom: "0.75rem" }}
                          >
                            <div className="col-lg-6">
                              {this.renderInput("title", "Form Title")}
                            </div>
                            <div className="col-lg-6">
                              <label>Category</label>
                              <Select
                                key={this.state.data.category.length}
                                onChange={(e) => {
                                  const data = { ...this.state.data };
                                  if (e) {
                                    data.category = e;
                                    this.setState({ data });
                                  }
                                }}
                                placeholder=""
                                styles={this.customStyles}
                                className="form-groupxx"
                                closeMenuOnSelect={true}
                                components={this.animatedComponents}
                                options={assessmentcategory.map((e) => ({
                                  label: e.name,
                                  value: e._id,
                                }))}
                                value={this.state.data.category}
                              />
                              {this.state.errors.category && (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-validator="notEmpty"
                                    className="fv-help-block"
                                  >
                                    {this.state.errors.category}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="form-group row"
                            style={{ marginBottom: "0.75rem" }}
                          >
                            <div className="col-lg-6">
                              {this.renderInput("header_text", "Header Text")}
                            </div>
                            <div className="col-lg-6">
                              {this.renderInput("footer_text", "Footer Text")}
                            </div>
                          </div>
                          <div
                            className="form-group row"
                            style={{ marginBottom: "0.75rem" }}
                          >
                            <div className="col-lg-6">
                              <label>Scoring Reference</label>
                              <Select
                                key={this.state.data.scoring.length}
                                onChange={(e) => {
                                  const data = { ...this.state.data };
                                  if (e) {
                                    data.scoring = e;
                                    this.setState({ data });
                                  }
                                }}
                                placeholder=""
                                styles={this.customStyles}
                                className="form-groupxx"
                                closeMenuOnSelect={true}
                                components={this.animatedComponents}
                                options={scoring.map((e) => ({
                                  label: e.code,
                                  value: e._id,
                                }))}
                                value={this.state.data.scoring}
                              />
                            </div>

                            <div className="mt-8 col-lg-6">
                              <button
                                type="submit"
                                className="btn btn-primary ml-2 font-weight-bolder"
                                style={{ float: "right" }}
                              >
                                Save
                              </button>
                              <Link
                                to={"/assessments"}
                                className="btn btn-light-primary font-weight-bolder mr-2"
                                style={{ float: "right" }}
                              >
                                <i className="ki ki-long-arrow-back icon-sm"></i>
                                Back
                              </Link>
                            </div>
                          </div>
                          <hr />
                        </form>
                        {ques &&
                          ques.map((e, i) =>
                            e.map((e1) => (
                              <div className="form-group row">
                                <div class="form-group">
                                  <div className="row">
                                    <div className="col-lg-12 ">
                                      <h3 style={{ color: "#6119c0" }}>
                                        {e1.section != "undefined" &&
                                          e1.section}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                                {e1.question_group.map((e2) => (
                                  <>
                                    <div className="col-lg-12 mt-3">
                                      <h5 style={{ color: "#6119c0" }}>
                                        {e2.question_group !== "undefined" &&
                                          e2.question_group}
                                      </h5>
                                    </div>
                                    {e2.question.map((e3) => (
                                      <div
                                        className="col-lg-12"
                                        key={e3.display_order}
                                      >
                                        <label>{e3.question}</label>
                                        <span>
                                          <a
                                            href="javascript:void (0)"
                                            onClick={() =>
                                              this.toggleEditUser(e3._id, e3)
                                            }
                                            className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2 ml-20 mb-5 mt-5"
                                            title="Edit details"
                                          >
                                            <span className="svg-icon svg-icon-md">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                              >
                                                <g
                                                  stroke="none"
                                                  strokeWidth="1"
                                                  fill="none"
                                                  fillRule="evenodd"
                                                >
                                                  <rect
                                                    x="0"
                                                    y="0"
                                                    width="24"
                                                    height="24"
                                                  ></rect>
                                                  <path
                                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                    fill="#000000"
                                                    fillRule="nonzero"
                                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                                  ></path>
                                                  <path
                                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                    fill="#000000"
                                                    fillRule="nonzero"
                                                    opacity="0.3"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </span>
                                          </a>
                                          <a
                                            href="javascript:void (0)"
                                            onClick={() => this.toggle(e3._id)}
                                            className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mb-5 mt-5"
                                            title="Delete"
                                          >
                                            <span className="svg-icon svg-icon-md">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                              >
                                                <g
                                                  stroke="none"
                                                  strokeWidth="1"
                                                  fill="none"
                                                  fillRule="evenodd"
                                                >
                                                  <rect
                                                    x="0"
                                                    y="0"
                                                    width="24"
                                                    height="24"
                                                  ></rect>
                                                  <path
                                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                    fill="#000000"
                                                    fillRule="nonzero"
                                                  ></path>
                                                  <path
                                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                    fill="#000000"
                                                    opacity="0.3"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </span>
                                          </a>
                                        </span>
                                        {e3.response_type === "text" && (
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                          />
                                        )}
                                        {e3.response_type === "text_area" && (
                                          <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                          ></textarea>
                                        )}
                                        {e3.response_type ===
                                          "free_text_pill_box" && (
                                          <CreatableSelect
                                            components={components}
                                            noOptionsMessage={() =>
                                              "Type something and press enter..."
                                            }
                                            isMulti
                                            classNamePrefix="select"
                                            placeholder="Type something and press enter..."
                                            isDisabled={false}
                                            isLoading={false}
                                            name="secondary_problem"
                                            styles={this.customStyles}
                                            value={this.state.problems}
                                          />
                                          // <ReactTags
                                          //   classNames={{
                                          //     tagInput:
                                          //       "form-group custom-input",
                                          //     tagInputField: "form-control",
                                          //   }}
                                          //   inline
                                          //   placeholder="e.g. disturbed sleep, negative thoughts... "
                                          //   inputFieldPosition="inline"
                                          //   tags={this.state.problems}
                                          //   suggestions={[
                                          //     { id: "Hello", text: "Hello" },
                                          //   ]}
                                          //   handleDelete={
                                          //     this.handleDeleteproblems
                                          //   }
                                          //   handleAddition={
                                          //     this.handleAdditionproblems
                                          //   }
                                          //   delimiters={delimiters}
                                          // />
                                        )}
                                        <div class="form-group">
                                          <div class="checkbox-list">
                                            {e3.response_type ===
                                              "dropdown" && (
                                              <select class="form-control">
                                                <option
                                                  value=""
                                                  disabled
                                                  selected
                                                >
                                                  Please Select
                                                </option>

                                                {e3.options.map((e3, i2) => (
                                                  <option key={i2}>
                                                    {e3.title}{" "}
                                                  </option>
                                                ))}
                                              </select>
                                            )}
                                          </div>
                                        </div>
                                        {e3.response_type === "doc" &&
                                          e3.options.length > 0 && (
                                            <>
                                              {e3.options[0].title
                                                .split(".")
                                                .pop() === "pdf" ||
                                              e3.options[0].title
                                                .split(".")
                                                .pop() === "PDF" ? (
                                                <>
                                                  <embed
                                                    src={
                                                      process.env
                                                        .REACT_APP_S3URL +
                                                      e3.options[0].title
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_S3URL +
                                                    e3.options[0].title
                                                  }
                                                  alt=""
                                                />
                                              )}
                                            </>
                                          )}
                                        {e3.response_type === "checkbox" &&
                                          e3.options.map((e3, i2) => (
                                            <div class="form-group">
                                              <div class="checkbox-list">
                                                <label
                                                  class="checkbox"
                                                  key={i2}
                                                  htmlFor={e3._id}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={e3._id}
                                                    name={e3.title}
                                                  />
                                                  <span></span>
                                                  {e3.title}{" "}
                                                  <div>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                  </div>
                                                  {/* {e3.score && (
                                                    <div>{e3.score} %</div>
                                                  )} */}
                                                </label>
                                              </div>
                                            </div>
                                          ))}
                                        {e3.response_type ===
                                          "multiselect_dropdown" && (
                                          <Select
                                            key={e3.options.length}
                                            isMulti
                                            placeholder=""
                                            styles={this.customStyles}
                                            className="form-groupxx"
                                            closeMenuOnSelect={false}
                                            components={this.animatedComponents}
                                            options={e3.options.map(
                                              (e3, i2) => ({
                                                label: e3.title,
                                                value: i2,
                                              })
                                            )}
                                          />
                                        )}
                                        {e3.response_type === "radio" &&
                                          e3.options.map((e3, i2) => (
                                            <div class="form-group" key={i2}>
                                              <div class="radio-list">
                                                <label class="radio" key={i2}>
                                                  <input
                                                    type="radio"
                                                    onChange={(e) =>
                                                      this.toggleRadio(e)
                                                    }
                                                    checked={
                                                      this.state.radio ===
                                                      e3.title
                                                    }
                                                    name={e3.title}
                                                  />
                                                  <span></span>
                                                  {e3.title}{" "}
                                                  <div>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                  </div>
                                                  {/* {e3.score && (
                                                    <div>{e3.score} %</div>
                                                  )} */}
                                                </label>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    ))}
                                  </>
                                ))}
                              </div>
                            ))
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="subheader py-3 py-lg-8 subheader-transparent"
                  id="kt_subheader"
                >
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center mr-1">
                      <div className="d-flex align-items-baseline flex-wrap mr-5"></div>
                    </div>
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void (0)"
                        className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleAddUser();
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add Question
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddQuestion
            data={this.state.detail}
            editShow={this.state.editShow}
            edit_id={this.state.id}
            isShow={this.state.isShow}
            toggleEditUser={this.toggleEditUser}
            toggleAddUser={this.toggleAddUser}
            id={this.props.location.state && this.props.location.state.data._id}
          />
          <ModalPopup
            show={this.state.modal}
            onHide={this.toggle}
            delete_id={this.state.delete_id}
            deleted={this.deletedQuestion}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  assessmentcategory: getAssessmentCategory(state).assessmentcategory,
  detail: getAssessment(state).detail,
  scoring: getScoring(state).scoring,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAssessmentcategory: () => dispatch(loadAllAssessmentcategory()),
  loadDetailAssessment: (id) => dispatch(loadDetailAssessment(id)),
  loadAllScoring: () => dispatch(loadAllScoring()),
  deletedQuestionAssessment: (id, id2, callback) =>
    dispatch(deletedQuestionAssessment(id, id2, callback)),
  updateAssessment: (id, data, editCallback) =>
    dispatch(updateAssessment(id, data, editCallback)),
  addAssessmentcategory: (param, callback) =>
    dispatch(addAssessmentcategory(param, callback)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentForm)
);
