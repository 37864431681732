import React from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { loadAllMessage, updateMessage, getMessage } from "../../../store/message";
import {
    KeyRequired,
    informationSaved,
    failed,
    messageRequired
} from "../../common/misc";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";

const initialState = {
    data: {
        key: "",
        message: "",
        notes: "",
    },
    errors: {
        key: "",
        message: "",
        notes: "",
    },
}
class EditMessage extends Form {
    state = initialState;
    schema = {
        key: Joi.string()
            .required()
            .label("Key")
            .error(() => {
                return { message: KeyRequired };
            }),
        message: Joi.string()
            .required()
            .label("Message")
            .error(() => {
                return { message: messageRequired };
            }),

        notes: Joi.string()
            .label("Notes")
    };
    doSubmit = () => {
        const { key, message, notes } = this.state.data;
        const id = this.props.id;
        var formData = {
            key: key,
            message: message,
            notes: notes,
        }
        this.props.updateMessage(id, formData, this.callback);
    };

    callback = (res) => {
        if (res && res.status === 200) {
            this.props.loadAllMessage({ page: this.props.initialPage });
            this.props.toggleEditUser();
            toast(<AlertSuccess message={informationSaved} />)
        }
        else {
            toast(<AlertError message={failed} />)
        }
    };

    componentDidMount = () => {
        this.setState({
            data: {
                key: this.props.data.key,
                message: this.props.data.message,
                notes: this.props.data.notes,

            },
        });
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.id !== prevProps.id
        ) {
            this.setState({
                data: {
                    key: this.props.data && this.props.data.key,
                    message: this.props.data && this.props.data.message,
                    notes: this.props.data && this.props.data.notes,
                },
            });
        }
    }
    render() {
        return (
            <>
                <div
                    className={
                        this.props.editShow === true
                            ? "offcanvas offcanvas-left p-10 offcanvas-on"
                            : "offcanvas offcanvas-left p-10"
                    }
                >
                    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                        <h3 className="font-weight-bold m-0">Edit Message</h3>
                        <a
                            href="javascript:void(0)"
                            className="btn btn-xs btn-icon btn-light btn-hover-primary"
                            onClick={this.props.toggleEditUser}
                        >
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                    <hr />
                    <form action="" onSubmit={this.handleSubmit}>
                        {this.renderInput("key", "Key")}
                        {this.renderInput("message", "Message")}
                        {this.renderInput("notes", "Note")}
                        <hr />
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                            <div className="my-3 mr-2">
                                <span className="text-muted mr-2"></span>
                                <a href="#/" className="font-weight-bold"></a>
                            </div>
                            <button type="submit" className="btn btn-primary font-weight-bolder" >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
                {this.props.editShow === true ? (
                    <div className="offcanvas-overlay"></div>
                ) : (
                    ""
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    message: getMessage(state).message,
});
const mapDispatchToProps = (dispatch) => ({
    loadAllMessage: (params) => dispatch(loadAllMessage(params)),
    updateMessage: (id, data, callback) => dispatch(updateMessage(id, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMessage);
