import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "site",
  initialState: {
    site: {},
    update: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    siteRequested: (site, action) => {
      site.loading = true;
    },

    siteReceived: (site, action) => {
      site.site = action.payload;
      site.loading = false;
      site.lastFetch = Date.now();
    },

    siteRequestFailed: (site, action) => {
      site.loading = false;
    },
     updatedSite: (site, action) => {
      site.update = action.payload;
      site.loading = false;
      site.lastFetch = Date.now();
    },

    updatedSiteRequestFailed: (site, action) => {
      site.loading = false;
    },
  },
});

export const {
  siteRequested,
  siteReceived,
  siteRequestFailed,  
  updatedSite,
  updatedSiteRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "constant";


export const loadAllSite = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: siteRequested.type,
      onSuccess: siteReceived.type,
      onError: siteRequestFailed.type,
    })
  );
};
export const updateSite = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedSite.type,
      onError: updatedSiteRequestFailed.type,
    })
  );
};
export const getSite = createSelector(
  (state) => state.entities.site,
  (site) => site
);
