import React, { Component } from "react";
import Moment from 'moment';

class ClinicDetails extends Component {

    render() {
        const { clinic_details } = this.props.data;
        return (
            <>
                <div className={this.props.clinic === true ? "offcanvas offcanvas-left p-10 offcanvas-on" : "offcanvas offcanvas-left p-10"} style={{ "height": "100%", "overflow-y": "auto" }}>
                    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                        <h3 className="font-weight-bold m-0">Clinic Details</h3>
                        <a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleClinic}>
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Name: </a>
                        <span className="text-muted font-weight-bold education-margin">{clinic_details && clinic_details.name}</span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Clinic Address: </a>
                        <span className="text-muted font-weight-bold education-margin">{clinic_details && clinic_details.address}</span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Phone Number: </a>
                        <span className="text-muted font-weight-bold education-margin">{clinic_details && clinic_details.phone}</span>
                    </div>

                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">State: </a>
                        <span className="text-muted font-weight-bold education-margin">{clinic_details && clinic_details.state}</span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">City: </a>
                        <span className="text-muted font-weight-bold education-margin">{clinic_details && clinic_details.city}</span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Zipcode: </a>
                        <span className="text-muted font-weight-bold education-margin">{clinic_details && clinic_details.zipcode}</span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Clinic image: </a>
                        <span className="text-muted font-weight-bold education-margin">
                            {clinic_details && clinic_details.photos && clinic_details.photos.map((e, i) => (
                                <img className="imageClinic" width="180px" src={process.env.REACT_APP_S3URL + e} alt="" />))}
                        </span>
                    </div>


                </div>
                {this.props.clinic === true ? <div className="offcanvas-overlay" onClick={this.props.toggleClinic}></div> : ""}
            </>
        )
    }
}

export default ClinicDetails;