import React from "react";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import logo from "../../include/media/logos/logo-3.png";
import bg from "../../include/media/bg/bg-15.png";

import mindy_branding_1 from "../../include/media/mindy_branding_1.svg";
import mindy_branding_2 from "../../include/media/mindy_branding_2.svg";

import Form from "../common/form/form";
import { adminSignIn } from "../../store/admin";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";

import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  emailRequired,
  accountNotExist,
} from "../common/misc";

class SignIn extends Form {
  state = {
    data: {
      email: "",
      password: "",
    },
    errors: {
      email: "",
      password: "",
    },
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
            default:
          }
        });
        return errors;
      }),
    email: Joi.string()
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
  };

  doSubmit = () => {
    const { data } = this.state;
    this.props.adminSignIn(data, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 400)
      return toast(<AlertError message={accountNotExist} />);
    if (res && res.status === 200) {
      localStorage.setItem("x-auth-token", res.data.xAuthToken);
      localStorage.setItem("admin_details", JSON.stringify(res.data.data));
      window.location.href = "/administrators";
    }
  };
  render() {
    return (
      <div
        className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        <div className="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
          <img src={mindy_branding_2} className="branding-2-shape" alt="" />
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <div className="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
              <Link tp={"#"} className="mb-15 text-center">
                <img src={logo} className="max-h-75px" alt="" />
              </Link>
              <div className="login-form login-signin">
                <div className="text-center mb-10 mb-lg-20">
                  <h2 className="font-weight-bold">Sign In</h2>
                  <p className="text-muted font-weight-bold">
                    Enter your username and password
                  </p>
                </div>
                <form action="" onSubmit={this.handleSubmit}>
                  {this.renderInput("email", "Email")}
                  {this.renderPasswordInput("password", "Password")}
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-3">
                    <div className="checkbox-inline"></div>
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                    <div className="my-3 mr-2">
                      <span className="text-muted mr-2"></span>
                      <Link to={"#"} className="font-weight-bold"></Link>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary font-weight-bolder"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7"
          style={{ backgroundImage: `url(${bg})`, relative: "relative" }}
        >
          <img src={mindy_branding_1} className="branding-1-shape" alt="" />
          <div className="d-flex flex-column-fluid flex-lg-center">
            <div className="d-flex flex-column mr-auto login-right-content">
              <h3 className="display-3 font-weight-bold my-7 text-white">
                {" "}
                Welcome to <br /> Yes Mindy Admin Panel!
              </h3>
              <p className="font-weight-bold font-size-lg text-white opacity-80 signin">
                We are committed to build a mental health ecosystem to make
                holistic services accessible to all; that utilizes an innovative
                virtual assistant platform to support patients & their aligned
                Mental Health Professionals identify, assess, deliver & track
                treatment throughout the journey of wellness.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  adminSignIn: (param, callback) => dispatch(adminSignIn(param, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(SignIn));
