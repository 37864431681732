import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "admin",
  initialState: {
    admin: {
      xAuthToken: localStorage.getItem("x-auth-token")?localStorage.getItem("x-auth-token"):null,
      admin_details: localStorage.getItem("admin_details")?JSON.parse(localStorage.getItem("admin_details")):null,
    },
    isLoggedIn: (localStorage.getItem("x-auth-token") && localStorage.getItem("admin_details"))? true:false,
    loading: false,
    lastFetch: null,
  },
  reducers: {
    adminRequested: (admin, action) => {
      admin.loading = true;
    },
    adminReceived: (admin, action) => {
      admin.admin.xAuthToken = action.payload.xAuthToken;
      admin.admin.admin_details = action.payload.data;
      admin.isLoggedIn= true;
      admin.loading = false;
      admin.lastFetch = Date.now();
    },
    adminRequestFailed: (admin, action) => {
      admin.loading = false;
    },
  },
});

export const {
  adminRequested,
  adminReceived,
  adminRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "admin/signIn";


export const adminSignIn = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      method: "POST",
      data,
      onSuccess: adminReceived.type,
      callback,
    })
  );
};



export const getAdmin = createSelector(
  (state) => state.entities.admin,
  (admin) => admin
);
