import { Modal } from 'react-bootstrap'
import closeBtn from '../../include/media/close.svg'
import moment from 'moment'

const CommentsView = props => {
  const data =
    (props.currentData &&
      props?.currentData.comments &&
      props?.currentData.comments) ||
    []
  console.log('comments', data)
  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <div className='modal-header'>
        <h3></h3>
        <button
          type='button'
          className='close mt-2'
          data-dismiss='modal'
          onClick={() => {
            props.onHide()
          }}>
          <img src={closeBtn} alt='close popup' />
        </button>
      </div>
      <div
        className='modal-body mt-5'
        style={{ maxHeight: 600, overflow: 'auto' }}>
        {data &&
          data.map(ele => (
            <div
              style={{
                border: '1px solid #ebedf3',
                borderRadius: 10,
                padding: '5px 10px',
              }}
              className='mb-5'>
              <div className='d-flex'>
                <div className='mr-3'>
                  <span className='symbol symbol-30 symbol-lg-40'>
                    <span className='svg-icon svg-icon-xl'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                        version='1.1'>
                        <g
                          stroke='none'
                          strokeWidth='1'
                          fill='none'
                          fillRule='evenodd'>
                          <polygon points='0 0 24 0 24 24 0 24' />
                          <path
                            d='M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z'
                            fill='#000000'
                            fillRule='nonzero'
                            opacity='0.3'
                          />
                          <path
                            d='M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z'
                            fill='#000000'
                            fillRule='nonzero'
                          />
                        </g>
                      </svg>
                    </span>
                  </span>
                </div>
                <div>
                  <div className='font-weight-bold font-size-h5 text-dark-75'>
                    {ele?.author?.first_name + ' ' + ele?.author?.last_name}
                  </div>
                  <div className='font-weight-bolder font-size-lg mb-0'>
                    {moment(ele.created_at).format('DD-MM-YYYY')}
                  </div>
                </div>
              </div>
              <div className='font-weight-bolder font-size-lg mb-0 mt-5'>
                {ele.text}
              </div>
            </div>
          ))}
      </div>
    </Modal>
  )
}

export default CommentsView
