import React, { Component } from "react";
import Moment from 'moment';

class Documentation extends Component {
 
	render() {
		const {documents_url} = this.props.data;
		return (
			<>
				<div className={this.props.documentation===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Documentations</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleDocumentation}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
					</div>
					<hr/>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Graduation certificate: </a>
						{documents_url && documents_url.graduation ? <a className="text-muted font-weight-bold education-margin" href={process.env.REACT_APP_S3URL + documents_url.graduation} target="_blank" download="">Click here to view image</a>:<span className="text-muted font-weight-bold education-margin"></span>}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 	text-hover-primary font-size-lg mb-1">Post Graduation certificate: </a>
						{documents_url && documents_url.post_graduation ? <a className="text-muted font-weight-bold education-margin" href={process.env.REACT_APP_S3URL + documents_url.post_graduation} target="_blank" download="">Click here to view image</a>:<span className="text-muted font-weight-bold education-margin"></span>}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Registration/License certificate: </a>
						{documents_url && documents_url.registration_license ? <a className="text-muted font-weight-bold education-margin" href={process.env.REACT_APP_S3URL + documents_url.registration_license.doc_url} 
						target="_blank" download="">Click here to view image</a>:<span className="text-muted font-weight-bold education-margin"></span>}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">License Number: </a>
						<span className="text-muted font-weight-bold education-margin">{documents_url?documents_url.registration_license && documents_url.registration_license.licence_no:""}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Super Specialty degree: </a>
						{documents_url && documents_url.super_specialty_degree ? <a className="text-muted font-weight-bold education-margin" href={process.env.REACT_APP_S3URL + documents_url.super_specialty_degree} 
						target="_blank" download="">Click here to view image</a>:<span className="text-muted font-weight-bold education-margin"></span>}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Experience certificate: </a>
						{documents_url &&  documents_url.experience_certificate ? <a className="text-muted font-weight-bold education-margin" href={process.env.REACT_APP_S3URL + documents_url.experience_certificate} target="_blank" download="">Click here to view image</a>:<span className="text-muted font-weight-bold education-margin"></span>}
					</div>
				</div>
				{this.props.documentation=== true?<div className="offcanvas-overlay" onClick={this.props.toggleDocumentation}></div>:""}
			</>
		)
	}
}

export default Documentation;