import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import user from "../../include/media/default-profile.png";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import HelmetComponent from "../common/helmetComponent";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import {
  getBlog,
  loadAllBlog,
  deletedBlog,
  updateBlog,
} from "../../store/blog";
import AlertSuccess from "../common/alertSuccess";
import {
  deleted,
  published,
  unpublished,
  failed,
  informationSaved,
} from "../common/misc";
import BlogGrid from "./blogGrid";
import Search from "./search";
import SortingIcon from "../common/sortingIcon";
import SortingIconUp from "../common/sortingIconUp";

class BlogTable extends Component {
  state = {
    userShow: false,
    modal: false,
    active: false,
    is_top: false,
    modalTop: false,
    id: "",
    blog_id: "",
    status: "",
    msg: "",
    isShow: false,
    data: "",
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    delete_id: "",
    asside: false,
    asside2: false,
    asside3: false,
    title: "",
    author: "",
    category: "",
    sort_by: "title",
    sort: "desc",
  };
  onSearch = (e) => {
    e.preventDefault();
    if (this.state.title) {
      this.props.loadAllBlog({
        title: this.state.title,
        page: this.state.currentPage,
      });
    } else if (this.state.author) {
      this.props.loadAllBlog({
        author: this.state.author,
        page: this.state.currentPage,
      });
    } else if (this.state.category) {
      this.props.loadAllBlog({
        category: this.state.category,
        page: this.state.currentPage,
      });
    }
  };
  changeTitle = (title) => {
    this.setState({ title: title });
  };
  changeAuthor = (author) => {
    this.setState({ author: author });
  };
  changeCategory = (category) => {
    this.setState({ category: category });
  };
  reset = () => {
    this.props.loadAllBlog({ page: this.props.currentPage });
    this.setState({
      title: "",
      author: "",
      category: "",
    });
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };
  toggleAcitve = (id, status, msg) => {
    this.setState({
      active: !this.state.active,
      blog_id: id,
      status: status,
      msg: msg,
    });
  };

  toggleTop = (id, is_top, msg) => {
    this.setState({
      modalTop: !this.state.modalTop,
      is_top,
      blog_id: id,
      msg: msg,
    });
  };

  toggleEditUser = (data, id) => {
    this.setState({
      isShow: !this.state.isShow,
      data: data,
      id: id,
    });
  };
  deletedBlog = (id) => {
    this.props.deletedBlog(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllBlog({ page: this.state.currentPage });
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  updateBlog = (id) => {
    const formData = {
      published: this.state.status,
    };
    this.props.updateBlog(id, formData, this.callbackActive);
  };
  updateTop = (id) => {
    const formData = {
      is_top: this.state.is_top,
    };
    this.props.updateBlog(id, formData, this.callbackTop);
  };
  callbackTop = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllBlog({ page: this.state.currentPage });
      this.setState({
        modalTop: false,
      });
      if (this.state.status === true) {
        toast(<AlertSuccess message={informationSaved} />);
      } else {
        toast(<AlertSuccess message={informationSaved} />);
      }
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  callbackActive = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllBlog({ page: this.state.currentPage });
      this.setState({
        active: false,
        modalTop: false,
      });
      if (this.state.status === true) {
        toast(<AlertSuccess message={published} />);
      } else {
        toast(<AlertSuccess message={unpublished} />);
      }
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadAllBlog({ page: this.state.currentPage });
    }
  };
  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page != this.state.currentPage && this.state.title) {
      this.props.loadAllBlog({ page: data.page, title: this.state.title });
    } else if (data.page != this.state.currentPage && this.state.category) {
      this.props.loadAllBlog({
        page: data.page,
        category: this.state.category,
      });
    } else if (data.page != this.state.currentPage && this.state.author) {
      this.props.loadAllBlog({ page: data.page, author: this.state.author });
    } else if (data.page != this.state.currentPage) {
      this.props.loadAllBlog({ page: data.page });
    }
  };

  sortByTitle = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "title",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "title",
        sort: "desc",
      });
    }
  };
  sortByCategory = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "category",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "category",
        sort: "desc",
      });
    }
  };
  sortByPublishedOn = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "publish_date",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "publish_date",
        sort: "desc",
      });
    }
  };
  sortByCreatedOn = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "created_on",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "created_on",
        sort: "desc",
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadAllBlog({
        page: this.state.currentPage,
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
  };

  render() {
    const { blog } = this.props;
    const {
      record_per_page,
      total_record_on_current_page,
      total_record,
      total_page,
      current_page,
      filtered_record,
    } = this.props.pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Blog Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Blogs
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          {filtered_record > 0
                            ? "Total :" +
                              " " +
                              filtered_record +
                              " " +
                              "records"
                            : "Total : 0 record"}
                        </span>
                        <Search
                          currentPage={current_page}
                          onSearch={this.onSearch}
                          title={this.state.title}
                          reset={this.reset}
                          author={this.state.author}
                          category={this.state.category}
                          changeAuthor={this.changeAuthor}
                          changeCategory={this.changeCategory}
                          changeTitle={this.changeTitle}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <Link
                        to={"/add-new-blog"}
                        className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add Blog
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Blogs Management
                            <span className="d-block text-muted pt-2 font-size-sm">
                              You can view/add/edit/delete blogs.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "145px" }}>
                                    Title
                                    {this.state.sort_by === "title" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={this.sortByTitle} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByTitle}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "145px" }}>
                                    Category
                                    {this.state.sort_by === "category" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCategory}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCategory}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "145px" }}>Author</span>
                                </th>

                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "100px" }}>
                                    Published
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "100px" }}>
                                    Top Article
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "145px" }}>
                                    Published Date
                                    {this.state.sort_by === "publish_date" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByPublishedOn}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByPublishedOn}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "120px" }}>
                                    Created On
                                    {this.state.sort_by === "created_on" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCreatedOn}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreatedOn}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "145px" }}>Action</span>
                                </th>
                              </tr>
                            </thead>
                            <BlogGrid
                              initialPage={this.state.current_page}
                              delete_id={this.state.delete_id}
                              isShow={this.state.isShow}
                              toggleEditUser={this.toggleEditUser}
                              data={this.state.data}
                              msg={this.state.msg}
                              status_id={this.state.blog_id}
                              modalActive={this.state.active}
                              toggleAcitve={this.toggleAcitve}
                              modalTop={this.state.modalTop}
                              updateTop={this.updateTop}
                              Update={this.updateBlog}
                              modal={this.state.modal}
                              toggle={this.toggle}
                              toggleTop={this.toggleTop}
                              id={this.state.id}
                              blog={blog}
                              deleted={this.deletedBlog}
                            />
                          </table>
                          <Pagination
                            totalRecords={filtered_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  blog: getBlog(state).blog,
  pagination: getBlog(state).pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllBlog: (params) => dispatch(loadAllBlog(params)),
  deletedBlog: (id, callback) => dispatch(deletedBlog(id, callback)),
  updateBlog: (id, data, callbackActive) =>
    dispatch(updateBlog(id, data, callbackActive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogTable);
