import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import HelmetComponent from "../common/helmetComponent";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import "tinymce/plugins/image";

import seUploadFun from "../services/s3Services";
import Joi from "joi-browser";
import Form from "../common/form/form";
import { getBlog, loadAllBlog, updateBlog } from "../../store/blog";
import { informationSaved, failed } from "../common/misc";
import ReactHtmlParse from "react-html-parser";
import _ from "lodash";
import { WithContext as ReactTags } from "react-tag-input";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getBlogCategory, loadAllBlogcategory } from "../../store/blogCategory";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

class BlogEdit extends Form {
  state = {
    tempImages: [],
    userShow: false,
    asside: false,
    publish: false,
    asside2: false,
    is_featured: false,
    is_top: false,
    photoStatus: "Add file",
    displayImage: "",
    asside3: false,
    keywords: [],
    Pages: [
      {
        id: "",
        name: "",
      },
      {
        id: "HOME",
        name: "HOME",
      },
      {
        id: "PSYCHIATRIST",
        name: "PSYCHIATRIST",
      },

      {
        id: "PSYCHOLOGIST",
        name: "PSYCHOLOGIST",
      },
      {
        id: "PSYCHOSOCIAL WORKER",
        name: "PSYCHOSOCIAL WORKER",
      },
      {
        id: "COUNSELLOR",
        name: "COUNSELLOR",
      },
      {
        id: "COMMUNITY",
        name: "COMMUNITY",
      },
    ],
    Types: [
      {
        id: "",
        name: "",
      },
      {
        id: "Media & Updates",
        name: "Media & Updates",
      },
      {
        id: "Awards and Achievements",
        name: "Awards & Achievements",
      },

      {
        id: "YesMindy Affiliations",
        name: "YesMindy Affiliations",
      },
      {
        id: "Social Outreach",
        name: "Social Outreach",
      },
      {
        id: "Online Engagements",
        name: "Online Engagements",
      },
      {
        id: "Mental Health Influencers",
        name: "Mental Health Influencers",
      },
    ],
    data: {
      title: "",
      author: "",
      photo: "",
      text: "",
      date: "",
      category: [],
      button_label: "",
      button_link: "",
      type_text: {},
      page: {},
    },
    errors: {},
  };
  schema = {
    date: Joi.date().label("Publish Date").allow(""),
    photo: Joi.string().label("Photo").allow(""),
    text: Joi.string().label("Blog").allow(""),
    title: Joi.string().label("Title").allow(""),
    category: Joi.array().items(Joi.object().allow("")).allow(""),
    author: Joi.string().label("Author").allow(""),
    button_label: Joi.string().label("Button Label").allow(""),
    button_link: Joi.string().label("Button Link").allow(""),
    type_text: Joi.object().label("Type").allow(""),
    page: Joi.object().label("Type").allow(""),
  };

  doSubmit = () => {
    const {
      date,
      author,
      type_text,
      title,
      category,
      photo,
      text,
      button_label,
      button_link,
      page,
    } = this.state.data;
    const { publish, keywords, is_featured, is_top } = this.state;
    const id = this.props.location.state.data._id;

    const formdata = {
      title: title,
      author: author,
      text: text,
      //category:  category.map((e) => e.value),
      featured_image_url: photo,
      published: publish ? true : false,
      publish_date: date,
      button_link: button_link,
      button_label: button_label,
      is_featured: is_featured ? true : false,
      is_top: is_top ? true : false,
      type_text: type_text.value,
      page: page.value,
      keywords: keywords.map((el) => el.text),
    };
    if (category && category.length > 0) {
      formdata.category = category.map((e) => e.value);
    } else {
      formdata.category = [];
    }
    const newData = _.pickBy(formdata, _.identity);
    this.props.updateBlog(id, formdata, this.callbackActive);
  };
  callbackActive = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllBlog({ page: this.props.initialPage });
      this.props.history.push("/blogs");
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  togglePublish = () => {
    this.setState({
      publish: !this.state.publish,
    });
  };
  toggleFeature = () => {
    this.setState({
      is_featured: !this.state.is_featured,
    });
  };

  toggleTop = () => {
    this.setState({
      is_top: !this.state.is_top,
    });
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
      asside: false,
    });
  };
  handleEditorChange = (content, editor) => {
    const data = { ...this.state.data };
    data.text = content;
    this.setState({ data });
  };
  animatedComponents = makeAnimated();
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#6119c0" : "#bdbdbd",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#6119c0",
      },
      "&:focus": {
        borderColor: "#6119c0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };
  fileUpload = (e) => {
    this.setState({ photoStatus: "Loading" });
    const fileName = "blog/photo/" + Date.now();
    const res = seUploadFun(e.target.files[0], fileName);
    res.then((result) => {
      const { data, errors } = this.state;
      data.photo = result.key;
      errors.photo = false;
      this.setState({
        photoStatus: "Uploaded successfully. Click here to change",
        data,
        displayImage: process.env.REACT_APP_S3URL + result.key,
      });
    });
  };
  fileUploadTemp = (e) => {
    console.log("target.files", e.target.files);

    for (let i = 0; i < e.target.files.length; i++) {
      const fileName = "blog/temp/photo/" + Date.now();
      console.log("target.files1", e.target.files[i]);
      const res = seUploadFun(e.target.files[i], fileName);
      res.then((result) => {
        const tempImages = [...this.state.tempImages];
        tempImages.push(process.env.REACT_APP_S3URL + result.key);
        this.setState({ tempImages });
      });
    }
  };
  componentDidMount = () => {
    const data = { ...this.state.data };
    data.title = this.props.location.state.data.title;
    data.author = this.props.location.state.data.author;
    data.text = this.props.location.state.data.text;
    data.photo = this.props.location.state.data.featured_image_url;
    data.button_link = this.props.location.state.data.button_link;
    data.button_label = this.props.location.state.data.button_label;
    data.type_text.label = this.props.location.state.data.type_text;
    data.page.label = this.props.location.state.data.page;
    data.category = this.props.location.state.data.category.map((e) => ({
      label: e.name,
      value: e._id,
    }));
    data.date = new Date(this.props.location.state.data.publish_date);

    this.setState({
      publish: this.props.location.state.data.published,
      is_featured: this.props.location.state.data.is_featured,
      is_top: this.props.location.state.data.is_top,
      keywords: this.props.location.state.data.keywords.map((el) => ({
        id: el,
        text: el,
      })),
    });
    this.setState({ data });
    this.props.loadAllBlogcategory();
  };
  handleDeletekeywords = (i) => {
    const { keywords } = this.state;
    this.setState({
      keywords: keywords.filter((tag, index) => index !== i),
    });
  };

  handleAdditionkeywords = (tag) => {
    this.setState((state) => ({ keywords: [...state.keywords, tag] }));
  };
  render() {
    const { blogcategory } = this.props;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Blogs" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
              asside3={this.state.asside3}
              toggleAsside3={this.toggleAsside3}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div
                  className="subheader py-3 py-lg-8 subheader-transparent"
                  id="kt_subheader"
                >
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center mr-1">
                      <div className="d-flex align-items-baseline flex-wrap mr-5">
                        <h2 className="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">
                          Edit Blog
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div
                      className="card card-custom card-sticky"
                      id="kt_page_sticky_card"
                    >
                      <div
                        className="card-header"
                        style={{
                          "z-index": "90",
                          top: "-1px",
                          left: "125px",
                          right: "25px",
                        }}
                      >
                        <div className="card-title">
                          <h3 className="card-label">
                            {ReactHtmlParse(this.state.data.title)}
                            <i className="mr-2"></i>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <form
                          action=""
                          onSubmit={this.handleSubmit}
                          autocomplete="off"
                          id="my-form"
                        >
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="my-5">
                                <div className="form-group row">
                                  <div className="col-lg-6">
                                    {this.renderInput("title", "Title")}
                                  </div>
                                  <div className="col-lg-3">
                                    <label>Category</label>
                                    <Select
                                      value={this.state.data.category}
                                      onChange={(e) => {
                                        const data = { ...this.state.data };
                                        if (e) {
                                          data.category = e;
                                          this.setState({ data });
                                        } else {
                                          data.category = [];
                                          this.setState({ data });
                                        }
                                      }}
                                      isMulti
                                      placeholder=""
                                      styles={this.customStyles}
                                      classNameName="form-groupxx"
                                      closeMenuOnSelect={false}
                                      components={this.animatedComponents}
                                      options={blogcategory.map((e) => ({
                                        label: e.name,
                                        value: e._id,
                                      }))}
                                    />
                                    {this.state.errors.category && (
                                      <div className="fv-plugins-message-container">
                                        <div
                                          data-validator="notEmpty"
                                          className="fv-help-block"
                                        >
                                          {this.state.errors.category}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-3">
                                    <label>Type</label>
                                    <Select
                                      key={this.state.data.type_text.length}
                                      onChange={(e) => {
                                        const data = { ...this.state.data };
                                        if (e) {
                                          data.type_text = e;
                                          this.setState({ data });
                                        }
                                      }}
                                      placeholder=""
                                      styles={this.customStyles}
                                      className="form-groupxx"
                                      closeMenuOnSelect={true}
                                      components={this.animatedComponents}
                                      options={this.state.Types.map((e) => ({
                                        label: e.name,
                                        value: e.id,
                                      }))}
                                      value={this.state.data.type_text}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-lg-6">
                                    {this.renderInput("author", "Author")}
                                  </div>
                                  <div className="col-lg-1">
                                    <label>Publish</label>
                                    <div className="checkbox-list">
                                      <label className="checkbox">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="agree"
                                          value=""
                                          checked={this.state.publish}
                                          onClick={this.togglePublish}
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-1">
                                    <label>Featured</label>
                                    <div className="checkbox-list">
                                      <label className="checkbox">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="agree"
                                          value={this.state.is_featured}
                                          checked={this.state.is_featured}
                                          onClick={this.toggleFeature}
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-1"></div>
                                  <div className="col-lg-3">
                                    {this.renderDate("date", "Publish Date")}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-lg-6">
                                    <div class="dc-block-head">
                                      <label>Keywords</label>
                                    </div>
                                    <ReactTags
                                      classNames={{
                                        tagInput: "form-group custom-input",
                                        tagInputField: "form-control",
                                      }}
                                      inline
                                      inputFieldPosition="inline"
                                      tags={this.state.keywords}
                                      suggestions={[
                                        { id: "Hello", text: "Hello" },
                                      ]}
                                      handleDelete={this.handleDeletekeywords}
                                      handleAddition={
                                        this.handleAdditionkeywords
                                      }
                                      delimiters={delimiters}
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    {this.renderInput(
                                      "button_label",
                                      "Button Label"
                                    )}
                                  </div>
                                  <div className="col-lg-3">
                                    {this.renderInput(
                                      "button_link",
                                      "Button Link"
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-lg-3">
                                    <label
                                      className={
                                        this.state.errors.photo
                                          ? "errorColor"
                                          : ""
                                      }
                                      htmlFor="photograph2"
                                    >
                                      Upload cover Photo
                                    </label>
                                    <input
                                      accept="image/*"
                                      type="file"
                                      id="photograph2"
                                      className="form-control "
                                      onChange={this.fileUpload}
                                      disabled={
                                        this.state.photoStatus === "Loading"
                                      }
                                    />
                                    {this.state.data.photo && (
                                      <a
                                        className="add-more-field"
                                        href={
                                          process.env.REACT_APP_S3URL +
                                          this.state.data.photo
                                        }
                                        style={{ fontSize: "11px" }}
                                        target="_blank"
                                        download
                                      >
                                        {process.env.REACT_APP_S3URL +
                                        this.state.data.photo
                                          ? "Click here to view your uploaded photo"
                                          : ""}
                                      </a>
                                    )}
                                    {this.state.errors.photo && (
                                      <div className="fv-plugins-message-container">
                                        <div
                                          data-validator="notEmpty"
                                          className="fv-help-block"
                                        >
                                          {this.state.errors.photo}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-3">
                                    <img
                                      className="img-fluid"
                                      src={
                                        process.env.REACT_APP_S3URL +
                                        this.state.data.photo
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className="col-lg-3"
                                    style={{ zIndex: 999999999999 }}
                                  >
                                    <label>Pages</label>
                                    <Select
                                      key={this.state.data.page.length}
                                      onChange={(e) => {
                                        const data = { ...this.state.data };
                                        if (e) {
                                          data.page = e;
                                          this.setState({ data });
                                        }
                                      }}
                                      placeholder=""
                                      styles={this.customStyles}
                                      className="form-groupxx"
                                      closeMenuOnSelect={true}
                                      components={this.animatedComponents}
                                      options={this.state.Pages.map((e) => ({
                                        label: e.name,
                                        value: e.id,
                                      }))}
                                      value={this.state.data.page}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    <label>Top Article</label>
                                    <div className="checkbox-list">
                                      <label className="checkbox">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="agree"
                                          value=""
                                          checked={this.state.is_top}
                                          onClick={this.toggleTop}
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>{" "}
                                <div className="form-group row">
                                  <div className="col-lg-3">
                                    <label
                                      className={
                                        this.state.errors.photo
                                          ? "errorColor"
                                          : ""
                                      }
                                      htmlFor="photograph2"
                                    >
                                      Upload Photo
                                    </label>
                                    <input
                                      multiple
                                      accept="image/*"
                                      type="file"
                                      id="photograph2"
                                      className="form-control "
                                      onChange={this.fileUploadTemp}
                                      disabled={
                                        this.state.photoStatus === "Loading"
                                      }
                                    />
                                    <ul>
                                      {this.state.tempImages.length > 0 &&
                                        this.state.tempImages.map((ep) => (
                                          <li>
                                            <img
                                              className="mb-2 mr-2"
                                              src={ep}
                                              height="50px"
                                            ></img>
                                            <a
                                              href=""
                                              title="click to copy"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                navigator.clipboard.writeText(
                                                  ep
                                                );
                                              }}
                                            >
                                              <i class="fas fa-copy"></i>
                                            </a>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>Blog</label>
                                  <Editor
                                    value={this.state.data.text}
                                    init={{
                                      branding: false,
                                      height: 300,
                                      menubar: false,
                                      plugins: [
                                        "advlist autolink lists link image charmap print preview anchor",
                                        "searchreplace visualblocks code fullscreen",
                                        "insertdatetime media table paste code help wordcount image code",
                                      ],
                                      toolbar:
                                        "undo redo | formatselect | bold italic backcolor | \
														 alignleft aligncenter alignright alignjustify | \
														 bullist numlist outdent indent | removeformat | help | link image | code",
                                    }}
                                    onEditorChange={this.handleEditorChange}
                                    file_picker_callback={(re) =>
                                      console.log(re)
                                    }
                                    file_picker_types="image"
                                  />
                                  {this.state.errors.text && (
                                    <div className="fv-plugins-message-container">
                                      <div
                                        data-validator="notEmpty"
                                        className="fv-help-block"
                                      >
                                        {this.state.errors.text}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2">
                            <button
                              type="submit"
                              className="btn btn-primary ml-2 font-weight-bolder"
                              style={{ float: "right" }}
                            >
                              Save
                            </button>
                            <Link
                              to={"/blogs"}
                              className="btn btn-light-primary font-weight-bolder mr-2"
                              style={{ float: "right" }}
                            >
                              <i className="ki ki-long-arrow-back icon-sm"></i>
                              Back
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  blog: getBlog(state).blog,
  blogcategory: getBlogCategory(state).blogcategory,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllBlog: (params) => dispatch(loadAllBlog(params)),
  updateBlog: (id, data, callbackActive) =>
    dispatch(updateBlog(id, data, callbackActive)),
  loadAllBlogcategory: () => dispatch(loadAllBlogcategory()),
});
export default connect(mapStateToProps, mapDispatchToProps)(BlogEdit);
