import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "expert",
  initialState: {
    expert: [],
    update: {},
    add: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    expertRequested: (expert, action) => {
      expert.loading = true;
    },

    expertReceived: (expert, action) => {
      expert.expert = action.payload.data;
      expert.loading = false;
      expert.lastFetch = Date.now();
    },

    expertRequestFailed: (expert, action) => {
      expert.loading = false;
    },
    updatedExpert: (expert, action) => {
      expert.update = action.payload;
      expert.loading = false;
      expert.lastFetch = Date.now();
    },

    updatedExpertRequestFailed: (expert, action) => {
      expert.loading = false;
    },
    expertAdded: (expert, action) => {
      expert.add = action.payload;
      expert.loading = false;
      expert.lastFetch = Date.now();
    },

    expertAddRequestFailed: (expert, action) => {
      expert.loading = false;
    },
  },
});

export const {
  expertRequested,
  expertReceived,
  expertRequestFailed,
  updatedExpert,
  updatedExpertRequestFailed,
  expertAdded,
  expertAddRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "patient/equests/list";
const url1 = "patient/request/approve/";
const url2 = "patient/doctor-remove-request";

export const loadAllExpert = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: expertRequested.type,
      onSuccess: expertReceived.type,
      onError: expertRequestFailed.type,
    })
  );
};
export const updateExpert = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url1 + id,
      method: "PUT",
      callback,
      onSuccess: updatedExpert.type,
      onError: updatedExpertRequestFailed.type,
    })
  );
};
export const addExpert = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url2,
      method: "post",
      data: param,
      callback,
      onSuccess: expertAdded.type,
      onError: expertAddRequestFailed.type,
    })
  );
};

export const getExpert = createSelector(
  (state) => state.entities.expert,
  (expert) => expert
);
