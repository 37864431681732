import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import Moment from 'moment';
import { getAssessmentCategory, loadAllAssessmentcategory, addAssessmentcategory } from "../../store/assessmentCategories";

import {

    categoryRequired,
    informationSaved,
    failed
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

const initialState = {
    data: {
        name: "",
    },
    can_be_deleted: false,
    errors: {
        name: "",
    },
}
class AddAssessmentCategory extends Form {
    state = initialState;
    schema = {
        name: Joi.string()
            .required()
            .label("Category Name")
            .error(() => {
                return { message: categoryRequired };
            }),
    };

    doSubmit = () => {
        const { name } = this.state.data;
        const { can_be_deleted } = this.state;
        var formdata = {
            can_be_deleted: can_be_deleted,
            name: name,
        }
        this.props.addAssessmentcategory(formdata, this.callback);
    };
    toggleMaster = () => {
        this.setState({
            can_be_deleted: !this.state.can_be_deleted,
        });
    };
    callback = (res) => {
        if (res && res.status === 200) {
            this.props.loadAllAssessmentcategory();

            this.props.toggleAddUser();
            this.setState({ can_be_deleted: false, data: { name: "" } });
            toast(<AlertSuccess message={informationSaved} />)
        }
        else {
            toast(<AlertError message={failed} />)
            this.setState({ can_be_deleted: false, data: { name: "" } });

        }
    };
    toggleClose = () => {
        this.setState({ can_be_deleted: false, data: { name: "" } });
        this.props.toggleAddUser();
    };
    render() {
        console.log(this.state);
        return (
            <>
                <div className={this.props.isShow === true ? "offcanvas offcanvas-left p-10 offcanvas-on" : "offcanvas offcanvas-left p-10"}>
                    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                        <h3 className="font-weight-bold m-0">Add Assessment Category</h3>
                        <a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.toggleClose}>
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                    <hr />
                    <form action="" onSubmit={this.handleSubmit}>
                        {this.renderInput("name", "Category Name")}
                        <div className="form-group">
                            <label>Can Be Deleted</label>
                            <div className="checkbox-list">
                                <label className="checkbox">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="agree"
                                        value=""
                                        checked={this.state.can_be_deleted === true ? true : false}
                                        onClick={this.toggleMaster} />
                                    <span></span></label>
                            </div>
                        </div>
                        <hr />
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                            <div className="my-3 mr-2">
                                <span className="text-muted mr-2"></span>
                                <a href="#" className="font-weight-bold"></a>
                            </div>
                            <button type="submit" className="btn btn-primary font-weight-bolder" >
                                Save
							</button>
                        </div>
                    </form>
                </div>
                {this.props.isShow === true ? <div className="offcanvas-overlay"></div> : ""}
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    assessmentcategory: getAssessmentCategory(state).assessmentcategory,
});
const mapDispatchToProps = (dispatch) => ({
    loadAllAssessmentcategory: () => dispatch(loadAllAssessmentcategory()),
    addAssessmentcategory: (param, callback) => dispatch(addAssessmentcategory(param, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddAssessmentCategory);