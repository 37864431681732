import React from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { loadAllFaq, addFaq, getFaq, deletedFaq } from "../../../store/faq";

import {
  question,
  informationSaved,
  failed,
  answer,
  category,
} from "../../common/misc";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const initialState = {
  data: {
    question: "",
    answer: "",
    category: "",
  },
  Categories: [
    {
      id: "Psychiatrist",
      name: "Psychiatrist",
    },
    {
      id: "Psychologist",
      name: "Psychologist",
    },

    {
      id: "Psychosocial",
      name: "Psychosocial",
    },
    {
      id: "Counsellor",
      name: "Counsellor",
    },
    {
      id: "Misc",
      name: "Misc",
    },
    {
      id: "Patient",
      name: "Patient",
    },
    {
      id: "Doctor",
      name: "Doctor",
    },
  ],
  errors: {},
};
class AddFaq extends Form {
  state = initialState;
  schema = {
    question: Joi.string()
      .required()
      .label("Question")
      .error(() => {
        return { message: question };
      }),
    answer: Joi.string()
      .required()
      .label("Answer")
      .error(() => {
        return { message: answer };
      }),

    category: Joi.string()
      .required()
      .label("Category")
      .error(() => {
        return { message: category };
      }),
  };

  doSubmit = () => {
    const { question, answer, category } = this.state.data;

    var formdata = {
      question: question,
      answer: answer,
      category: category,
    };
    this.props.addFaq(formdata, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllFaq();
      this.props.toggleAddUser();
      toast(<AlertSuccess message={informationSaved} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  animatedComponents = makeAnimated();
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#6119c0" : "#bdbdbd",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#6119c0",
      },
      "&:focus": {
        borderColor: "#6119c0",
      },
    }),
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add FAQ</h3>
            <a
              href="javascript:void(0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleAddUser}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("question", "Question")}
            {this.renderTextarea("answer", "Answer")}
            {this.renderSelect("category", "Category", this.state.Categories)}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  faq: getFaq(state).faq,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllFaq: (params) => dispatch(loadAllFaq(params)),
  addFaq: (param, callback) => dispatch(addFaq(param, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddFaq);
