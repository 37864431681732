import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import HelmetComponent from "../common/helmetComponent";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
  getAssessmentCategory,
  loadAllAssessmentcategory,
  deletedAssessmentcategory,
  updateAssessmentcategory,
} from "../../store/assessmentCategories";
import AddAssessmentCategory from "./addAssessmentcategory";
import {
  informationSaved,
  deleted,
  activated,
  deactivated,
  failed,
} from "../common/misc";
import AssessmentCategoryGrid from "./assessmentCategorygrid";

class AssessmentCategory extends Component {
  state = {
    userShow: false,
    modal: false,
    active: false,
    id: "",
    admin_id: "",
    status: "",
    msg: "",
    data: "",
    editShow: false,
    delete_id: "",
    asside: false,
    isShow: false,
    asside2: false,
    asside3: false,
  };
  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  toggleEditUser = (data, id) => {
    this.setState({
      editShow: !this.state.editShow,
      data: data,
      id: id,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  deletedAssessmentcategory = (id) => {
    this.props.deletedAssessmentcategory(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAssessmentcategory();
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  componentDidMount = () => {
    this.props.loadAllAssessmentcategory();
  };
  render() {
    const { assessmentcategory } = this.props;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Assessment Category Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Assessment Categories
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          Total : {assessmentcategory.length} records
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void (0)"
                        className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleAddUser();
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add Assessment Category
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Assessment Category Management
                            <span className="d-block text-muted pt-2 font-size-sm">
                              You can view/add/edit/delete assessment
                              categories.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>
                                    Category Name
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>
                                    Can Be Deleted
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>
                                    Created On
                                  </span>
                                </th>

                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>Action</span>
                                </th>
                              </tr>
                            </thead>
                            <AssessmentCategoryGrid
                              data={this.state.data}
                              isShow={this.state.editShow}
                              toggleEditUser={this.toggleEditUser}
                              delete_id={this.state.delete_id}
                              modal={this.state.modal}
                              toggle={this.toggle}
                              id={this.state.id}
                              assessmentcategory={assessmentcategory}
                              deleted={this.deletedAssessmentcategory}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddAssessmentCategory
            isShow={this.state.isShow}
            toggleAddUser={this.toggleAddUser}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  assessmentcategory: getAssessmentCategory(state).assessmentcategory,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAssessmentcategory: () => dispatch(loadAllAssessmentcategory()),
  deletedAssessmentcategory: (id, callback) =>
    dispatch(deletedAssessmentcategory(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentCategory);
