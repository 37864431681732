import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import Moment from 'moment';
import { getQuestion,loadAllQuestion,addQuestion} from "../../store/question";
import {

  questionRequired,
  informationSaved,
  failed
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

const initialState={
	data: {
		name: "",
	},
  	errors: {
		name: "",
	},
}
class AddQuestionGroup extends Form {
  state = initialState;
  schema = {
    name: Joi.string()
      .required()
      .label("Name")
      .error(() => {
        return { message: questionRequired };
		}),
	};
 	
  	doSubmit = () => {
		const { data } = this.state;
		this.props.addQuestion(data, this.callback);
		
		
	};

	callback = (res) => {
      if (res && res.status === 200) {
        this.props.loadAllQuestion();
        this.props.toggleAddUser();
		toast(<AlertSuccess message={informationSaved}/>)
		this.setState(initialState)
      }
	  else{
		toast(<AlertError message={failed}/>)
	   }
    };
  	toggleAddClose = () => {
		this.setState(initialState);
		this.props.toggleAddUser();
	};
	render() {
		return (
			<>
				<div className={this.props.isShow===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Add Question Group</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary"  onClick={this.toggleAddClose}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
					</div>
					<hr/>
					<form action="" onSubmit={this.handleSubmit}>
						{this.renderInput("name",  "Name")}
           				
						<hr />
            			<div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              				<div className="my-3 mr-2">
                				<span className="text-muted mr-2"></span>
                				<a href="#" className="font-weight-bold"></a>
             				</div>
							<button type="submit" className="btn btn-primary font-weight-bolder" >
								Save
							</button>
            			</div>
					</form>
				</div>
				{this.props.isShow=== true?<div className="offcanvas-overlay"></div>:""}
			</>
		)
	}
}
const mapStateToProps = (state) => ({
   question: getQuestion(state).question,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllQuestion: () => dispatch(loadAllQuestion()),
  addQuestion: (param,callback) => dispatch(addQuestion(param,callback)),
 });
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestionGroup);