import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import StatusModal from "../common/statusModal";

class ChangeExpertGrid extends Component {
  render() {
    console.log(this.props.expert);
    return (
      <tbody className="datatable-body">
        {this.props.expert.map((e, i) => (
          <tr
            data-row="10"
            className="datatable-row"
            style={{ left: "0px" }}
            key={i}
          >
            <td className="datatable-cell">
              <span style={{ width: "190px" }}>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {e.patient_id &&
                    e.patient_id.first_name &&
                    e.patient_id.first_name + " " + e.patient_id.last_name}
                </div>
                <div className="font-weight-bold text-muted">
                  {e.patient_id && e.patient_id.type === 2
                    ? "Guardian"
                    : e.patient_id && e.patient_id.partner_id
                    ? "Partner"
                    : "Self"}
                </div>
                <div className="font-weight-bold text-muted">
                  {e.patient_id && e.patient_id.email}
                </div>
                <div className="font-weight-bold text-muted">
                  {e.patient_id && e.patient_id.mobile}
                </div>
              </span>
            </td>
            <td className="datatable-cell">
              <span style={{ width: "190px" }}>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {e.doctor_id && e.doctor_id.name}
                </div>
                <div className="font-weight-bold text-muted">
                  {e.doctor_id && e.doctor_id.field_type.name}
                </div>

                <div className="font-weight-bold text-muted">
                  {e.doctor_id && e.doctor_id.email}
                </div>
                <div className="font-weight-bold text-muted">
                  {e.doctor_id && e.doctor_id.mobile}
                </div>
              </span>
            </td>
            <td className="datatable-cell">
              <span style={{ width: "120px" }}>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {e.reason_title}
                </div>
              </span>
            </td>
            <td className="datatable-cell">
              <span style={{ width: "120px" }}>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {e.reason_text}
                </div>
              </span>
            </td>
            <td className="datatable-cell">
              <span style={{ width: "100px" }}>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {e.is_apptoved === true ? (
                    <span className="label label-lg font-weight-bold  label-light-success label-inline">
                      Approved
                    </span>
                  ) : (
                    <Link
                      to={"#"}
                      onClick={() => {
                        this.props.toggleAcitve(
                          e._id,
                          true,
                          "Are you sure you want to approve this record?"
                        );
                      }}
                    >
                      <span className="label label-lg font-weight-bold  label-light-danger label-inline">
                        Pending
                      </span>
                    </Link>
                  )}
                </div>
              </span>
            </td>

            <td className="datatable-cell">
              <span style={{ width: "150px" }}>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {Moment(e.created_on).format("DD-MM-YYYY")}
                </div>
              </span>
            </td>
          </tr>
        ))}
        <StatusModal
          show={this.props.modalActive}
          msg={this.props.msg}
          onHide={this.props.toggleAcitve}
          status_id={this.props.status_id}
          Update={this.props.Update}
        />
      </tbody>
    );
  }
}
export default ChangeExpertGrid;
