import React, {Component} from 'react';
import {Modal,Button} from 'react-bootstrap'
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import closeBtn from "../../include/media/close.svg";

export default class ModalPopup extends Component {
 
render(){
   return (
	<div className= "modal fade" tabIndex="-1" role="dialog"  aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered modal-sm" role="document">
			<div className="modal-content">
			<Modal
				{...this.props}
				aria-labelledby="contained-modal-title-vcenter"
				centered> 
				<div className="modal-header"><h3></h3>
					<button type="button" className="close mt-2" data-dismiss="modal" onClick={this.props.onHide}>
						<img src={closeBtn} alt="close popup"/>
					</button>
                </div>
				<div className="modal-body">
					<div className="modal-alert-box">
						<h3 className="mb-6">Alert!</h3>
						<p>Are you sure you want to delete this record?</p>
						<p> This action cannot be reverted.</p>
					</div>
					<div className="modal-action-btn">
						<a href="javascript:void (0)" onClick={() => this.props.deleted(this.props.delete_id)} className="btn btn-delete">Delete</a>
						<a href="javascript:void (0)" onClick={this.props.onHide} className="btn btn-cancel" data-dismiss="modal" aria-label="Close">Cancel</a>
					</div>
				</div>
			</Modal>
			</div>
      </div>
    </div>
  );
}
}