import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "patient",
  initialState: {
    patient: [],
    pagination:{},
    update: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    patientRequested: (patient, action) => {
      patient.loading = true;
    },

    patientReceived: (patient, action) => {
      patient.patient = action.payload.data;
      patient.pagination=action.payload.pagination;
      patient.loading = false;
      patient.lastFetch = Date.now();
    },

    patientRequestFailed: (patient, action) => {
      patient.loading = false;
    },
    
    updatedPatient: (patient, action) => {
      patient.update = action.payload;
      patient.loading = false;
      patient.lastFetch = Date.now();
    },

    updatedPatientRequestFailed: (patient, action) => {
      patient.loading = false;
    },
  },
});

export const {
  patientRequested,
  patientReceived,
  patientRequestFailed,
  updatedPatient,
  updatedPatientRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "patient/";


export const loadAllPatient = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: patientRequested.type,
      onSuccess: patientReceived.type,
      onError: patientRequestFailed.type,
    })
  );
};
export const updatePatient = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url  + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedPatient.type,
      onError: updatedPatientRequestFailed.type,
    })
  );
};
export const deletedPatient = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url:url + id,
      method: "delete",
      callback,
    })
  );
};

export const getProduct = createSelector(
  (state) => state.entities.patient,
  (patient) => patient
);
