import React from 'react'
import Joi from 'joi-browser'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { connect } from 'react-redux'
import { loadAllDoctor, getDoctor } from '../../store/doctor'
import SelectSearchAsync from '../common/form/selectSearchAsync'

import { toast } from 'react-toastify'
import AlertError from '../common/alertError'
import Form from '../common/form/form'
import { title } from '../common/misc'

const initialState = {
  data: {
    text: '',
    doctor_id: [],
  },
  errors: {
    text: '',
    doctor_id: [],
  },
  for_all: true,
}
class AddNotification extends Form {
  state = initialState
  schema = {
    text: Joi.string()
      .required()
      .label('Text')
      .error(() => {
        return { message: title }
      }),
    doctor_id: Joi.string()
      .required()
      .label('Doctors')
      .error(() => {
        return { message: 'Doctors field is required' }
      }),
  }

  animatedComponents = makeAnimated()

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }

  componentDidMount = () => {
    this.setState({ state: initialState })
  }
  toggleCheckbox = e => {
    this.setState(state => {
      return {
        for_all: !state.for_all,
      }
    })
  }

  doSubmit = () => {
    const { text, doctor_id } = this.state.data
    var formdata = {
      text,
      for_all: this.state.for_all,
      doctor_id: doctor_id && doctor_id.map(e => e.value),
    }
    if (!text) {
      toast(<AlertError message={'Text field is mandatory !!!'} />)
      return
    } else if (
      (text && this.state.for_all) ||
      (text && !this.state.for_all && doctor_id.length > 0)
    ) {
      this.props.addNotification(formdata, this.props.callback)
    } else {
      toast(<AlertError message={'Doctor field is mandatory !!!'} />)
    }
  }

  loadOptions = inputValue => {
    this.props.loadAllDoctor({
      name: inputValue,
    })
  }

  render() {
    const docArr =
      (this.props.allDoctor &&
        this.props.allDoctor.map(ele => ({
          name: ele.name,
          value: ele._id,
        }))) ||
      []

    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? 'offcanvas offcanvas-left p-10 offcanvas-on'
              : 'offcanvas offcanvas-left p-10'
          }>
          <div className='offcanvas-header d-flex align-items-center justify-content-between pb-5'>
            <h3 className='font-weight-bold m-0'>Add Notification</h3>
            <a
              href='javascript:void (0)'
              className='btn btn-xs btn-icon btn-light btn-hover-primary'
              onClick={this.props.toggleAddUser}>
              <i className='ki ki-close icon-xs text-muted'></i>
            </a>
          </div>
          <hr />
          <form action='' onSubmit={this.handleSubmit}>
            {this.renderInput('text', 'Text')}
            <div className='form-group'>
              <label>For All</label>
              <div className='checkbox-list'>
                <label className='checkbox'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='agree'
                    value=''
                    checked={this.state.for_all === true ? true : false}
                    onClick={this.toggleCheckbox.bind(this)}
                  />
                  <span></span>
                </label>
              </div>
            </div>
            {!this.state.for_all && (
              <div>
                <label>Doctors</label>

                <SelectSearchAsync
                  name='doctor_id'
                  options={docArr}
                  //label='Patient'
                  error={this.state.errors.doctor_id}
                  value={this.state.data.doctor_id}
                  onChange={e => {
                    const doctorVal = e.currentTarget
                    const data = { ...this.state.data }
                    if (e) {
                      data.doctor_id = doctorVal
                      this.setState({ data })
                    }
                  }}
                  loadOptions={this.loadOptions}
                  isMulti={true}
                />

                {/* <Select
                  // key={this.state.data.category.length}
                  onChange={e => {
                    const data = { ...this.state.data }
                    if (e) {
                      data.doctor_id = e
                      this.setState({ data })
                    }
                  }}
                  isMulti
                  placeholder=''
                  styles={this.customStyles}
                  classNameName='form-groupxx'
                  closeMenuOnSelect={false}
                  components={this.animatedComponents}
                  options={docArr}
                  // defaultValue={this.state.data.category}
                /> */}
                {this.state.errors.doctor_id && (
                  <div className='fv-plugins-message-container'>
                    <div data-validator='notEmpty' className='fv-help-block'>
                      {this.state.errors.doctor_id}
                    </div>
                  </div>
                )}
              </div>
            )}

            <hr />
            <div className='form-group d-flex flex-wrap justify-content-between align-items-center mt-2'>
              <div className='my-3 mr-2'>
                <span className='text-muted mr-2'></span>
                <a href='#' className='font-weight-bold'></a>
              </div>
              <button
                type='submit'
                className='btn btn-primary font-weight-bolder'
                onClick={() => this.doSubmit()}>
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className='offcanvas-overlay'></div>
        ) : (
          ''
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  allDoctor: getDoctor(state).doctor,
})
const mapDispatchToProps = dispatch => ({
  loadAllDoctor: param => dispatch(loadAllDoctor(param)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNotification)
