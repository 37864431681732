import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "template",
  initialState: {
    template: [],
    update: {},
    add: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    templateRequested: (template, action) => {
      template.loading = true;
    },

    templateReceived: (template, action) => {
      template.template = action.payload.data;
      template.loading = false;
      template.lastFetch = Date.now();
    },

    templateRequestFailed: (template, action) => {
      template.loading = false;
    },
    updatedTemplate: (template, action) => {
      template.update = action.payload;
      template.loading = false;
      template.lastFetch = Date.now();
    },

    updatedTemplateRequestFailed: (template, action) => {
      template.loading = false;
    },
    templateAdded: (template, action) => {
      template.add = action.payload;
      template.loading = false;
      template.lastFetch = Date.now();
    },

    templateAddRequestFailed: (template, action) => {
      template.loading = false;
    },
  },
});

export const {
  templateRequested,
  templateReceived,
  templateRequestFailed,
  updatedTemplate,
  updatedTemplateRequestFailed,
  templateAdded,
  templateAddRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "termTemplate/";

export const loadAllTemplate = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: templateRequested.type,
      onSuccess: templateReceived.type,
      onError: templateRequestFailed.type,
    })
  );
};
export const updateTemplate = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedTemplate.type,
      onError: updatedTemplateRequestFailed.type,
    })
  );
};
export const addTemplate = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: templateAdded.type,
      onError: templateAddRequestFailed.type,
    })
  );
};

export const getTemplate = createSelector(
  (state) => state.entities.template,
  (template) => template
);
