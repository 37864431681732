import React, { Component } from "react";
import Moment from 'moment';

class Schedule extends Component {

  tConv24=(time24) =>{
  var ts = time24;
  var H = +ts.substr(0, 2);
  var h = (H % 12) || 12;
  h = (h < 10)?("0"+h):h;
  var ampm = H < 12 ? " AM" : " PM";
  ts = h + ts.substr(2, 3) + ampm;
  return ts;
};

	render() {
		const {schedules} = this.props.data;
		return (
			<>
				<div className={this.props.schedule===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Schedule</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleSchedule}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
					</div>
					<hr/>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Time Slot: </a>
						<span className="text-muted font-weight-bold education-margin">{schedules &&schedules.time} Minutes</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Clinic Fee: </a>
						<span className="text-muted font-weight-bold education-margin">{schedules &&schedules.clinicFee} Rs</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Online Fee: </a>
						<span className="text-muted font-weight-bold education-margin">{schedules&&schedules.onlineFee} Rs</span>
					</div>
					
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Monday: </a>
						{schedules && schedules.mon.map((e,i)=>(
						<span className="text-muted font-weight-bold education-margin">{this.tConv24(e.start_time.replace(/(..)/g, '$1:').slice(0,-1))} to {this.tConv24(e.end_time.replace(/(..)/g, '$1:').slice(0,-1))} {e.is_online===true && e.is_clinic === true ? "(Online & Clinic)" : e.is_online=== true ?"(Online)":"(Clinic)"}</span> ))}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Tuesday: </a>
						{schedules && schedules.tue.map((e,i)=>(
						<span className="text-muted font-weight-bold education-margin">{this.tConv24(e.start_time.replace(/(..)/g, '$1:').slice(0,-1))} to {this.tConv24(e.end_time.replace(/(..)/g, '$1:').slice(0,-1))} {e.is_online===true && e.is_clinic === true ? "(Online & Clinic)" : e.is_online=== true ?"(Online)":"(Clinic)"}</span>  ))}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Wednesday: </a>
						{schedules && schedules.wed.map((e,i)=>(
						<span className="text-muted font-weight-bold education-margin">{this.tConv24(e.start_time.replace(/(..)/g, '$1:').slice(0,-1))} to {this.tConv24(e.end_time.replace(/(..)/g, '$1:').slice(0,-1))} {e.is_online===true && e.is_clinic === true ? "(Online & Clinic)" : e.is_online=== true ?"(Online)":"(Clinic)"}</span>  ))}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Thrusday: </a>
						{schedules && schedules.thu.map((e,i)=>(
						<span className="text-muted font-weight-bold education-margin">{this.tConv24(e.start_time.replace(/(..)/g, '$1:').slice(0,-1))} to {this.tConv24(e.end_time.replace(/(..)/g, '$1:').slice(0,-1))} {e.is_online===true && e.is_clinic === true ? "(Online & Clinic)" : e.is_online=== true ?"(Online)":"(Clinic)"}</span>  ))}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Friday: </a>
						{schedules && schedules.fri.map((e,i)=>(
						<span className="text-muted font-weight-bold education-margin">{this.tConv24(e.start_time.replace(/(..)/g, '$1:').slice(0,-1))} to {this.tConv24(e.end_time.replace(/(..)/g, '$1:').slice(0,-1))} {e.is_online===true && e.is_clinic === true ? "(Online & Clinic)" : e.is_online=== true ?"(Online)":"(Clinic)"}</span>  ))}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Saturday: </a>
						{schedules && schedules.sat.map((e,i)=>(
						<span className="text-muted font-weight-bold education-margin">{this.tConv24(e.start_time.replace(/(..)/g, '$1:').slice(0,-1))} to {this.tConv24(e.end_time.replace(/(..)/g, '$1:').slice(0,-1))} {e.is_online===true && e.is_clinic === true ? "(Online & Clinic)" : e.is_online=== true ?"(Online)":"(Clinic)"}</span>  ))}
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Sunday: </a>
						{schedules && schedules.sun.map((e,i)=>(
						<span className="text-muted font-weight-bold education-margin">{this.tConv24(e.start_time.replace(/(..)/g, '$1:').slice(0,-1))} to {this.tConv24(e.end_time.replace(/(..)/g, '$1:').slice(0,-1))} {e.is_online===true && e.is_clinic === true ? "(Online & Clinic)" : e.is_online=== true ?"(Online)":"(Clinic)"}</span>  ))}
					</div>
					
				</div>
				{this.props.schedule=== true?<div className="offcanvas-overlay" onClick={this.props.toggleSchedule}></div>:""}
			</>
		)
	}
}

export default Schedule;