import React, { Component } from "react";
import Moment from 'moment';

class Payment extends Component {
    render() {
        const { payData, data } = this.props;
        return (
            <>
                <div className={this.props.payment === true ? "offcanvas offcanvas-left p-10 offcanvas-on" : "offcanvas offcanvas-left p-10"} style={{ "height": "100%", "overflowY": "auto" }}>
                    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                        <h3 className="font-weight-bold m-0">Payment details</h3>
                        <a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={() => this.props.togglePayment(this.props.payData, this.props.data)}>
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                    <hr />

                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Consultation Charges: </a>
                        <span className="text-muted font-weight-bold education-margin">Rs {data.consultation_charges}</span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Convenience Charges: </a>
                        <span className="text-muted font-weight-bold education-margin">Rs {data.convenience_charges}</span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">GST: </a>
                        <span className="text-muted font-weight-bold education-margin">Rs {data.taxes}</span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Total: </a>
                        <span className="text-muted font-weight-bold education-margin">Rs {data.amount}</span>
                    </div>

                    {payData && payData.map((e, i) => (
                        <>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Method: </a>
                                <span className="text-muted font-weight-bold education-margin">{e.method}</span>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Trasaction Id: </a>
                                <span className="text-muted font-weight-bold education-margin">{e.id}</span>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Order Id: </a>
                                <span className="text-muted font-weight-bold education-margin">{e.order_id}</span>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Created On: </a>
                                <span className="text-muted font-weight-bold education-margin">{Moment.unix(e.created_at).format("DD-MM-YYYY hh:mm:ss A")}</span>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Status: </a>
                                <span className="text-muted font-weight-bold education-margin">{e.captured === true ? "Success" : "Failed"}</span>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Refund: </a>
                                <span className="text-muted font-weight-bold education-margin">Rs {e.amount_refunded / 100}</span>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Refund Status: </a>
                                <span className="text-muted font-weight-bold education-margin">{e.refund_status}</span>
                            </div>
                        </>
                    ))}
                </div>
                {this.props.payment === true ? <div className="offcanvas-overlay"></div> : ""}
            </>
        )
    }
}

export default Payment;

