import React, { Component } from "react";
import { getMessage,loadAllMessage } from "../../../store/message";
import { connect } from "react-redux";
import SearchIcon from "../../common/searchIcon";

const initialState = {
	key: "",
	message:"",
	notes:""
};

class Search extends Component {
	state = initialState;
	
	onSearch = (e) => {
		e.preventDefault();
		if (this.state.key) {
			this.props.loadAllMessage({"key":this.state.key})
		}
		else if (this.state.message){
			this.props.loadAllMessage({"message":this.state.message})
		}
		else if (this.state.notes){
			this.props.loadAllMessage({"notes":this.state.notes})
		}
	};
	  reset = ()=>{
       this.props.loadAllMessage({"page":this.props.currentPage})
	   this.setState(initialState)
       }
	render(){
		return(
			<>
				<form className="ml-5"  onSubmit={this.onSearch}>
					<div className="input-group input-group-sm input-group-solid" onClick={this.onSearch} style={{ "maxWidth": "195px" }}>
						<input
						name="key"
						id="key"
						className="form-control"
						placeholder="Search By Key"
						autoComplete="off"
						dir="ltr"
						value={this.state.key}
						onChange={(e) =>
							this.setState({ key: e.target.value })
						}
						/>
						<SearchIcon/>
					</div>
				</form>
				<form className="ml-5" onSubmit={this.onSearch}>
					<div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.onSearch}>
						<input
						name="message"
						id="message"
						className="form-control"
						placeholder="Search By Message"
						autoComplete="off"
						dir="ltr"
						value={this.state.message}
						onChange={(e) =>
							this.setState({ message: e.target.value })
						}
						/>
						<SearchIcon/>
					</div>
				</form>
				<form className="ml-5" onSubmit={this.onSearch}>
					<div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.onSearch}>
						<input
						name="notes"
						id="notes"
						className="form-control"
						placeholder="Search By Notes"
						autoComplete="off"
						value={this.state.notes}
						onChange={(e) =>
							this.setState({ notes: e.target.value })
						}
						/>
					<SearchIcon/>
					</div>
				</form>
				<form className="ml-5" onSubmit={this.reset}>
					<div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.reset}>
						<a href="javascript:void (0)" type="submit">
				  			<span className="text-dark-50 font-weight-bold" style={{"cursor":"pointer"}}>
					  			Reset
							</span>
						</a>
					</div>
				</form>
			</>
		)
	}
}
const mapStateToProps = (state) => ({
    message: getMessage(state).message,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllMessage: (params) => dispatch(loadAllMessage(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
