import React, { Component } from "react";
import Moment from 'moment';

class LinkSetup extends Component {
 
	render() {
		const {user_name} = this.props.data;
		return (
			<>
				<div className={this.props.link===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Link Setup</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleLink}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
						
					</div>
					<hr/>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Link: </a>
						<a className="text-muted font-weight-bold education-margin" href={process.env.REACT_APP_BASEURL + "dr/" + user_name} target="_blank">{process.env.REACT_APP_BASEURL + "dr/" + user_name}</a>
					</div>
					
				</div>
				{this.props.link=== true?<div className="offcanvas-overlay" onClick={this.props.toggleLink}></div>:""}
			</>
		)
	}
}

export default LinkSetup;