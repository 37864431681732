import React from "react";
import "./include/css/style.bundle.css";
import "./include/css/alert.css";
import "./include/css/pages/login/classic/login-2.css";
import "react-datepicker/dist/react-datepicker.css";
import "./include/css/tags.css";
import "./include/plugins/global/plugins.bundle.css";
import "./include/plugins/custom/prismjs/prismjs.bundle.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "./components/administrator/signIn";
import AdminTable from "./components/administrator/adminTable";
import PatientTable from "./components/patient/patientTable";
import ForMyself from "./components/patient/forMyself";
import GuardianDetails from "./components/patient/guardiansDetail";
import BlogTable from "./components/blogs/blogTable";
import AddBlog from "./components/blogs/blogAdd";
import BlogEdit from "./components/blogs/blogEdit";
import BlogCategory from "./components/blogs/blogCategory";
import AssessmentCategory from "./components/assessment/assessmentCategory";
import Section from "./components/assessment/section";
import QuestionGroup from "./components/assessment/questionGroup";
import ScoringReference from "./components/assessment/scoringReference";
import Assessment from "./components/assessment/assessment";
import ForCouple from "./components/patient/forCouple";
import SeoTable from "./components/settings/seoTable";
import ChangeExpert from "./components/settings/changeExpert";
import MessageTable from "./components/settings/userMessage/messageTable";
import TemplateTable from "./components/settings/termsTemplate/templateTable";
import DoctorTable from "./components/doctors/doctorTable";
import AppointmentTable from "./components/appointment/appointmentTable";
import NotificationTable from "./components/notifications/notificationTable";
import AssessmentForm from "./components/assessment/assessmentForm";
import FaqTable from "./components/settings/faq/faqTable";
import PrivateRoute from "./components/routes/PrivateRoute";
import PublicRoute from "./components/routes/PublicRoute";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        hideProgressBar
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        toastClassName="alert alert-success alert-white"
      />
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={SignIn} />
          <PrivateRoute exact path="/administrators" component={AdminTable} />
          <PrivateRoute exact path="/patients" component={PatientTable} />
          <PrivateRoute exact path="/patient-add-for-myself" component={ForMyself} />
          <PrivateRoute
            exact
            path="/patient-add-guardian"
            component={GuardianDetails}
          />
          <PrivateRoute exact path="/patient-add-for-couple" component={ForCouple} />
          <PrivateRoute exact path="/blogs" component={BlogTable} />
          <PrivateRoute exact path="/add-new-blog" component={AddBlog} />
          <PrivateRoute exact path="/blog-category" component={BlogCategory} />
          <PrivateRoute exact path="/edit-blog" component={BlogEdit} />
          <PrivateRoute exact path="/seo-settings" component={SeoTable} />
          <PrivateRoute
            exact
            path="/change-expert-requests"
            component={ChangeExpert}
          />

          <PrivateRoute exact path="/doctors" component={DoctorTable} />
          <PrivateRoute exact path="/appointments" component={AppointmentTable} />
          <PrivateRoute exact path="/notifications" component={NotificationTable} />
          <PrivateRoute exact path="/user-facing-messages" component={MessageTable} />
          <PrivateRoute exact path="/faq" component={FaqTable} />

          <PrivateRoute exact path="/terms-templates" component={TemplateTable} />

          <PrivateRoute exact path="/assessments" component={Assessment} />
          <PrivateRoute
            exact
            path="/assessment-category"
            component={AssessmentCategory}
          />
          <PrivateRoute
            exact
            path="/assessment-scoring-reference"
            component={ScoringReference}
          />
          <PrivateRoute exact path="/sections" component={Section} />
          <PrivateRoute exact path="/edit-assessment" component={AssessmentForm} />
          <PrivateRoute exact path="/question-groups" component={QuestionGroup} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
