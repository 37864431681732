import React from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { loadAllTemplate, addTemplate, getTemplate } from "../../../store/termsTemplates";

import {
    KeyRequired,
    informationSaved,
    failed,
} from "../../common/misc";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";
import { Link } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';

const initialState = {
    data: {
        key: "",
        text: "",
        header_text: ""
    },
    errors: {
        key: "",
        text: "",
        header_text: ""
    },
}
class AddTemplate extends Form {
    state = initialState;
    schema = {
        key: Joi.string()
            .required()
            .label("Key")
            .error(() => {
                return { message: KeyRequired };
            }),
        text: Joi.string()
            .required()
            .label("Text")
            .error(() => {
                return { message: "HTML Data field is required" };
            }),
        header_text: Joi.string()
            .required()
            .label("Header Text")
            .error(() => {
                return { message: "Header Text field is required" };
            }),


    };
    handleEditorChange = (content) => {
        const data = { ...this.state.data };
        data.text = content
        this.setState({ data })

    }
    doSubmit = () => {
        const { key, text, header_text } = this.state.data;
        var formdata = {
            key: key,
            html_data: text,
            header_text: header_text

        }
        this.props.addTemplate(formdata, this.callback);
    };

    callback = (res) => {
        if (res && res.status === 200) {
            this.props.loadAllTemplate({ page: this.props.initialPage });
            this.props.toggleAddUser();
            toast(<AlertSuccess message={informationSaved} />)
            this.setState(initialState)
        }
        else if (res && res.status === 409) {
            this.props.toggleAddUser();
            toast(<AlertError message={"Already Exist"} />)
            this.setState(initialState)
        }
        else {
            toast(<AlertError message={failed} />)
        }
    };

    render() {
        return (
            <>
                <div className={this.props.isShow === true ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on" : "offcanvas offcanvas3 offcanvas-left p-10"} style={{ "height": "100%", "overflow-y": "auto" }}>
                    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                        <h3 className="font-weight-bold m-0">Add Terms Templates</h3>
                        <a href="javascript:void(0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleAddUser}>
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                    <hr />
                    <form action="" onSubmit={this.handleSubmit}>
                        {this.renderInput("key", "Key")}
                        {this.renderInput("header_text", "Header Text")}

                        <div className="form-group">
                            <label>Text</label>
                            <Editor
                                value={this.state.data.text}
                                init={{
                                    branding: false,
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | \
														 alignleft aligncenter alignright alignjustify | \
														 bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={this.handleEditorChange}
                            />
                            {this.state.errors.text && <div className="fv-plugins-message-container">
                                <div data-validator="notEmpty" className="fv-help-block">{this.state.errors.text}</div>
                            </div>}
                        </div>
                        <hr />
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                            <div className="my-3 mr-2">
                                <span className="text-muted mr-2"></span>
                                <Link to={"#"} className="font-weight-bold"></Link>
                            </div>
                            <button type="submit" className="btn btn-primary font-weight-bolder" >
                                Save
							</button>
                        </div>
                    </form>
                </div>
                {this.props.isShow === true ? <div className="offcanvas-overlay"></div> : ""}
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    template: getTemplate(state).template,
});
const mapDispatchToProps = (dispatch) => ({
    loadAllTemplate: (params) => dispatch(loadAllTemplate(params)),
    addTemplate: (param, callback) => dispatch(addTemplate(param, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);