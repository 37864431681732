import Joi from 'joi-browser'
import { Modal } from 'react-bootstrap'
import Form from '../common/form/form'
import AlertError from '../common/alertError'
import AlertSuccess from '../common/alertSuccess'
import closeBtn from '../../include/media/close.svg'
import { informationSaved, failed } from '../common/misc'
import { addComment } from '../../store/appointment'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

class AddComment extends Form {
  state = {
    data: { comment: '' },
    errors: {
      comment: '',
    },
  }

  schema = {
    comment: Joi.string()
      .required()
      .label('Comment')
      .error(() => {
        return { message: 'Comment field is required' }
      }),
  }

  doSubmit = () => {
    const { data } = this.state
    console.log('data', data, this.props)
    this.props.addComment(
      { ...data, id: this.props.currentData._id },
      this.callback
    )
  }

  callback = res => {
    if (res && res.status === 200) {
      this.props.handleLoad()
      this.props.onHide()
      toast(<AlertSuccess message={informationSaved} />)
    } else {
      this.props.onHide()
      toast(<AlertError message={failed} />)
    }
    this.setState({
      data: { comment: '' },
      errors: {
        comment: '',
      },
    })
  }

  render() {
    return (
      <Modal
        {...this.props}
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <div className='modal-header'>
          <h3></h3>
          <button
            type='button'
            className='close mt-2'
            data-dismiss='modal'
            onClick={() => {
              this.setState({
                data: { comment: '' },
                errors: {
                  comment: '',
                },
              })
              this.props.onHide()
            }}>
            <img src={closeBtn} alt='close popup' />
          </button>
        </div>
        <div className='modal-body'>
          <form action='' onSubmit={this.handleSubmit} autocomplete='off'>
            {this.renderTextarea('comment', 'Add Comment')}
            <div className='mt-2'>
              <button
                type='submit'
                className='btn btn-primary ml-2 font-weight-bolder'
                style={{ float: 'right' }}>
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  addComment: (param, callback) => dispatch(addComment(param, callback)),
})

export default connect(null, mapDispatchToProps)(AddComment)
