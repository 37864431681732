import React, { Component } from "react";
import Moment from "moment";

class NotificationGrid extends Component {
  render() {
    return (
      <tbody className="datatable-body">
        {this.props.notification.map((e, i) => (
          <>
            <tr data-row="10" className="datatable-row" key={i}>
              <td className="datatable-cell" style={{ width: "60%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.text}
                  </div>
                  <div className="font-weight-bold text-muted"></div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.for_all ? "All" : e.doctor_id.length}
                  </div>
                  <div className="font-weight-bold text-muted"></div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "10%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {e.viewed_by.length}
                  </div>
                  <div className="font-weight-bold text-muted"></div>
                </span>
              </td>
              <td className="datatable-cell" style={{ width: "15%" }}>
                <span>
                  <div className="font-weight-bolder font-size-lg mb-0">
                    {Moment(e.created_at).format("DD-MM-YYYY")}
                  </div>
                  <div className="font-weight-bold text-muted"></div>
                </span>
              </td>
            </tr>
          </>
        ))}
      </tbody>
    );
  }
}
export default NotificationGrid;
