import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "message",
  initialState: {
    message: [],
    pagination:{},
    update: {},
    add:{},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    messageRequested: (message, action) => {
      message.loading = true;
    },

    messageReceived: (message, action) => {
      message.message = action.payload.data;
      message.pagination=action.payload.pagination;
      message.loading = false;
      message.lastFetch = Date.now();
    },

    messageRequestFailed: (message, action) => {
      message.loading = false;
    },
    updatedMessage: (message, action) => {
      message.update = action.payload;
      message.loading = false;
      message.lastFetch = Date.now();
    },

    updatedMessageRequestFailed: (message, action) => {
      message.loading = false;
    },
     messageAdded: (message, action) => {
      message.add = action.payload;
      message.loading = false;
      message.lastFetch = Date.now();
    },

    messageAddRequestFailed: (message, action) => {
      message.loading = false;
    },
  },
});

export const {
  messageRequested,
  messageReceived,
  messageRequestFailed,
  updatedMessage,
  updatedMessageRequestFailed,
  messageAdded,
  messageAddRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "message/";


export const loadAllMessage = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: messageRequested.type,
      onSuccess: messageReceived.type,
      onError: messageRequestFailed.type,
    })
  );
};
export const updateMessage = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url  + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedMessage.type,
      onError: updatedMessageRequestFailed.type,
    })
  );
};
export const addMessage = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: messageAdded.type,
      onError: messageAddRequestFailed.type,
    })
  );
};


export const getMessage = createSelector(
  (state) => state.entities.message,
  (message) => message
);
