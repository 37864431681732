import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "misc",
  initialState: {
    fieldTypes: [],
	loadingFieldTypes: false,
    lastFetchFieldTypes: null,
   
  },
  reducers: {
    fieldTypesRequested: (misc, action) => {
      misc.loadingFieldTypes = true;
    },
    fieldTypesReceived: (misc, action) => {
      misc.fieldTypes = action.payload;
      misc.loadingFieldTypes = false;
      misc.lastFetchFieldTypes = Date.now();
    },
    fieldTypesRequestFailed: (misc, action) => {
      misc.loadingFieldTypes = false;
    },
  },
});

export const {
  fieldTypesRequested,
  fieldTypesReceived,
  fieldTypesRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const fieldTypeUrl = "fieldType/";

export const loadFieldType = () => (dispatch, getState) => {
  const { lastFetchFieldTypes } = getState().entities.misc;

  const diffInMinutes = moment().diff(moment(lastFetchFieldTypes), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: fieldTypeUrl,
      onStart: fieldTypesRequested.type,
      onSuccess: fieldTypesReceived.type,
      onError: fieldTypesRequestFailed.type,
    })
  );
};

export const getMisc = createSelector(
  (state) => state.entities.misc,
  (misc) => misc
);
