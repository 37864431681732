import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import { Link } from "react-router-dom";

export default class ForMyself extends Component {
    state = {
	 	userShow: false,
  	};
	toggleShowUser = () => {
	    this.setState({
	      userShow: !this.state.userShow,
	    });
  	};
    render() {
		return (
			<div>
				<div className="d-flex flex-row flex-column-fluid page">
					<SideBar userShow={this.state.userShow} toggleShowUser={this.toggleShowUser}/>
					<div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
						<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
							<div className="subheader py-3 py-lg-8 subheader-transparent" id="kt_subheader">
								<div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
									<div className="d-flex align-items-center mr-1">
										<div className="d-flex align-items-baseline flex-wrap mr-5">
											<h2 className="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">Add Patient For Myself</h2>
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column-fluid">
								<div className="container-fluid">
									<div className="card card-custom card-sticky" id="kt_page_sticky_card">
										<div className="card-header" style={{"z-index": "90", "top": "-1px", "left": "125px", "right": "25px"}}>
											<div className="card-title">
												<h3 className="card-label">Personal details<i className="mr-2"></i></h3>
											</div>
											<div className="card-toolbar">
												<Link to={"/patients"} className="btn btn-light-primary font-weight-bolder mr-2">
												<i className="ki ki-long-arrow-back icon-sm"></i>Back</Link>
												<div className="btn-group">
													<button type="button" className="btn btn-primary font-weight-bolder">
													<i className="ki ki-check icon-sm"></i>Save</button>
												</div>
											</div>
										</div>
										<div className="card-body">
											<form className="form" id="kt_form">
												<div className="row">
													<div className="col-xl-12">
														<div className="my-5">
															<div className="form-group row">
																<div className="col-lg-6">
																	<label>First Name</label>
																	<input type="text" className="form-control" placeholder="First Name" />
																</div>
																<div className="col-lg-6">
																	<label>Last Name</label>
																	<input type="text" className="form-control"  placeholder="Last Name" />
																</div>
															</div>
															<div className="form-group row">
																<div className="col-lg-6">
																	<label>Email</label>
																	<input type="text" className="form-control" placeholder="Email" />
																</div>
																<div className="col-lg-3">
																	<label>Mobile Number</label>
																	<input type="text" className="form-control" placeholder="Mobile Number" />
																</div>
																<div className="col-lg-3">
																	<label>DOB</label>
																	<input type="text" className="form-control" placeholder="Date of Birth" />
																</div>
															</div>
															<div className="form-group row">
																<div className="col-lg-6">
																	<label>Security Question</label>
																	<input type="text" className="form-control" placeholder="Security Question" />
																</div>
																<div className="col-lg-6">
																	<label>Answer</label>
																	<input type="text" className="form-control" placeholder="Answer" />
																</div>
															</div>
															
														</div>
													</div>
												</div>
												<div className="card-toolbar">
													<div className="btn-group" style={{"float": "right"}}>
														<button type="button" className="btn btn-primary font-weight-bolder" > 
														<i className="ki ki-check icon-sm"></i>Save</button>
													</div>
													<Link to={"/patients"} className="btn btn-light-primary font-weight-bolder mr-2" style={{"float": "right"}}>
													<i className="ki ki-long-arrow-back icon-sm"></i>Back</Link>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<UserProfile userShow={this.state.userShow} toggleShowUser={this.toggleShowUser}/>
				<ScrollTop />
			</div>
		
			);
		}
		}
