import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { getScoring, loadAllScoring, addScoring, updateScoring } from "../../store/scoringReference";
import _ from "lodash";

import {
    description,
    title,
    informationSaved,
    failed,
    code,
    minRange,
    maxRange,
    severityRequired,
    recommendationRequired
} from "../common/misc";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

class AddScoringReference extends Form {
    state = {
        inputs: [1],
        data: {
            title: "",
            code: "",
            description: "",
            min_range1: "",
            max_range1: "",
            severity1: "",
            recommendation1: ""

        },
        errors: {
            title: "",
            code: "",
            description: ""
        },
    };
    schema = {
        title: Joi.string()
            .required()
            .label("Title")
            .error(() => {
                return { message: title };
            }),
        description: Joi.string()
            .required()
            .label("Description")
            .error(() => {
                return { message: description };
            }),

        code: Joi.string()
            .required()
            .label("Code")
            .error(() => {
                return { message: code };
            }),
        min_range1: Joi.number()
            .required()
            .label("Min Range")
            .error(() => {
                return { message: minRange };
            }),
        max_range1: Joi.number()
            .required()
            .label("Max Range")
            .error(() => {
                return { message: maxRange };
            }),
        severity1: Joi.string()
            .required()
            .label("Severity")
            .error(() => {
                return { message: severityRequired };
            }),
        recommendation1: Joi.string()
            .required()
            .label("Recommendation")
            .error(() => {
                return { message: recommendationRequired };
            }),
    };

    removeLast = (e) => {
        e.preventDefault();
        const { inputs } = this.state;
        const removeNumber = inputs.length;
        inputs.pop();
        this.setState({ inputs });
        const min_range = "min_range" + removeNumber;
        const max_range = "max_range" + removeNumber;
        const severity = "severity" + removeNumber;
        const recommendation = "recommendation" + removeNumber;
        delete [min_range];
        delete [max_range];
        delete [severity];
        delete [recommendation];

    };
    addMore = (e) => {
        e.preventDefault();
        const { inputs } = this.state;
        const NewNumber = inputs.length + 1;
        inputs.push(NewNumber);
        this.setState({ inputs });

        const min_range = "min_range" + NewNumber;
        const max_range = "max_range" + NewNumber;
        const severity = "severity" + NewNumber;
        const recommendation = "recommendation" + NewNumber;

        const newData = {};
        //const newArr = {};

        newData[min_range] = "";
        newData[max_range] = "";
        newData[severity] = "";
        newData[recommendation] = "";

        this.schema[min_range] =
            Joi.number().required()
                .label("Min Range")
                .error(() => {
                    return { message: minRange };
                });
        this.schema[max_range] =
            Joi.number()
                .required()
                .label("Max Range")
                .error(() => {
                    return { message: maxRange };
                });
        this.schema[severity] =
            Joi.string()
                .required()
                .label("Severity")
                .error(() => {
                    return { message: severityRequired };
                });
        this.schema[recommendation] =
            Joi.string()
                .required()
                .label("Recommendation")
                .error(() => {
                    return { message: recommendationRequired };
                });
        const { data, errors } = this.state;

        this.setState({
            data: { ...data, ...newData },
            errors: { ...errors, ...newData },
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.props.data &&
            this.props.data !== prevProps.data
        ) {

            const inputs = _.range(
                1,
                this.props.data.values.length + 1
            );
            const newData = {};
            if (inputs.length > 1) {
                newData.code = this.props.data.code;
                newData.title = this.props.data.title;
                newData.description = this.props.data.description;
                inputs.forEach((element, i) => {

                    newData["min_range" + element] = this.props.data.values[
                        i
                    ].min_range;
                    newData["max_range" + element] = this.props.data.values[
                        i
                    ].max_range;
                    newData["severity" + element] = this.props.data.values[
                        i
                    ].severity;
                    newData["recommendation" + element] = this.props.data.values[
                        i
                    ].recommendation;

                    this.schema["min_range" + element] = Joi.number()
                        .required()
                        .error(() => {
                            return { message: minRange };
                        });
                    this.schema["max_range" + element] = Joi.number()
                        .required()
                        .error(() => {
                            return { message: maxRange };
                        });
                    this.schema["severity" + element] = Joi.string()
                        .required()
                        .error(() => {
                            return { message: severityRequired };
                        });
                    this.schema["recommendation" + element] = Joi.string()
                        .required()
                        .error(() => {
                            return { message: recommendationRequired };
                        });
                });

                this.setState({ inputs, data: newData });
            }

            if (inputs.length === 1) {
                const data = {
                    code: this.props.data.code,
                    title: this.props.data.title,
                    description: this.props.data.description,
                    min_range1: this.props.data.values[0].min_range,
                    max_range1: this.props.data.values[0].max_range,
                    severity1: this.props.data.values[0].severity,
                    recommendation1: this.props.data.values[0].recommendation,
                };

                this.setState({ inputs, data });
            }
        }
    };

    doSubmit = () => {


        const payload = [];
        (this.state.inputs.forEach((element) => {
            const obj = {
                min_range: this.state.data["min_range" + element],
                max_range: this.state.data["max_range" + element],
                severity: this.state.data["severity" + element],
                recommendation: this.state.data["recommendation" + element],
            };
            payload.push(obj);
        })
        )
        const { title, code, description } = this.state.data;
        const formdata = {
            title: title,
            code: code,
            description: description,
            values: payload,
        }
        if (this.props.id) {
            this.props.updateScoring(this.props.id, formdata, this.editCallback)
        }
        else {
            this.props.addScoring(formdata, this.callback);
        }

    };
    editCallback = (res) => {
        if (res && res.status === 200) {
            this.props.loadAllScoring();
            this.toggleClose();
            toast(<AlertSuccess message={informationSaved} />);
        }
        else {
            this.toggleClose();
            toast(<AlertError message={failed} />);
        }
    };
    callback = (res) => {
        if (res && res.status === 200) {
            this.props.loadAllScoring();
            this.toggleAddClose();
            toast(<AlertSuccess message={informationSaved} />)
        }
        else {
            this.toggleAddClose();
            toast(<AlertError message={failed} />)
        }
    };
    toggleClose = () => {
        this.setState({
            inputs: [1], data: {
                title: "",
                code: "",
                description: "",
                min_range1: "",
                max_range1: "",
                severity1: "",
                recommendation1: ""

            },
        });

        this.props.toggleEditUser();

    };
    toggleAddClose = () => {
        this.setState({
            inputs: [1],
            data: {
                title: "",
                code: "",
                description: "",
                min_range1: "",
                max_range1: "",
                severity1: "",
                recommendation1: ""
            },
        });
        this.props.toggleAddUser();

    };
    render() {
        return (
            <>
                <div className={this.props.isShow === true ? "offcanvas offcanvas-left offcanvas3 p-10 offcanvas-on" : "offcanvas offcanvas-left offcanvas3 p-10"} style={{ "height": "100%", "overflow-y": "auto" }}>
                    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                        <h3 className="font-weight-bold m-0">{this.props.id ? "Edit Scoring Reference" : "Add Scoring Reference"}</h3>
                        {this.props.id ?
                            <a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={(e) => {
                                e.preventDefault();
                                this.toggleClose();
                            }}>
                                <i className="ki ki-close icon-xs text-muted"></i>
                            </a> :
                            <a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.toggleAddClose}>
                                <i className="ki ki-close icon-xs text-muted"></i>
                            </a>}
                    </div>
                    <hr />
                    <form action="" onSubmit={this.handleSubmit}>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6">
                                    {this.renderInput("code", "Code")}
                                </div>
                                <div class="col-md-6">
                                    {this.renderInput("title", "Title")}
                                </div>
                            </div>
                        </div>

                        {this.renderTextarea("description", "Description")}
                        {this.state.inputs.map((element, i) => (
                            <React.Fragment key={element}>
                                <div className="form-group row" style={{ marginBottom: "0.50rem" }}>
                                    <div className="col-lg-2">
                                        {this.renderInput(
                                            "min_range" + element,
                                            "Min Range", "Number"
                                        )}
                                    </div>
                                    <div className="col-lg-2">
                                        {this.renderInput("max_range" + element, "Max Range", "Number")}
                                    </div>
                                    <div className="col-lg-3">
                                        {this.renderInput("severity" + element, "Severity")}
                                    </div>
                                    <div className="col-lg-3">
                                        {this.renderInput("recommendation" + element, "Recommendation")}
                                    </div>
                                    {this.state.inputs.length > 1 &&
                                        this.state.inputs.length - 1 === i && (
                                            <div className="col-lg-2">
                                                <a href="javascript:void (0)" onClick={this.removeLast} className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mt-8" title="Delete">
                                                    <span className="svg-icon svg-icon-md">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero"></path>
                                                                <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                        )}
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="row">
                            <div className="col-xl-5  mb-4">
                                <a
                                    href="#"
                                    onClick={this.addMore}
                                    className="add-more-field"
                                >
                                    + Add more
							</a>
                            </div>
                        </div>
                        <hr />
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                            <div className="my-3 mr-2">
                                <span className="text-muted mr-2"></span>
                                <a href="#" className="font-weight-bold"></a>
                            </div>
                            <button type="submit" className="btn btn-primary font-weight-bolder" >
                                Save
							</button>
                        </div>
                    </form>
                </div>
                {this.props.isShow === true ? <div className="offcanvas-overlay"></div> : ""}
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    scoring: getScoring(state).scoring,
});
const mapDispatchToProps = (dispatch) => ({
    loadAllScoring: () => dispatch(loadAllScoring()),
    addScoring: (param, callback) => dispatch(addScoring(param, callback)),
    updateScoring: (id, param, callback) => dispatch(updateScoring(id, param, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(AddScoringReference);