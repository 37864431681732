import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "administrator",
  initialState: {
    administrator: [],
    pagination:{},
    update: {},
    add:{},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    administratorRequested: (administrator, action) => {
      administrator.loading = true;
    },

    administratorReceived: (administrator, action) => {
      administrator.administrator = action.payload.data;
      administrator.pagination=action.payload.pagination;
      administrator.loading = false;
      administrator.lastFetch = Date.now();
    },

    administratorRequestFailed: (administrator, action) => {
      administrator.loading = false;
    },
    updatedAdministrator: (administrator, action) => {
      administrator.update = action.payload;
      administrator.loading = false;
      administrator.lastFetch = Date.now();
    },

    updatedAdministratorRequestFailed: (administrator, action) => {
      administrator.loading = false;
    },
     administratorAdded: (administrator, action) => {
      administrator.add = action.payload;
      administrator.loading = false;
      administrator.lastFetch = Date.now();
    },

    administratorAddRequestFailed: (administrator, action) => {
      administrator.loading = false;
    },
  },
});

export const {
  administratorRequested,
  administratorReceived,
  administratorRequestFailed,
  updatedAdministrator,
  updatedAdministratorRequestFailed,
  administratorAdded,
  administratorAddRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "admin/";


export const loadAllAdministrator = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: administratorRequested.type,
      onSuccess: administratorReceived.type,
      onError: administratorRequestFailed.type,
    })
  );
};
export const updateAdministrator = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url  + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedAdministrator.type,
      onError: updatedAdministratorRequestFailed.type,
    })
  );
};
export const addAdministrator = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: administratorAdded.type,
      onError: administratorAddRequestFailed.type,
    })
  );
};

export const deletedAdministrator = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url:url + id,
      method: "delete",
      callback,
    })
  );
};

export const getAdministrator = createSelector(
  (state) => state.entities.administrator,
  (administrator) => administrator
);
