import React, { Component } from "react";
import { getProduct, loadAllPatient } from "../../store/patient";
import { connect } from "react-redux";
import SearchIcon from "../common/searchIcon";

class Search extends Component {
  render() {
    return (
      <>
        <form className="ml-5" onSubmit={this.props.onSearch}>
          <div
            className="input-group input-group-sm input-group-solid"
            onClick={this.props.onSearch}
            style={{ maxWidth: "195px" }}
          >
            <input
              name="first_name"
              id="first_name"
              className="form-control"
              placeholder="Search By First Name"
              autoComplete="off"
              dir="ltr"
              value={this.props.first_name}
              onChange={(e) => {
                this.props.changeFirstname(e.target.value);
              }}
            />
            <SearchIcon />
          </div>
        </form>
        <form className="ml-5" onSubmit={this.props.onSearch}>
          <div
            className="input-group input-group-sm input-group-solid"
            style={{ maxWidth: "195px" }}
            onClick={this.props.onSearch}
          >
            <input
              name="mobile"
              id="mobile"
              className="form-control"
              placeholder="Search By Mobile"
              autoComplete="off"
              dir="ltr"
              value={this.props.mobile}
              onChange={(e) => {
                this.props.changeMobile(e.target.value);
              }}
            />
            <SearchIcon />
          </div>
        </form>
        <form className="ml-5" onSubmit={this.props.onSearch}>
          <div
            className="input-group input-group-sm input-group-solid"
            style={{ maxWidth: "195px" }}
            onClick={this.props.onSearch}
          >
            <input
              name="email"
              id="email"
              className="form-control"
              placeholder="Search By Email"
              autoComplete="off"
              value={this.props.email}
              onChange={(e) => {
                this.props.changeEmail(e.target.value);
              }}
            />
            <SearchIcon />
          </div>
        </form>
        <form className="ml-5" onSubmit={this.props.reset}>
          <div
            className="input-group input-group-sm input-group-solid"
            style={{ maxWidth: "195px" }}
            onClick={this.props.reset}
          >
            <a href="javascript:void (0)" type="submit">
              <span
                className="text-dark-50 font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                Reset
              </span>
            </a>
          </div>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  patient: getProduct(state),
});
const mapDispatchToProps = (dispatch) => ({
  loadAllPatient: (params) => dispatch(loadAllPatient(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
