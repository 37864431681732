import React, { Component } from "react";
import { Link } from "react-router-dom";
import EditMessage from "./editMessage";

class MessageGrid extends Component {
  	render(){
	return(
		<>
			<tbody className="datatable-body">
			{this.props.message.map((e,i) => (
				<tr data-row="10" className="datatable-row" style={{"left": "0px"}} key={i}>
					<td className="datatable-cell">
						<span style={{"width": "145px"}}>
							<div className="font-weight-bolder font-size-lg mb-0">{e.key}</div>
						</span>
					</td>
					<td className="datatable-cell">
						<span style={{"width": "250px"}}>
							<div className="font-weight-bolder font-size-lg mb-0">{e.message}</div>
						</span>
					</td>
					<td className="datatable-cell">
		                <span style={{ width: "250px"}}>
		                  <div className="font-weight-bolder font-size-lg mb-0">
						  {e.notes}
		                  </div>
		                </span>
             		</td>
					
					
					<td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
						<span style={{"overflow": "visible", "position": "relative", "width": "145px"}}>
						
							<a href="javascript:void(0)"  onClick={() => {this.props.toggleEditUser(e,e._id)}} className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2" title="Edit Message">	       
								<span className="svg-icon svg-icon-md">		
									<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">						
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">			
											<rect x="0" y="0" width="24" height="24"></rect>
											<path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "></path>			
											<path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>					
										</g>		
									</svg>	       
								</span>	            
							</a> 
						</span>
					</td>
				</tr>

				))}
          	</tbody>
			
			<EditMessage initialPage={this.props.initialPage} editShow={this.props.editShow} toggleEditUser={this.props.toggleEditUser} data={this.props.data} id={this.props.id}/>
			
		</>
		)
    }
}
export default MessageGrid;