import React, { Component } from "react";
import Moment from 'moment';

class BankDetail extends Component {
 
	render() {
		const {bank_details} = this.props.data;

		return (
			<>
				<div className={this.props.bank===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Bank Details</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleBank}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
						
					</div>
					<hr/>
					
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Bank name: </a>
							{bank_details?<span className="text-muted font-weight-bold education-margin">{bank_details.bank_name}</span>:<span className="text-muted font-weight-bold education-margin"></span>}
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Account number: </a>
							{bank_details?<span className="text-muted font-weight-bold education-margin">{bank_details.account_no}</span>:<span className="text-muted font-weight-bold education-margin"></span>}
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">IFSC Code: </a>
							{bank_details?<span className="text-muted font-weight-bold education-margin">{bank_details.ifsc_code}</span>:<span className="text-muted font-weight-bold education-margin"></span>}
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Pan card: </a>
							{bank_details?<span className="text-muted font-weight-bold education-margin"><img className="imageClinic"  width ="180px" src={process.env.REACT_APP_S3URL + bank_details.pancard_url} alt=""/></span>:<span className="text-muted font-weight-bold education-margin"></span>}
						</div>
					
				</div>
				{this.props.bank=== true?<div className="offcanvas-overlay" onClick={this.props.toggleBank}></div>:""}
			</>
		)
	}
}

export default BankDetail;