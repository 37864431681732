import React, { Component } from "react";
import SideBar from "../common/sideBar";
import { Dropdown } from "react-bootstrap";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import { connect } from "react-redux";
import ChangeExpertGrid from "./changeExpertGrid";
import HelmetComponent from "../common/helmetComponent";
import {
  addExpert,
  getExpert,
  loadAllExpert,
  updateExpert,
} from "../../store/changeExpert";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
  deleted,
  activated,
  deactivated,
  failed,
  informationSaved,
} from "../common/misc";
import SortingIconUp from "../common/sortingIconUp";
import SortingIcon from "../common/sortingIcon";

class ChangeExpert extends Component {
  state = {
    userShow: false,
    id: "",
    msg: "",
    status: "",
    editShow: false,
    asside: false,
    asside2: false,
    asside3: false,
    active: false,
    sort_by: "first_name",
    sort: "desc",
    status: "",
  };

  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  toggleAcitve = (id, status, msg) => {
    this.setState({
      active: !this.state.active,
      id: id,
      status: status,
      msg: msg,
    });
  };
  UpdateStatus = (id) => {
    this.props.updateExpert(id, this.callbackActive);
  };

  callbackActive = (res) => {
    console.log(res);
    if (res && res.status === 200) {
      this.props.loadAllExpert();
      this.setState({
        active: !this.state.active,
      });
      if (this.state.status === true) {
        toast(<AlertSuccess message={informationSaved} />);
      }
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  componentDidMount = () => {
    this.props.loadAllExpert();
  };

  sortByFirstName = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "first_name",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "first_name",
        sort: "desc",
      });
    }
  };

  sortByReasonTitle = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "reason_title",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "reason_title",
        sort: "desc",
      });
    }
  };
  sortByReasonText = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "reason_text",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "reason_text",
        sort: "desc",
      });
    }
  };

  sortByCreatedOn = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "created_on",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "created_on",
        sort: "desc",
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadAllExpert({
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
    if (this.state.status !== prevState.status) {
      this.props.loadAllExpert({
        status: this.state.status,
      });
    }
  };

  render() {
    const { expert } = this.props;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Administrators Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Change Expert Request
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          {expert.length > 0
                            ? "Total :" + " " + expert.length + " " + "records"
                            : "Total : 0 record"}
                        </span>
                      </div>{" "}
                      <div class="d-flex align-items-center ml-6">
                        {/* <span className="text-dark-50 font-weight-bold mr-3">
                          Filter By Status
                        </span> */}
                        <Dropdown>
                          <Dropdown.Toggle style={{ all: "unset" }}>
                            <span className="text-dark-50 font-weight-bold mr-1">
                              Filter By Status
                            </span>
                            <a
                              href="javascript:void (0)"
                              className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                              title="Action"
                            >
                              <span className="svg-icon svg-icon-md">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="#ff8f00"
                                    fill-rule="evenodd"
                                  >
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <path
                                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                      transform="translate(12.000003, 11.999999) rotate(-180.000000) translate(-12.000003, -11.999999) "
                                    />
                                  </g>
                                </svg>
                              </span>
                            </a>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <ul className="navi flex-column navi-hover py-2">
                              <li className="navi-header font-weight-bolder   text-uppercase font-size-xs text-primary pb-2">
                                Filter by status:
                              </li>
                              <li className="navi-item">
                                <a
                                  href="javascript:void (0)"
                                  onClick={() =>
                                    this.setState({ status: "approved" })
                                  }
                                  className="navi-link"
                                >
                                  <span className="navi-text">Approved</span>
                                </a>
                              </li>
                              <li className="navi-item">
                                <a
                                  href="javascript:void (0)"
                                  onClick={() =>
                                    this.setState({ status: "pending" })
                                  }
                                  className="navi-link"
                                >
                                  <span className="navi-text">Pending</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                        <span
                          className="text-dark-50 font-weight-bold"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ status: "" })}
                        >
                          Reset
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Change Expert Requests
                            <span className="d-block text-muted pt-2 font-size-sm">
                              You can view/approve change expert requests.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "190px" }}>
                                    Patient Name{" "}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "190px" }}>
                                    Doctor Name
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "120px" }}>
                                    Reason Title
                                    {this.state.sort_by === "reason_title" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByReasonTitle}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByReasonTitle}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "120px" }}>
                                    Reason Text
                                    {this.state.sort_by === "reason_text" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByReasonText}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByReasonText}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "100px" }}>
                                    Status{" "}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "150px" }}>
                                    Requested On{" "}
                                    {this.state.sort_by === "created_on" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCreatedOn}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreatedOn}
                                      />
                                    )}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <ChangeExpertGrid
                              Update={this.UpdateStatus}
                              modalActive={this.state.active}
                              expert={expert}
                              toggleAcitve={this.toggleAcitve}
                              status_id={this.state.id}
                              msg={this.state.msg}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  expert: getExpert(state).expert,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllExpert: (param) => dispatch(loadAllExpert(param)),
  updateExpert: (id, callbackActive) =>
    dispatch(updateExpert(id, callbackActive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeExpert);
