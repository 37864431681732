import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import HelmetComponent from "../common/helmetComponent";
import { connect } from "react-redux";
import Pagination from "../common/Pagination";
import {
  getNotification,
  loadAllNotification,
  addNotification,
} from "../../store/notification";
import { loadAllDoctor, getDoctor } from "../../store/doctor";
import { informationSaved } from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import AddNotification from "./addNotification";
import NotificationGrid from "./notificationGrid";
import SortingIcon from "../common/sortingIcon";
import SortingIconUp from "../common/sortingIconUp";

class NotificationTable extends Component {
  state = {
    userShow: false,
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    asside: false,
    payment: false,
    payData: "",
    data: "",
    asside2: false,
    asside3: false,
    modal: false,
    sort_by: "text",
    sort: "desc",
  };

  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleAddUser = () => {
    this.setState((state) => {
      return {
        isShow: !state.isShow,
      };
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  componentDidMount = () => {
    this.props.loadAllNotification({
      sort_by: "text",
      sort: "desc",
    });
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadAllNotification({ page: data.page });
    }
  };

  addCallback = (res) => {
    console.log(res);
    if (res && res.status === 200) {
      this.props.loadAllNotification({ page: this.state.currentPage });
      this.toggleAddUser();
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.statusText} />);
    }
  };

  sortByText = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "text",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "text",
        sort: "desc",
      });
    }
  };

  sortByCreatedOn = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "created_on",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "created_on",
        sort: "desc",
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadAllNotification({
        page: this.state.currentPage,
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
  };

  render() {
    const { notification, loading } = this.props.notification;
    const { record_per_page, total_page, current_page, filtered_record } =
      this.props.pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Appointments Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Notifications
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          {filtered_record > 0
                            ? "Total :" +
                              " " +
                              filtered_record +
                              " " +
                              "records"
                            : "Total : 0 record"}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void (0)"
                        className="btn btn-light-primary font-weight-bold btn-sm px-4 font-size-base ml-2"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.loadAllDoctor();
                          this.toggleAddUser();
                        }}
                      >
                        Add Notification
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Notifications Management
                            <span className="d-block text-muted pt-2 font-size-sm">
                              You can view/change notifications.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "60%" }}
                                >
                                  <span>
                                    Text{" "}
                                    {this.state.sort_by === "text" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={this.sortByText} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByText}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  data-field="Status"
                                  style={{ width: "15%" }}
                                  className="datatable-cell datatable-cell-sort"
                                >
                                  <span>Doctor</span>
                                </th>
                                <th
                                  data-field="Status"
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span>View</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "15%" }}
                                >
                                  <span>
                                    Created On{" "}
                                    {this.state.sort_by === "created_on" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCreatedOn}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreatedOn}
                                      />
                                    )}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            {loading === false && notification.length === 0 ? (
                              "Record not found"
                            ) : (
                              <NotificationGrid
                                initialPage={this.state.currentPage}
                                notification={notification}
                              />
                            )}
                          </table>
                          <Pagination
                            totalRecords={filtered_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddNotification
            initialPage={this.state.current_page}
            isShow={this.state.isShow}
            toggleAddUser={this.toggleAddUser}
            callback={this.addCallback}
            addNotification={this.props.addNotification}
            allDoctor={this.props.allDoctor}
          />
          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  notification: getNotification(state),
  allDoctor: getDoctor(state).doctor,
  pagination: getNotification(state).pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllNotification: (params) => dispatch(loadAllNotification(params)),
  addNotification: (param, callback) =>
    dispatch(addNotification(param, callback)),
  loadAllDoctor: () => dispatch(loadAllDoctor()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTable);
