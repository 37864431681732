import React, { Component } from "react";
import { getSeo,loadAllSeo} from "../../store/seo";
import { connect } from "react-redux";
import SearchIcon from "../common/searchIcon";

const initialState = {
	title: "",
	description:"",
	page_key:""
};

class SeoSearch extends Component {
	state = initialState;
	
	onSearch = (e) => {
		e.preventDefault();
		if (this.state.title) {
			this.props.loadAllSeo({"title":this.state.title})
		}
		else if (this.state.description){
			this.props.loadAllSeo({"description":this.state.description})
		}
		else if (this.state.page_key){
			this.props.loadAllSeo({"page_key":this.state.page_key})
		}
	};
	  reset = ()=>{
       this.props.loadAllSeo({"page":this.props.currentPage})
	   this.setState(initialState)
       }
	render(){
		return(
			<>
				<form className="ml-5"  onSubmit={this.onSearch}>
					<div className="input-group input-group-sm input-group-solid" onClick={this.onSearch} style={{ "maxWidth": "195px" }}>
						<input
						name="title"
						id="title"
						className="form-control"
						placeholder="Search By Title"
						autoComplete="off"
						dir="ltr"
						value={this.state.title}
						onChange={(e) =>
							this.setState({ title: e.target.value })
						}
						/>
						<SearchIcon/>
					</div>
				</form>
				<form className="ml-5" onSubmit={this.onSearch}>
					<div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.onSearch}>
						<input
						name="description"
						id="description"
						className="form-control"
						placeholder="Search By Description"
						autoComplete="off"
						dir="ltr"
						value={this.state.description}
						onChange={(e) =>
							this.setState({ description: e.target.value })
						}
						/>
						<SearchIcon/>
					</div>
				</form>
				<form className="ml-5" onSubmit={this.onSearch}>
					<div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.onSearch}>
						<input
						name="page_key"
						id="page_key"
						className="form-control"
						placeholder="Search By Page Key"
						autoComplete="off"
						value={this.state.page_key}
						onChange={(e) =>
							this.setState({ page_key: e.target.value })
						}
						/>
						<SearchIcon/>
					</div>
				</form>
				<form className="ml-5" onSubmit={this.reset}>
					<div className="input-group input-group-sm input-group-solid" style={{ "maxWidth": "195px" }} onClick={this.reset}>
						<a href="javascript:void (0)" type="submit">
				  			<span className="text-dark-50 font-weight-bold" style={{"cursor":"pointer"}}>
					  			Reset
							</span>
						</a>
					</div>
				</form>
			</>
		)
	}
}
const mapStateToProps = (state) => ({
    seo: getSeo(state).seo,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllSeo: (params) => dispatch(loadAllSeo(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeoSearch);
