import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "assessmentcategory",
  initialState: {
    assessmentcategory: [],
    pagination:{},
    update: {},
    add:{},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    assessmentcategoryRequested: (assessmentcategory, action) => {
      assessmentcategory.loading = true;
    },

    assessmentcategoryReceived: (assessmentcategory, action) => {
      assessmentcategory.assessmentcategory = action.payload.data;
      assessmentcategory.pagination=action.payload.pagination;
      assessmentcategory.loading = false;
      assessmentcategory.lastFetch = Date.now();
    },

    assessmentcategoryRequestFailed: (assessmentcategory, action) => {
      assessmentcategory.loading = false;
    },
    
    updatedAssessmentcategory: (assessmentcategory, action) => {
      assessmentcategory.update = action.payload;
      assessmentcategory.loading = false;
      assessmentcategory.lastFetch = Date.now();
    },

    updatedAssessmentcategoryRequestFailed: (assessmentcategory, action) => {
      assessmentcategory.loading = false;
    },
    assessmentcategoryAdded: (assessmentcategory, action) => {
      assessmentcategory.add = action.payload;
      assessmentcategory.loading = false;
      assessmentcategory.lastFetch = Date.now();
    },

    assessmentcategoryAddRequestFailed: (assessmentcategory, action) => {
      assessmentcategory.loading = false;
    },
  },
});

export const {
  assessmentcategoryRequested,
  assessmentcategoryReceived,
  assessmentcategoryRequestFailed,
  updatedAssessmentcategory,
  updatedAssessmentcategoryRequestFailed,
  assessmentcategoryAdded,
  assessmentcategoryAddRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "assessment-category/";


export const loadAllAssessmentcategory = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: assessmentcategoryRequested.type,
      onSuccess: assessmentcategoryReceived.type,
      onError: assessmentcategoryRequestFailed.type,
    })
  );
};
export const updateAssessmentcategory = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url  + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedAssessmentcategory.type,
      onError: updatedAssessmentcategoryRequestFailed.type,
    })
  );
};
export const addAssessmentcategory = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: assessmentcategoryAdded.type,
      onError: assessmentcategoryAddRequestFailed.type,
    })
  );
};

export const deletedAssessmentcategory = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url:url + id,
      method: "delete",
      callback,
    })
  );
};

export const getAssessmentCategory = createSelector(
  (state) => state.entities.assessmentcategory,
  (assessmentcategory) => assessmentcategory
);
