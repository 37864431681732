import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "seo",
  initialState: {
    seo: [],
    pagination:{},
    update: {},
    add:{},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    seoRequested: (seo, action) => {
      seo.loading = true;
    },

    seoReceived: (seo, action) => {
      seo.seo = action.payload.data;
      seo.pagination=action.payload.pagination;
      seo.loading = false;
      seo.lastFetch = Date.now();
    },

    seoRequestFailed: (seo, action) => {
      seo.loading = false;
    },
    updatedSeo: (seo, action) => {
      seo.update = action.payload;
      seo.loading = false;
      seo.lastFetch = Date.now();
    },

    updatedSeoRequestFailed: (seo, action) => {
      seo.loading = false;
    },
     seoAdded: (seo, action) => {
      seo.add = action.payload;
      seo.loading = false;
      seo.lastFetch = Date.now();
    },

    seoAddRequestFailed: (seo, action) => {
      seo.loading = false;
    },
  },
});

export const {
  seoRequested,
  seoReceived,
  seoRequestFailed,
  updatedSeo,
  updatedSeoRequestFailed,
  seoAdded,
  seoAddRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "seo/";


export const loadAllSeo = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: seoRequested.type,
      onSuccess: seoReceived.type,
      onError: seoRequestFailed.type,
    })
  );
};
export const updateSeo = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url  + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedSeo.type,
      onError: updatedSeoRequestFailed.type,
    })
  );
};
export const addSeo = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: seoAdded.type,
      onError: seoAddRequestFailed.type,
    })
  );
};

export const deletedSeo = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url:url + id,
      method: "delete",
      callback,
    })
  );
};

export const getSeo = createSelector(
  (state) => state.entities.seo,
  (seo) => seo
);
