import React, { Component } from "react";

class Practice extends Component {
 
	render() {
		const {practice_preferences} = this.props.data;

		return (
			<>
				<div className={this.props.practice===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Practice Preferences</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.togglePractice}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
					</div>
					<hr/>
						<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Area of Interest: </a>
							{practice_preferences && practice_preferences.interest.map((e,i)=>(
							<span className="text-muted font-weight-bold education-margin">{e}</span>))}
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Area of Expertise: </a>
							{practice_preferences && practice_preferences.expertise.map((e,i)=>(
							<span className="text-muted font-weight-bold education-margin">{e}</span>))}
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Preferred Modality of Therapy: </a>
							{practice_preferences && practice_preferences.therapy.map((e,i)=>(
							<span className="text-muted font-weight-bold education-margin">{e}</span>))}
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Language: </a>
							{practice_preferences && practice_preferences.language.map((e,i)=>(
							<span className="text-muted font-weight-bold education-margin">{e}</span>))}
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Few Qualities That Patients Say About You: </a>
							<span className="text-muted font-weight-bold education-margin">{practice_preferences && practice_preferences.about_me}</span>
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Your Message To Patients On Mental Health/ Illness: </a>
							<span className="text-muted font-weight-bold education-margin">{practice_preferences && practice_preferences.message_to_patient}</span>
						</div>
					
					
				</div>
				{this.props.practice=== true?<div className="offcanvas-overlay" onClick={this.props.togglePractice}></div>:""}
			</>
		)
	}
}

export default Practice;