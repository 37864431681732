import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import ScrollTop from "../common/scrollTop";
import HelmetComponent from "../common/helmetComponent";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DoctorGrid from "./doctorGrid";
import Pagination from "../common/Pagination";
import {
  getDoctor,
  loadAllDoctor,
  deletedDoctor,
  loadDoctorDetail,
  updateApproved,
  updateDoctor,
} from "../../store/doctor";
import {
  informationSaved,
  deleted,
  activated,
  deactivated,
  failed,
} from "../common/misc";
import Search from "./search";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import SortingIcon from "../common/sortingIcon";
import SortingIconUp from "../common/sortingIconUp";

class DoctorTable extends Component {
  state = {
    userShow: false,
    modal: false,
    education: false,
    id: "",
    status: "",
    isShow: false,
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    delete_id: "",
    asside: false,
    experience: false,
    certification: false,
    clinic: false,
    award: false,
    publication: false,
    documentation: false,
    practice: false,
    link: false,
    bank: false,
    schedule: false,
    asside2: false,
    sort_by: "created_on",
    sort: "desc",
    asside3: false,
    association: false,
    doctor_id: "",
    active: false,
    msg: "",
    status: "",
    change: false,
    name: "",
    mobile: "",
    email: "",
    field_type: "",
    featured_on_expert: "",
  };
  sortByName = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "name",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "name",
        sort: "desc",
      });
    }
  };
  sortByEmail = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "email",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "email",
        sort: "desc",
      });
    }
  };

  sortByDOB = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "date_of_birth",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "date_of_birth",
        sort: "desc",
      });
    }
  };
  sortByCreated = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "created_on",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "created_on",
        sort: "desc",
      });
    }
  };
  sortByFeild = () => {
    if (this.state.sort === "desc")
      this.setState({
        sort_by: "field_type",
        sort: "asc",
      });
    else {
      this.setState({
        sort_by: "field_type",
        sort: "desc",
      });
    }
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleEducation = (id) => {
    this.setState({
      education: !this.state.education,
      id: id,
    });
  };
  toggleSchedule = (id) => {
    this.setState({
      schedule: !this.state.schedule,
      id: id,
    });
  };
  toggleAssociation = (id) => {
    this.setState({
      association: !this.state.association,
      id: id,
    });
  };
  toggleBank = (id) => {
    this.setState({
      bank: !this.state.bank,
      id: id,
    });
  };
  toggleLink = (id) => {
    this.setState({
      link: !this.state.link,
      id: id,
    });
  };
  togglePractice = (id) => {
    this.setState({
      practice: !this.state.practice,
      id: id,
    });
  };
  toggleAcitve = (id, status, msg) => {
    this.setState({
      active: !this.state.active,
      doctor_id: id,
      status: status,
      msg: msg,
    });
  };
  toggleChange = (id, status, msg) => {
    this.setState({
      change: !this.state.change,
      doctor_id: id,
      status: status,
      msg: msg,
    });
  };
  togglePublication = (id) => {
    this.setState({
      publication: !this.state.publication,
      id: id,
    });
  };
  toggleDocumentation = (id) => {
    this.setState({
      documentation: !this.state.documentation,
      id: id,
    });
  };
  toggleExperience = (id) => {
    this.setState({
      experience: !this.state.experience,
      id: id,
    });
  };
  toggleAward = (id) => {
    this.setState({
      award: !this.state.award,
      id: id,
    });
  };
  toggleClinic = (id) => {
    this.setState({
      clinic: !this.state.clinic,
      id: id,
    });
  };
  toggleCertification = (id) => {
    this.setState({
      certification: !this.state.certification,
      id: id,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  deletedDoctor = (id) => {
    this.props.deletedDoctor(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllDoctor({ page: this.state.currentPage });
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  UpdateApproved = (id) => {
    const formData = {
      is_approved: this.state.status,
    };
    this.props.updateApproved(id, formData, this.callbackActive);
  };
  callbackActive = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllDoctor({
        page: this.state.currentPage,
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
      this.setState({
        active: !this.state.active,
      });
      if (this.state.status === true) {
        toast(<AlertSuccess message="Approved Successfully." />);
      } else {
        toast(<AlertSuccess message="Rejected Successfully." />);
      }
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  toggleChange = (id, featured_on_home) => {
    const formData = {
      featured_on_home: !featured_on_home,
    };
    this.props.updateDoctor(id, formData, this.callBack);
  };
  toggleChange1 = (id, featured_on_expert) => {
    const formData = {
      featured_on_expert: !featured_on_expert,
    };
    this.props.updateDoctor(id, formData, this.callBack);
  };

  callBack = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllDoctor({
        page: this.state.currentPage,
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });

      toast(<AlertSuccess message="Information saved." />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadAllDoctor({
        page: this.state.currentPage,
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
  };
  onSearch = (e) => {
    e.preventDefault();

    if (this.state.name) {
      this.props.loadAllDoctor({
        name: this.state.name,
        page: this.state.currentPage,
      });
    } else if (this.state.mobile) {
      this.props.loadAllDoctor({
        mobile: this.state.mobile,
        page: this.state.currentPage,
      });
    } else if (this.state.email) {
      this.props.loadAllDoctor({
        email: this.state.email,
        page: this.state.currentPage,
      });
    }
  };

  reset = () => {
    this.props.loadAllDoctor({ page: this.props.currentPage });
    this.setState({ name: "", email: "", mobile: "" });
    var dropDown = document.getElementById("field");
    dropDown.selectedIndex = 0;
    var feature = document.getElementById("featured");
    feature.selectedIndex = 0;
  };
  handleChange = (field) => {
    this.setState({ field_type: field });
    this.props.loadAllDoctor({
      field_type: field,
      page: this.state.currentPage,
    });
  };
  handleFeatured = (featured) => {
    this.setState({ featured_on_expert: featured });
    this.props.loadAllDoctor({
      featured_on_expert: featured,
      page: this.state.currentPage,
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by != prevState.sort_by ||
      this.state.sort != prevState.sort
    ) {
      this.props.loadAllDoctor({
        page: this.state.currentPage,
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
    if (this.state.id && this.state.id != prevState.id) {
      this.props.loadDoctorDetail(this.state.id);
    }
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page != this.state.currentPage && this.state.name) {
      this.props.loadAllDoctor({ page: data.page, name: this.state.name });
    } else if (data.page != this.state.currentPage && this.state.email) {
      this.props.loadAllDoctor({ page: data.page, email: this.state.email });
    } else if (data.page != this.state.currentPage && this.state.mobile) {
      this.props.loadAllDoctor({ page: data.page, mobile: this.state.mobile });
    } else if (data.page != this.state.currentPage && this.state.field_type) {
      this.props.loadAllDoctor({
        page: data.page,
        field_type: this.state.field_type,
      });
    } else if (
      data.page != this.state.currentPage &&
      this.state.featured_on_expert
    ) {
      this.props.loadAllDoctor({
        page: data.page,
        featured_on_expert: this.state.featured_on_expert,
      });
    } else if (data.page != this.state.currentPage) {
      this.props.loadAllDoctor({ page: data.page });
    }
  };
  changeName = (name) => {
    this.setState({ name: name });
  };
  changeEmail = (email) => {
    this.setState({ email: email });
  };
  changeMobile = (mobile) => {
    this.setState({ mobile: mobile });
  };
  render() {
    const { doctor, detail } = this.props;
    const {
      record_per_page,
      total_record_on_current_page,
      total_record,
      total_page,
      current_page,
      filtered_record,
    } = this.props.pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Doctors Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div className="d-flex flex-column flex-row-fluid wrapper">
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2 ">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Doctors
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5  bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          {filtered_record > 0
                            ? "Total :" +
                              " " +
                              filtered_record +
                              " " +
                              "records"
                            : "Total : 0 record"}
                        </span>
                        <Search
                          handleFeatured={this.handleFeatured}
                          handleChange={this.handleChange}
                          reset={this.reset}
                          mobile={this.state.mobile}
                          email={this.state.email}
                          changeMobile={this.changeMobile}
                          changeEmail={this.changeEmail}
                          changeName={this.changeName}
                          currentPage={current_page}
                          name={this.state.name}
                          onSearch={this.onSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Doctors Management
                            <span className="d-block text-muted pt-2 font-size-sm">
                              You can view/delete doctors.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block", minHeight: "1000px" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>
                                    Name
                                    {this.state.sort_by === "name" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={this.sortByName} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByName}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>
                                    Email/ Mobile
                                    {this.state.sort_by === "email" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={this.sortByEmail} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByEmail}
                                      />
                                    )}{" "}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "90px" }}>
                                    DOB
                                    {this.state.sort_by === "date_of_birth" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={this.sortByDOB} />
                                    ) : (
                                      <SortingIconUp sorting={this.sortByDOB} />
                                    )}{" "}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "150px" }}>
                                    Field Type
                                    {this.state.sort_by === "field_type" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={this.sortByFeild} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByFeild}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "75px" }}>
                                    Approved
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "115px" }}>
                                    Created On
                                    {this.state.sort_by === "created_on" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCreated}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreated}
                                      />
                                    )}{" "}
                                  </span>
                                </th>
                                <th
                                  data-5-field="Status"
                                  className="datatable-cell datatable-cell-sort"
                                >
                                  <span style={{ width: "205px" }}>Action</span>
                                </th>
                              </tr>
                            </thead>
                            <DoctorGrid
                              initialPage={this.state.current_page}
                              delete_id={this.state.delete_id}
                              isShow={this.state.isShow}
                              modal={this.state.modal}
                              toggle={this.toggle}
                              id={this.state.id}
                              doctor={doctor}
                              deleted={this.deletedDoctor}
                              toggleEducation={this.toggleEducation}
                              education={this.state.education}
                              toggleExperience={this.toggleExperience}
                              experience={this.state.experience}
                              toggleCertification={this.toggleCertification}
                              certification={this.state.certification}
                              toggleClinic={this.toggleClinic}
                              clinic={this.state.clinic}
                              detail={detail}
                              toggleAward={this.toggleAward}
                              award={this.state.award}
                              togglePublication={this.togglePublication}
                              publication={this.state.publication}
                              toggleDocumentation={this.toggleDocumentation}
                              documentation={this.state.documentation}
                              togglePractice={this.togglePractice}
                              practice={this.state.practice}
                              toggleLink={this.toggleLink}
                              link={this.state.link}
                              toggleBank={this.toggleBank}
                              bank={this.state.bank}
                              toggleSchedule={this.toggleSchedule}
                              schedule={this.state.schedule}
                              association={this.state.association}
                              toggleAssociation={this.toggleAssociation}
                              msg={this.state.msg}
                              status_id={this.state.doctor_id}
                              Update={this.UpdateApproved}
                              modalActive={this.state.active}
                              toggleAcitve={this.toggleAcitve}
                              toggleChange={this.toggleChange}
                              toggleChange1={this.toggleChange1}
                            />
                          </table>
                          <Pagination
                            totalRecords={filtered_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />

          <ScrollTop />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  doctor: getDoctor(state).doctor,
  pagination: getDoctor(state).pagination,
  detail: getDoctor(state).doctorDetail,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllDoctor: (params) => dispatch(loadAllDoctor(params)),
  deletedDoctor: (id, callback) => dispatch(deletedDoctor(id, callback)),
  loadDoctorDetail: (id) => dispatch(loadDoctorDetail(id)),
  updateApproved: (id, data, callbackActive) =>
    dispatch(updateApproved(id, data, callbackActive)),
  updateDoctor: (id, data, callBack) =>
    dispatch(updateDoctor(id, data, callBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorTable);
