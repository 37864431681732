import React, { Component } from "react";
import Moment from 'moment';

class Publication extends Component {
 
	render() {
		const {publication} = this.props.data;

		return (
			<>
				<div className={this.props.publication===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Publications</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.togglePublication}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
					</div>
					<hr/>
					{publication && publication.map((e,i)=>(
						<>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Title of article: </a>
							<span className="text-muted font-weight-bold education-margin">{e.title}</span>
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Journal: </a>
							<span className="text-muted font-weight-bold education-margin">{e.journal}</span>
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Year: </a>
							<span className="text-muted font-weight-bold education-margin">{e.year}</span>
						</div>
						
						<hr/>
						</>
					))}
					
					
				</div>
				{this.props.publication=== true?<div className="offcanvas-overlay" onClick={this.props.togglePublication}></div>:""}
			</>
		)
	}
}

export default Publication;