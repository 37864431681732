import { combineReducers } from "redux";
import patientReducer from "./patient";
import adminReducer from "./admin";
import administratorReducer from "./administrator";
import blogcategoryReducer from "./blogCategory";
import assessmentcategoryReducer from "./assessmentCategories";
import blogReducer from "./blog";
import seoReducer from "./seo";
import doctorReducer from "./doctor";
import miscReducer from "./misc";
import appointmentReducer from "./appointment";
import siteReducer from "./siteConstant";
import messageReducer from "./message";
import sectionReducer from "./section";
import questionReducer from "./question";
import assessmentReducer from "./assessment";
import scoringReducer from "./scoringReference";
import templateReducer from "./termsTemplates";
import expertReducer from "./changeExpert";
import faqReducer from "./faq";
import notificationReducer from "./notification";

export default combineReducers({
  admin: adminReducer,
  patient: patientReducer,
  administrator: administratorReducer,
  blogcategory: blogcategoryReducer,
  blog: blogReducer,
  seo: seoReducer,
  doctor: doctorReducer,
  misc: miscReducer,
  appointment: appointmentReducer,
  site: siteReducer,
  message: messageReducer,
  assessmentcategory: assessmentcategoryReducer,
  section: sectionReducer,
  question: questionReducer,
  assessment: assessmentReducer,
  scoring: scoringReducer,
  template: templateReducer,
  expert: expertReducer,
  faq: faqReducer,
  notification: notificationReducer,
});
