import React, { Component } from "react";
import Moment from 'moment';

class Certification extends Component {
 
	render() {
		const {certification} = this.props.data;

		return (
			<>
				<div className={this.props.certification===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"} style={{"height":"100%","overflow-y":"auto"}}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Certifications/Fellowships</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleCertification}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
						
					</div>
					<hr/>
					{certification && certification.map((e,i)=>(
						<>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Certifications/PDF/Superspeciality: </a>
							<span className="text-muted font-weight-bold education-margin">{e.title}</span>
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Place: </a>
							<span className="text-muted font-weight-bold education-margin">{e.place}</span>
						</div>
						<div className="d-flex flex-column flex-grow-1 mr-2">
							<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Year: </a>
							<span className="text-muted font-weight-bold education-margin">{e.year}</span>
						</div>
						<hr/>
						</>
					))}
					
					
				</div>
				{this.props.certification=== true?<div className="offcanvas-overlay" onClick={this.props.toggleCertification}></div>:""}
			</>
		)
	}
}

export default Certification;