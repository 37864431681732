import React, { Component } from 'react'
import SideBar from '../common/sideBar'
import UserProfile from '../common/userProfile'
import ScrollTop from '../common/scrollTop'
import HelmetComponent from '../common/helmetComponent'
import { connect } from 'react-redux'
import AppointmentGrid from './appointmentGrid'
import AppointmentEmpty from './appointmentEmpty'
import Pagination from '../common/Pagination'
import { getAppointment, loadAllAppointment } from '../../store/appointment'
import SortingIcon from '../common/sortingIcon'
import SortingIconUp from '../common/sortingIconUp'
import Search from './search'

class AppointmentTable extends Component {
  state = {
    userShow: false,
    totalRecords: '',
    totalPages: '',
    pageLimit: '',
    currentPage: '',
    asside: false,
    payment: false,
    payData: '',
    data: '',
    asside2: false,
    asside3: false,
    sort_by: 'created_at',
    sort: 'desc',
    modal: false,
    refund_id: '',
    queryData: {},
  }
  sortByType = () => {
    // this.setState({
    //   sort_by: "appointment_type",
    //   sort: "asc",
    // });
    if (this.state.sort === 'desc')
      this.setState({
        sort_by: 'appointment_type',
        sort: 'asc',
      })
    else {
      this.setState({
        sort_by: 'appointment_type',
        sort: 'desc',
      })
    }
  }
  sortByAmount = () => {
    // this.setState({
    //   sort_by: "amount",
    //   sort: "asc",
    // });
    if (this.state.sort === 'desc')
      this.setState({
        sort_by: 'amount',
        sort: 'asc',
      })
    else {
      this.setState({
        sort_by: 'amount',
        sort: 'desc',
      })
    }
  }

  sortByCreated = () => {
    // this.setState({
    //   sort_by: "created_at",
    //   sort: "asc",
    // });
    if (this.state.sort === 'desc')
      this.setState({
        sort_by: 'created_at',
        sort: 'asc',
      })
    else {
      this.setState({
        sort_by: 'created_at',
        sort: 'desc',
      })
    }
  }
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    })
  }
  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    })
  }
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    })
  }
  togglePayment = (data, data2) => {
    this.setState({
      payment: !this.state.payment,
      payData: data,
      data: data2,
    })
  }

  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    })
  }
  toggle = id => {
    this.setState({
      modal: !this.state.modal,
      refund_id: id,
    })
  }
  componentDidMount = () => {
    if (this.props.location.state && this.props.location.state.patient_id) {
      this.props.loadAllAppointment({
        page: this.state.currentPage,
        patient_id: this.props.location.state.patient_id,
        ...this.state.queryData,
      })
    } else if (
      this.props.location.state &&
      this.props.location.state.doctor_id
    ) {
      this.props.loadAllAppointment({
        page: this.state.currentPage,
        doctor_id: this.props.location.state.doctor_id,
        ...this.state.queryData,
      })
    } else if (this.state.currentPage) {
      this.props.loadAllAppointment({
        page: this.state.currentPage,
        sort_by: this.state.sort_by,
        sort: this.state.sort,
        ...this.state.queryData,
      })
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadAllAppointment({
        page: this.state.currentPage,
        sort_by: this.state.sort_by,
        sort: this.state.sort,
        ...this.state.queryData,
      })
    }
  }

  onChangePage = data => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    })
    if (this.props.location.state && this.props.location.state.patient_id) {
      this.props.loadAllAppointment({
        page: data.page,
        patient_id: this.props.location.state.patient_id,
        ...this.state.queryData,
      })
    } else if (
      this.props.location.state &&
      this.props.location.state.doctor_id
    ) {
      this.props.loadAllAppointment({
        page: data.page,
        doctor_id: this.props.location.state.doctor_id,
        ...this.state.queryData,
      })
    } else if (data.page !== this.state.currentPage) {
      this.props.loadAllAppointment({
        page: data.page,
        ...this.state.queryData,
      })
    }
  }

  handleSearch = data => {
    this.setState({ queryData: data, currentPage: 1 })
    this.props.loadAllAppointment({
      page: 1,
      ...data,
    })
  }

  handleLoad = () => {
    this.props.loadAllAppointment({
      page: this.state.currentPage,
      sort_by: this.state.sort_by,
      sort: this.state.sort,
      ...this.state.queryData,
    })
  }
  render() {
    const { appointment, loading } = this.props.appointment
    const { record_per_page, total_page, current_page, filtered_record } =
      this.props.pagination

    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? 'header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled'
            : 'header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled aside-minimize'
        }>
        <div className='d-flex flex-column flex-root'>
          <HelmetComponent title='Appointments Management' />
          <div className='d-flex flex-row flex-column-fluid page'>
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className='d-flex flex-column flex-row-fluid wrapper'
              id='kt_wrapper'>
              <div className='content d-flex flex-column flex-column-fluid'>
                <div className='subheader py-2 py-lg-4 subheader-transparent'>
                  <div className='container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-2'>
                      <h5 className='text-dark font-weight-bold mt-2 mb-2 mr-5'>
                        Appointments
                      </h5>
                      <div className='subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200'></div>
                      <div className='d-flex align-items-center'>
                        <span className='text-dark-50 font-weight-bold'>
                          {filtered_record > 0
                            ? 'Total :' +
                              ' ' +
                              filtered_record +
                              ' ' +
                              'records'
                            : 'Total : 0 record'}
                        </span>
                        <Search handleSearch={this.handleSearch} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column-fluid'>
                  <div className='container-fluid'>
                    <div className='card card-custom'>
                      <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                        <div className='card-title'>
                          <h3 className='card-label'>
                            Appointments Management
                            <span className='d-block text-muted pt-2 font-size-sm'>
                              You can view/change appointments.
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded'>
                          <table
                            className='datatable-table'
                            style={{ display: 'block' }}>
                            <thead className='datatable-head'>
                              <tr
                                className='datatable-row'
                                style={{ left: '0px' }}>
                                <th className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '125px' }}>
                                    Doctor Name
                                  </span>
                                </th>
                                <th className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '150px' }}>
                                    Patient Name
                                  </span>
                                </th>
                                <th className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '100px' }}>
                                    Amount
                                    {this.state.sort_by === 'amount' &&
                                    this.state.sort === 'asc' ? (
                                      <SortingIcon
                                        sorting={this.sortByAmount}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByAmount}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '100px' }}>Refund</span>
                                </th>
                                <th className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '130px' }}>
                                    Date & Time
                                  </span>
                                </th>
                                <th className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '90px' }}>
                                    Type
                                    {this.state.sort_by ===
                                      'appointment_type' &&
                                    this.state.sort === 'asc' ? (
                                      <SortingIcon sorting={this.sortByType} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByType}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  data-field='Status'
                                  className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '90px' }}>Status</span>
                                </th>
                                <th
                                  data-field='Status'
                                  className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '125px' }}>
                                    Created On
                                    {this.state.sort_by === 'created_at' &&
                                    this.state.sort === 'asc' ? (
                                      <SortingIcon
                                        sorting={this.sortByCreated}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreated}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className='datatable-cell datatable-cell-sort'>
                                  <span style={{ width: '105px' }}>Action</span>
                                </th>
                              </tr>
                            </thead>
                            {loading === false && appointment.length === 0 ? (
                              <AppointmentEmpty />
                            ) : (
                              <AppointmentGrid
                                handleLoad={this.handleLoad}
                                toggle={this.toggle}
                                modal={this.state.modal}
                                refund_id={this.state.refund_id}
                                initialPage={this.state.currentPage}
                                appointment={appointment}
                                payment={this.state.payment}
                                payData={this.state.payData}
                                data={this.state.data}
                                togglePayment={this.togglePayment}
                              />
                            )}
                          </table>
                          <Pagination
                            totalRecords={filtered_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <ScrollTop />
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  appointment: getAppointment(state),
  pagination: getAppointment(state).pagination,
})
const mapDispatchToProps = dispatch => ({
  loadAllAppointment: params => dispatch(loadAllAppointment(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentTable)
