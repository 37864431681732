import React, { Component } from "react";

class Education extends Component {

	render() {
		const {graduation,post_graduation,super_speciality}=this.props.data
		return (
			<>
				<div className={this.props.education===true ?"offcanvas offcanvas-left p-10 offcanvas-on":"offcanvas offcanvas-left p-10"}>
					<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
						<h3 className="font-weight-bold m-0">Educations</h3>
						<a href="javascript:void (0)" className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={this.props.toggleEducation}>
							<i className="ki ki-close icon-xs text-muted"></i>
						</a>
						
					</div>
					<hr/>				
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Graduation: </a>
						<span className="text-muted font-weight-bold education-margin">{graduation && graduation.education}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">College/University name: </a>
						<span className="text-muted font-weight-bold education-margin">{graduation && graduation.college_university}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Year of Passing: </a>
						<span className="text-muted font-weight-bold education-margin">{graduation && graduation.year_of_passing}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Post Graduation: </a>
						<span className="text-muted font-weight-bold education-margin">{post_graduation && post_graduation.education}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">College/University name: </a>
						<span className="text-muted font-weight-bold education-margin">{post_graduation && post_graduation.college_university}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Year of Passing: </a>
						<span className="text-muted font-weight-bold education-margin">{post_graduation && post_graduation.year_of_passing}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Super Speciality: </a>
						<span className="text-muted font-weight-bold education-margin">{super_speciality && super_speciality.education}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">College/University name: </a>
						<span className="text-muted font-weight-bold education-margin">{super_speciality && super_speciality.college_university}</span>
					</div>
					<div className="d-flex flex-column flex-grow-1 mr-2">
						<a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Year of Passing: </a>
						<span className="text-muted font-weight-bold education-margin">{super_speciality && super_speciality.year_of_passing}</span>
					</div>
					 
				</div>
				{this.props.education=== true?<div className="offcanvas-overlay" onClick={this.props.toggleEducation}></div>:""}
			</>
		)
	}
}

export default Education;

